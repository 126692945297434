import React from 'react';
import {
    reservationStatusColorsHEX,
    reservationStatusColorsHEXCondo,
    reservationStatusEnumsDesc,
} from '../../data/reservationStatusEnums';
import translate from '@data/translations';

interface ReservationStatusLegendProps {
    toggleHideReservationStatusEnums: Function;
    toggleHideCondoReservations: Function;
    hideReservationStatusEnums: any;
    hideCondoReservations: any;
}

function ReservationStatusLegend(props: ReservationStatusLegendProps) {
    const clickHandler = (i: any, e: any) => {
        e.preventDefault();
        props.toggleHideReservationStatusEnums(i);
    };

    const condoResFilter = (i: any, e: any) => {
        e.preventDefault();
        props.toggleHideCondoReservations(i);
    };

    let items: any = [];
    for (let i = 0; i <= 5; i++) {
        items.push(
            <div className="" key={'legend' + i}>
                <label onClick={(e: any) => clickHandler(i, e)} className="checkbox-container">
                    {reservationStatusEnumsDesc[i as keyof typeof reservationStatusEnumsDesc]}
                    <input
                        onChange={() => {}}
                        checked={!props.hideReservationStatusEnums.includes(i)}
                        type="checkbox"
                    />
                    <span
                        style={{
                            backgroundColor: reservationStatusColorsHEX[i as keyof typeof reservationStatusColorsHEX],
                        }}
                        className="checkmark"
                    ></span>
                </label>
            </div>
        );
    }
    items.push(
        <div className="" key={'legend condo'}>
            <label onClick={(e: any) => condoResFilter(!props.hideCondoReservations, e)} className="checkbox-container">
                {translate('Condo reservations')}
                <input onChange={() => {}} checked={!props.hideCondoReservations} type="checkbox" />
                <span style={{ backgroundColor: reservationStatusColorsHEXCondo[1] }} className="checkmark"></span>
            </label>
        </div>
    );
    return <div className="reservation-status-checkboxes">{items}</div>;
}

export default ReservationStatusLegend;
