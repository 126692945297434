//@ts-ignore
import _ from "lodash"

export const checkValidationInputs = (objToValidate: any, getValidationInputs: Function) => {
    let validationResults = getValidationInputs(objToValidate);
    markInvalidInputRefs(validationResults);
};

export const markInvalidInputRefs = (validationResults: any) => {
    //generic
    const { invalidInputs, validInputs } = validationResults;
    invalidInputs.forEach((input: any) => {
        if (input && input.current) {
            let classes = input.current.className ? input.current.className?.split(' ') : [];
            if (!classes?.includes('is-invalid')) {
                classes.push('is-invalid');
            }
            input.current.className = classes.join(' ');
        }
    });
    validInputs.forEach((input: any) => {
        if (input && input.current) {
            let classes = input.current.className ? input.current.className?.split(' ') : [];
            if (classes?.includes('is-invalid')) {
                _.remove(classes, (i: any) => i === 'is-invalid');
            }
            input.current.className = classes.join(' ');
        }
    });
};