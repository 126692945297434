import * as React from 'react';
import PageTitle from '@components/pageTitle';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
//@ts-ignore
import DocumentHeader from '@pages/checkoutAndInvoice/printView/DocumentHeader';
import DocumentFooter from '@pages/checkoutAndInvoice/printView/DocumentFooter';
import '../style.css';
import { reservationStatusEnumsDesc } from '@data/reservationStatusEnums';
import translate from '@data/translations';
import { Reservation } from '../resController';

interface ReservationPrintDetailsInterface {
    reservation: Reservation;
}
export enum DocumentTypeEnum {
    'P' = 'Passport',
    'I' = 'Identity card',
    'V' = 'Visa',
    'D' = 'Driving licence',
    'X' = 'Other',
}
function ReservationPrintDetails(props: ReservationPrintDetailsInterface) {
    const [headerHeight, setHeaderHeight] = React.useState(null);
    const [footerHeight, setFooterHeight] = React.useState(null);

    const { reservation } = props;

    if (!reservation) {
        return translate('Loading ...');
    }

    const customerFirstName = reservation?.Customer?.firstName ? reservation.Customer.firstName : '';
    const customerLastName = reservation?.Customer?.lastName ? reservation.Customer.lastName : '';
    const customerName = `${customerFirstName} ${customerLastName}`;
    const companyName = reservation?.Company?.name ? reservation.Company.name : '';
    const reservationCheckIn = moment(reservation.checkInTimestamp).format('lll');
    const reservationCheckOut = moment(reservation.checkOutTimestamp).format('lll');
    const holderVisaInfo = reservation?.VisaInfo?.find((r) => r.customerId === reservation?.Customer?.id);
    const documentId = holderVisaInfo?.documentId ? holderVisaInfo.documentId : '';
    const documentTypeString: string = holderVisaInfo?.documentType ? holderVisaInfo.documentType : 'X';
    const documentType = DocumentTypeEnum[documentTypeString as keyof typeof DocumentTypeEnum];
    const visaTypeAndNumber = holderVisaInfo?.visaTypeAndNumber ? holderVisaInfo.visaTypeAndNumber : '';
    const visaValidityPeriod = holderVisaInfo?.visaValidityPeriod ? holderVisaInfo.visaValidityPeriod : '';

    return (
        <div className="">
            <div className="reservation-details-header">
                <DocumentHeader setHeaderHeight={setHeaderHeight} />
            </div>
            <div className="reservation-details-footer">
                <DocumentFooter setFooterHeight={setFooterHeight} />
            </div>
            <table className="w-100">
                <thead>
                    <tr>
                        <td>
                            <div style={{ height: `${headerHeight}px` }}></div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className="bg-white p-4">
                                <PageTitle title={translate('Reservation details')} />
                                <div className="d-flex p-2 reservation-items m-2">
                                    <div className="col-6">
                                        <i className="fa fa-hotel mr-1" aria-hidden="true"></i>
                                        {translate('Room')}:
                                    </div>
                                    <div className="col-6">
                                        {reservation?.RoomInfo?.name ? reservation.RoomInfo.name : ''}
                                    </div>
                                </div>
                                <div className="d-flex p-2 reservation-items m-2">
                                    <div className="col-6">
                                        <i className="fa fa-building mr-1" aria-hidden="true"></i>
                                        {translate('Company Name')}:
                                    </div>
                                    <div className="col-6">{companyName}</div>
                                </div>
                                <div className="d-flex p-2 reservation-items m-2">
                                    <div className="col-6">
                                        <i className="fa fa-user mr-1" aria-hidden="true"></i>
                                        {translate('Reservation holder')}:
                                    </div>
                                    <div className="col-6">{customerName}</div>
                                </div>
                                <div className="d-flex p-2 reservation-items m-2">
                                    <div className="col-6">
                                        <i className="fa fa-user mr-1" aria-hidden="true"></i>
                                        {translate('Personal document id')}:
                                    </div>
                                    <div className="col-6">{`${documentId} - ${translate(documentType)}`}</div>
                                </div>
                                <div className="d-flex p-2 reservation-items m-2">
                                    <div className="col-6">
                                        <i className="fa fa-user mr-1" aria-hidden="true"></i>
                                        {translate('Visa Type And Number')}:
                                    </div>
                                    <div className="col-6">{visaTypeAndNumber}</div>
                                </div>
                                <div className="d-flex p-2 reservation-items m-2">
                                    <div className="col-6">
                                        <i className="fa fa-user mr-1" aria-hidden="true"></i>
                                        {translate('Visa Validity Period')}:
                                    </div>
                                    <div className="col-6">{visaValidityPeriod}</div>
                                </div>

                                <div className="d-flex p-2 reservation-items m-2">
                                    <div className="col-6">
                                        <i className="fa fa-users mr-1" aria-hidden="true"></i>
                                        {translate('Guests')}:
                                    </div>
                                    <div className="col-6">
                                        {reservation.Guests?.map((g) => {
                                            if (g.id !== reservation.customerId) {
                                                return g.firstName + ' ' + g.lastName + ', ';
                                            } else {
                                                return '';
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="d-flex p-2 reservation-items m-2">
                                    <div className="col-6">
                                        <i className="fa fa-calendar-check-o mr-1" aria-hidden="true"></i>
                                        {translate('Check in')}:
                                    </div>
                                    <div className="col-6">{reservationCheckIn}</div>
                                </div>
                                <div className="d-flex p-2 reservation-items m-2">
                                    <div className="col-6">
                                        <i className="fa fa-sign-out mr-1" aria-hidden="true"></i>
                                        {translate('Check out')}:
                                    </div>
                                    <div className="col-6">{reservationCheckOut}</div>
                                </div>
                                <div className="d-flex p-2 reservation-items m-2">
                                    <div className="col-6">
                                        <i className="fa fa-bed mr-1" aria-hidden="true"></i>{' '}
                                        {translate('Beds/Spare beds')}:
                                    </div>
                                    <div className="col-6">
                                        {' '}
                                        {reservation?.RoomInfo?.bedCount} / {reservation?.RoomInfo?.spareBedCount}
                                    </div>
                                </div>
                                <div className="d-flex p-2 reservation-items m-2">
                                    <div className="col-6">
                                        <i className="fa fa-users mr-1" aria-hidden="true"></i>{' '}
                                        {translate('Adults/Children')}:
                                    </div>
                                    <div className="col-6">
                                        {reservation.adultsNumber} / {reservation.childrenNumber}
                                    </div>
                                </div>
                                <div className="d-flex p-2 reservation-items m-2">
                                    <div className="col-6">
                                        <i className="fa fa-info mr-1" aria-hidden="true"></i>
                                        {translate('Reservation status')}:
                                    </div>
                                    <div className="col-6">
                                        <b>{reservationStatusEnumsDesc[reservation.statusEnum]}</b>
                                    </div>
                                </div>
                                <div className="d-flex p-2 reservation-items m-2">
                                    <div className="col-6">
                                        <i className="fa fa-sticky-note-o mr-1" aria-hidden="true"></i>
                                        {translate('Notes')}:
                                    </div>
                                    <div className="col-6">{reservation.notes}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <div style={{ height: `${footerHeight}px` }}></div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
}

export default ReservationPrintDetails;
