import Keycloak from 'keycloak-js';
import rest from './data/restWrapper';
import useStore from './data/state/zustand';
const keycloak = new Keycloak('/keycloakConfig');

async function setStateToken(authenticated) {
    const { realm, realmAccess, resourceAccess, sessionId, token, tokenParsed, refreshToken } = keycloak;
    let role = undefined;
    const instanceNameObj = await rest('/getInstanceName');
    let client = instanceNameObj?.instanceName ? instanceNameObj?.instanceName : 'development';
    if (tokenParsed.resource_access[client] && tokenParsed.resource_access[client]?.roles[0]) {
        role = tokenParsed.resource_access[client].roles[0];
    }
    let authToken = await rest('/checkAndAuthenticateKeycloakUser', 'POST', {
        keycloakTokenParsed: { ...tokenParsed, role: role },
    });
    useStore.getState().setNestedProperty(['keycloak'], {
        realm,
        realmAccess,
        resourceAccess,
        sessionId,
        token,
        tokenParsed,
        refreshToken,
        authenticated,
    });
    useStore.getState().setNestedProperty(['authTokenInfo'], {
        ...authToken,
    });
}

async function handleTokenRefresh() {
    const refreshed = await keycloak.updateToken(30);
    if (refreshed) {
        setStateToken(refreshed);
    }
}

export async function initKeycloak(params) {
    keycloak.onTokenExpired = () => handleTokenRefresh();
    const isAuthenticated = await keycloak.init({
        //onLoad: 'login-required',
        checkLoginIframe: true,
        ...params,
    });
    if (isAuthenticated) {
        setStateToken(isAuthenticated);
    }
    return keycloak;
}

export async function logout() {
    try {
        if (keycloak.authenticated) {
            // await keycloak.logout()
            await keycloak.logout({ redirectUri: window.location.href });
            return true;
        } else {
            return true;
        }
    } catch (err) {
        console.log('Could not log out. ', err);
    }
}

export function attachAuthorizationHeader(headers = {}) {
    if (keycloak.token) {
        headers['Authorization'] = `Bearer ${keycloak.token}`;
    }
}

export function checkClientRole(roleName) {
    return keycloak.hasResourceRole(roleName);
}
export function checkRealmRole(roleName) {
    return keycloak.hasRealmRole(roleName);
}
