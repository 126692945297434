import * as React from 'react';
import { translate } from '@data/translations';
import { Reservation, ReservationController } from './resController';
import { ReservationModel, ReservationInvoicedStatus, ReservationStatus } from '@common/modelDefinition';
import MiniLoader from '@components/loaders/miniLoader';
import ReservationGuestReference from './reservations/reservationGuestReference';
import ReservationNotifications from './reservations/reservationNotifications';
import MainReservationInfo from './reservations/mainReservationInfo';
import MinibarConsumption from './reservations/minibarConsumption';
import PosTerminalConsumption from './reservations/posTerminalConsumption';
import SelfCheckIn from './reservations/selfCheckIn';
import { ReactComponent as ChevronDown } from '../../assets/custom/icons/chevron_down.svg';
import { ReactComponent as Bell } from '../../assets/custom/icons/bell.svg';
import { ReactComponent as Edit } from '../../assets/custom/icons/edit.svg';
import { ReactComponent as Change } from '../../assets/custom/icons/change.svg';
import { ReactComponent as DeleteIcon } from '../../assets/custom/icons/trash.svg';
import { ReactComponent as Info } from '../../assets/custom/icons/info.svg';
import CondoOwnerRestrictionWrapper from './CondoOwnerRestrictionWrapper';
import { closeModal, setModalContent } from '@components/modal';
import FolderBox from '@components/documentTools/folderBox';
import ShowAvailableRoomsForReservationModal from './reservations/ChangeRoomModal';
import PermissionCheck from '@components/PermissionCheck';
import getRoomSRCStatusIcon, { getRoomSRCStatus } from '@data/controllers/SRCStatus';
import BasicSRCSettings from '../roomInfo/components/basicSRCSettingsModal';
import { reservationStatusEnumsDesc } from '@data/reservationStatusEnums';
import ConfirmButtons from '@components/buttons/confirmButtons';
import InlineModal from '@components/modalInline';
import HouseholdTask from './reservations/householdTask';
import useStore from '@data/state/zustand';
import Tooltip from '@components/tooltip/tooltip';

interface TabsBodyProps {
    history: any;
    loading: boolean;
    setLoading: Function;
    showReservationDetails?: Function;
}

const ReservationLinkTabs: any = {
    0: 'General',
    1: 'Guest info',
    2: 'Minibar',
    3: 'Terminal',
    4: 'Self check in',
    5: 'Household',
};

enum ReservationLinkTabsEnum {
    'general' = 0,
    'guestInfo' = 1,
    'minibar' = 2,
    'terminal' = 3,
    'selfCheckIn' = 4,
    'household' = 5,
}

function TabsBody(props: TabsBodyProps) {
    const { history, showReservationDetails } = props;
    const [activeTab, setActiveTab] = React.useState(0);
    const [showNoteModal, setShowNoteModal] = React.useState(false as boolean);
    const [showMoreOptions, setShowMoreOptions] = React.useState(false as boolean);
    const [showOnlyOneSRC, setShowOnlyOneSRC] = React.useState(true as boolean);

    const ctrl = new ReservationController();

    const reservations: Reservation[] = useStore((appState: any) => appState.state.reservationForm2.reservations);
    const otherSettings: any = useStore((appState: any) => appState.otherSettings);

    const reservation = ctrl.getActiveReservation();
    const groupRes = ctrl.getGroupReservation();

    const onFolderUpdated = async (documents: any) => {
        const res = { ...reservation };
        res.documents = documents;
        ctrl.updateActiveReservation(res as ReservationModel);
    };

    if (!reservation || !reservation.RoomInfo) {
        return <MiniLoader />;
    }

    // const style = loading ? { opacity: '0.4' } : {};
    // const unEditable = loading ? 'unEditable' : '';
    let isGroupInvoiceIssued = false;
    if (groupRes && groupRes.invoicedStatus !== ReservationInvoicedStatus.notPaid) {
        isGroupInvoiceIssued = true;
    }

    let disableSettingButton = true;
    let disableSettingButton2 = true;
    let disableSettingButton3 = true;

    const checkInToday = reservation
        ? new Date(reservation.checkInTimestamp).toISOString().substr(0, 10) === new Date().toISOString().substr(0, 10)
        : false;

    if (
        reservation.statusEnum === ReservationStatus.checkedIn ||
        reservation.statusEnum === ReservationStatus.confirmed
    ) {
        disableSettingButton = false;
        disableSettingButton2 = false;
        disableSettingButton3 = false;
    } else if (checkInToday) {
        disableSettingButton = false;
        disableSettingButton2 = false;
        disableSettingButton3 = false;
    }

    //disable if not connected
    if (reservation?.RoomInfo?.remoteDeviceId && getRoomSRCStatus(reservation?.RoomInfo?.remoteDeviceId) >= 3) {
        disableSettingButton = true;
    }

    if (reservation?.RoomInfo?.remoteDeviceId2 && getRoomSRCStatus(reservation?.RoomInfo?.remoteDeviceId2) >= 3) {
        disableSettingButton2 = true;
    }

    if (reservation?.RoomInfo?.remoteDeviceId3 && getRoomSRCStatus(reservation?.RoomInfo?.remoteDeviceId3) >= 3) {
        disableSettingButton3 = true;
    }

    return (
        <>
            <CondoOwnerRestrictionWrapper history={history} reservation={reservation}>
                <div className="reservation-body-wrapper mt-2p mb-8p">
                    <InlineModal
                        show={showNoteModal}
                        header={translate('Add document')}
                        width="md"
                        setShow={setShowNoteModal}
                    >
                        {showNoteModal ? <div>
                            <FolderBox onFolderUpdated={onFolderUpdated} {...reservation} />
                        </div> : null}
                    </InlineModal>

                    <div className="flex-center flex-column">
                        <div className="flex-center-between mb-8p w-100">
                            <div className="display-flex" style={{ flexDirection: 'column' }}>
                                <div className="reservation-room">
                                    {translate('Room')} {reservation?.RoomInfo?.name} (
                                    {translate(reservationStatusEnumsDesc[reservation.statusEnum])})
                                </div>
                                <div className="mt-4p text-bold color-warning">
                                    {reservation?.providerReservation ? translate('Provider reservation') : ''}
                                </div>
                            </div>
                            <div className="display-flex">
                                {reservation?.RoomInfo?.remoteDeviceId === null ? null : reservation?.RoomInfo
                                      ?.remoteDeviceId2 ? null : (
                                    <RoomController1
                                        disableSettingButton={disableSettingButton}
                                        reservation={reservation}
                                        showOnlyOneSRC={showOnlyOneSRC}
                                        disableSettingButton2={disableSettingButton2}
                                        disableSettingButton3={disableSettingButton3}
                                    />
                                )}
                                {((groupRes && groupRes?.Reservations) || reservation?.id) && (
                                    <div>
                                        <div
                                            className="button-info mr-4p pointer pb-8p"
                                            onClick={() => {
                                                if (groupRes && groupRes.uuid) {
                                                    history.push('/issueGroupInvoice/' + groupRes.uuid);
                                                } else {
                                                    const reservation = ctrl.getReservations()[0];
                                                    history.push('/checkoutAndInvoice/' + reservation.id);
                                                }
                                            }}
                                        >
                                            {/* <i className="fa fa-print" />  */}
                                            {translate('Invoices')}
                                        </div>
                                    </div>
                                )}

                                <div
                                    className="mr-4p pointer"
                                    onClick={() => {
                                        setModalContent(
                                            <ReservationNotifications history={history} reservation={reservation} />,
                                            translate('Reservation notifications'),
                                            false,
                                            'modal-lg'
                                        );
                                    }}
                                >
                                    <Bell />
                                </div>
                                {reservation?.id && showReservationDetails ? (
                                    <div
                                        className="mr-4p pointer"
                                        onClick={() => {
                                            if (showReservationDetails && reservation?.id) {
                                                showReservationDetails(reservation?.id);
                                            }
                                        }}
                                    >
                                        <Info />
                                    </div>
                                ) : null}
                                <div className="pointer" onClick={() => setShowMoreOptions(!showMoreOptions)}>
                                    <ChevronDown />
                                    {showMoreOptions ? (
                                        <div className="more-options-wrapper shadow-sm">
                                            <div
                                                className="more-options"
                                                onClick={() => {
                                                    setShowNoteModal(true);
                                                }}
                                            >
                                                <div className=" mr-8p">
                                                    <Edit />
                                                </div>
                                                <div>{translate('Add document')}</div>
                                            </div>
                                            <div
                                                className="more-options"
                                                onClick={() => {
                                                    setModalContent(
                                                        <ShowAvailableRoomsForReservationModal
                                                            reservation={{ ...reservation }}
                                                        />,
                                                        translate('Confirm ') + translate('change room'),
                                                        false,
                                                        'modal-lg'
                                                    );
                                                }}
                                            >
                                                <div className=" mr-8p">
                                                    <Change />
                                                </div>
                                                <div>{translate('Change room')}</div>
                                            </div>
                                            <hr
                                                style={{
                                                    width: '90%',
                                                    paddingRight: '5px',
                                                    paddingLeft: '5px',
                                                    borderTop: '1px solid lightgray',
                                                    marginTop: '5px',
                                                    marginBottom: '5px',
                                                }}
                                            />
                                            {reservation.groupUuid && reservation.id && !isGroupInvoiceIssued ? (
                                                <div
                                                    className="more-options color-error"
                                                    onClick={() =>
                                                        setModalContent(
                                                            <div className="container-fluid">
                                                                <div className="text-center">
                                                                    {translate(
                                                                        'Do you really want to remove this reservation from group? Please confirm and reservation will become standalone!'
                                                                    )}
                                                                    <hr />
                                                                </div>
                                                                <div className="text-center">
                                                                    <ConfirmButtons
                                                                        onConfirm={async () => {
                                                                            await ctrl.removeFromGroupRes();
                                                                            closeModal();
                                                                        }}
                                                                        onCancel={() => {
                                                                            closeModal();
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>,
                                                            translate('Remove from group'), // header
                                                            false, // showCloseButton
                                                            'modal-md'
                                                        )
                                                    }
                                                >
                                                    <div className=" mr-8p fill-red">
                                                        <DeleteIcon />
                                                    </div>
                                                    <div>{translate('Remove from group')}</div>
                                                </div>
                                            ) : reservation.id &&
                                              !reservation.groupUuid &&
                                              !reservation.previousGroupUuid &&
                                              ![4, 5].includes(reservation.statusEnum) ? (
                                                <div
                                                    className="more-options color-error"
                                                    onClick={() =>
                                                        history.push('/groupInfos?reservationId=' + reservation.id)
                                                    }
                                                >
                                                    <div className=" mr-8p fill-red">
                                                        <svg width="32" height="32"></svg>
                                                    </div>
                                                    <div>{translate('Attach to group')}</div>
                                                </div>
                                            ) : null}
                                            {reservation.groupUuid &&
                                            reservation.id &&
                                            reservations?.every((r) => r.statusEnum === 1 && !isGroupInvoiceIssued) ? (
                                                <div
                                                    className="more-options color-error"
                                                    onClick={() =>
                                                        setModalContent(
                                                            <div className="container-fluid">
                                                                <div className="text-center">
                                                                    {translate(
                                                                        'Do you really want to cancel all this reservations?'
                                                                    )}
                                                                    <hr />
                                                                </div>
                                                                <div className="text-center">
                                                                    <ConfirmButtons
                                                                        onConfirm={async () => {
                                                                            for (
                                                                                let i = 0;
                                                                                i < reservations?.length;
                                                                                i++
                                                                            ) {
                                                                                if (reservations[i]) {
                                                                                    ctrl.setActiveReservationIndex(i);
                                                                                    let res = { ...reservations[i] };
                                                                                    res.statusEnum =
                                                                                        ReservationStatus.canceled;
                                                                                    ctrl.updateActiveReservation(
                                                                                        res as ReservationModel
                                                                                    );
                                                                                }
                                                                            }
                                                                            closeModal();
                                                                        }}
                                                                        onCancel={() => {
                                                                            closeModal();
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>,
                                                            translate('Cancel all reservations'), // header
                                                            false, // showCloseButton
                                                            'modal-md'
                                                        )
                                                    }
                                                >
                                                    <div className=" mr-8p fill-red">
                                                        <svg width="32" height="32"></svg>
                                                    </div>
                                                    <div>{translate('Cancel all reservations')}</div>
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        {reservation?.RoomInfo?.remoteDeviceId2 ? (
                            <div className="flex-center-right w-100">
                                {reservation?.RoomInfo?.remoteDeviceId === null ? null : (
                                    <RoomController1
                                        disableSettingButton={disableSettingButton}
                                        reservation={reservation}
                                        showOnlyOneSRC={showOnlyOneSRC}
                                        disableSettingButton2={disableSettingButton2}
                                        disableSettingButton3={disableSettingButton3}
                                    />
                                )}
                                {!showOnlyOneSRC && reservation?.RoomInfo?.remoteDeviceId2 ? (
                                    <RoomController2
                                        disableSettingButton={disableSettingButton}
                                        reservation={reservation}
                                        showOnlyOneSRC={showOnlyOneSRC}
                                        disableSettingButton2={disableSettingButton2}
                                        disableSettingButton3={disableSettingButton3}
                                    />
                                ) : null}
                                {!showOnlyOneSRC && reservation?.RoomInfo?.remoteDeviceId3 ? (
                                    <RoomController3
                                        disableSettingButton={disableSettingButton}
                                        reservation={reservation}
                                        showOnlyOneSRC={showOnlyOneSRC}
                                        disableSettingButton2={disableSettingButton2}
                                        disableSettingButton3={disableSettingButton3}
                                    />
                                ) : null}
                                {reservation?.RoomInfo?.remoteDeviceId &&
                                (reservation?.RoomInfo?.remoteDeviceId2 || reservation?.RoomInfo?.remoteDeviceId3) ? (
                                    <div className="ml-8p" style={{ width: '20px' }}>
                                        <Tooltip
                                            message={translate(
                                                `If checked, the guest card is programmed to all controllers at once.`
                                            )}
                                        >
                                            <i
                                                onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    setShowOnlyOneSRC(!showOnlyOneSRC);
                                                }}
                                                className={
                                                    showOnlyOneSRC
                                                        ? 'fa fa-check-square-o fa-lg mr-4p'
                                                        : 'fa fa-square-o fa-lg mr-4p'
                                                }
                                            ></i>
                                        </Tooltip>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>

                    <div className="reservation-form-tabs">
                        {Object.keys(ReservationLinkTabs)?.map((t: string, index: number) => {
                            return (
                                <div
                                    onClick={() => {
                                        if (reservation?.id) {
                                            setActiveTab(index);
                                        }
                                    }}
                                    className={
                                        ' reservation-form-tabs-single ' + (activeTab === index ? ' active-tab ' : '')
                                    }
                                    style={{ color: reservation?.id ? 'black' : '' }}
                                    key={index}
                                >
                                    {translate(ReservationLinkTabs[t])}
                                </div>
                            );
                        })}
                    </div>

                    {activeTab === ReservationLinkTabsEnum.general ? (
                        <MainReservationInfo history={history} otherSettings={otherSettings} />
                    ) : null}

                    {activeTab === ReservationLinkTabsEnum.guestInfo && reservation?.id !== undefined ? (
                        <ReservationGuestReference
                            history={history}
                            reservation={reservation}
                            otherSettings={otherSettings}
                        />
                    ) : null}

                    {activeTab === ReservationLinkTabsEnum.minibar && reservation?.id !== undefined ? (
                        <MinibarConsumption history={history} reservation={reservation} />
                    ) : null}
                    {activeTab === ReservationLinkTabsEnum.household && reservation?.id !== undefined ? (
                        <HouseholdTask history={history} reservation={reservation} />
                    ) : null}

                    {activeTab === ReservationLinkTabsEnum.terminal && reservation?.id !== undefined ? (
                        <PosTerminalConsumption history={history} reservation={reservation} />
                    ) : null}

                    {activeTab === ReservationLinkTabsEnum.selfCheckIn && reservation?.id !== undefined ? (
                        <SelfCheckIn />
                    ) : null}
                </div>
            </CondoOwnerRestrictionWrapper>
        </>
    );
}

interface RoomControllerProps {
    disableSettingButton: boolean;
    reservation: Reservation;
    showOnlyOneSRC: boolean;
    disableSettingButton2: boolean;
    disableSettingButton3: boolean;
}

const RoomController1 = (props: RoomControllerProps) => {
    const { disableSettingButton, reservation, showOnlyOneSRC } = props;
    if (!reservation?.RoomInfo?.remoteDeviceId) {
        return null;
    }

    return (
        <div className="mr-4p pointer">
            <PermissionCheck name="roomInfo_roomSettings">
                <button
                    disabled={disableSettingButton}
                    title={translate('Room settings')}
                    onClick={() => {
                        const roomName = reservation?.RoomInfo?.name;
                        const basicSRCSettings = (
                            <BasicSRCSettings
                                roomInfoId={reservation?.RoomInfo?.id}
                                key={reservation?.RoomInfo?.id}
                                reservation={reservation}
                                remoteDeviceId={
                                    reservation?.RoomInfo?.remoteDeviceId ? reservation?.RoomInfo?.remoteDeviceId : null
                                }
                                showOnlyOneSRC={showOnlyOneSRC}
                            />
                        );
                        setModalContent(
                            basicSRCSettings,
                            <span>
                                {translate('Room settings') + ' - '} <b>{roomName}</b>
                            </span>,
                            true,
                            'modal-xl'
                        );
                    }}
                    className={
                        (reservation?.RoomInfo?.remoteDeviceId === null ||
                        getRoomSRCStatusIcon(reservation?.RoomInfo?.remoteDeviceId) === null
                            ? 'button-light  disabled mb-2 '
                            : 'button-light   mb-2') + ' mr-1'
                    }
                    style={{ padding: 0 }}
                >
                    <span className="d-inline-flex">
                        {getRoomSRCStatusIcon(reservation?.RoomInfo?.remoteDeviceId)}{' '}
                    </span>
                    <i className="fa fa-cog" style={{ paddingRight: '6px' }} />
                </button>
            </PermissionCheck>
        </div>
    );
};

const RoomController2 = (props: RoomControllerProps) => {
    const { disableSettingButton2, reservation } = props;

    if (!reservation?.RoomInfo?.remoteDeviceId2) {
        return null;
    }

    return (
        <div className="mr-4p pointer">
            <PermissionCheck name="roomInfo_roomSettings">
                <button
                    disabled={disableSettingButton2}
                    title={translate('Room settings')}
                    onClick={() => {
                        const roomName = reservation?.RoomInfo?.name;
                        const basicSRCSettings = (
                            <BasicSRCSettings
                                roomInfoId={reservation?.RoomInfo?.id}
                                key={reservation?.RoomInfo?.id}
                                reservation={reservation}
                                remoteDeviceId={reservation?.RoomInfo?.remoteDeviceId2}
                            />
                        );
                        setModalContent(
                            basicSRCSettings,
                            <span>
                                {translate('Room settings') + ' - '} <b>{roomName}</b>
                            </span>,
                            true,
                            'modal-xl'
                        );
                    }}
                    className={
                        (reservation?.RoomInfo?.remoteDeviceId2 === null ||
                        getRoomSRCStatusIcon(reservation?.RoomInfo?.remoteDeviceId2) === null
                            ? 'button-light  disabled mb-2 '
                            : 'button-light   mb-2') + ' mr-1'
                    }
                    style={{ padding: 0 }}
                >
                    <span className="d-inline-flex">
                        {getRoomSRCStatusIcon(reservation?.RoomInfo?.remoteDeviceId2)}{' '}
                    </span>
                    <i className="fa fa-cog" style={{ paddingRight: '6px' }} />
                </button>
            </PermissionCheck>
        </div>
    );
};

const RoomController3 = (props: RoomControllerProps) => {
    const { disableSettingButton3, reservation } = props;

    if (!reservation?.RoomInfo?.remoteDeviceId3) {
        return null;
    }

    return (
        <div className="mr-4p pointer">
            <PermissionCheck name="roomInfo_roomSettings">
                <button
                    disabled={disableSettingButton3}
                    title={translate('Room settings')}
                    onClick={() => {
                        const roomName = reservation?.RoomInfo?.name;
                        const basicSRCSettings = (
                            <BasicSRCSettings
                                roomInfoId={reservation?.RoomInfo?.id}
                                key={reservation?.RoomInfo?.id}
                                reservation={reservation}
                                remoteDeviceId={reservation?.RoomInfo?.remoteDeviceId3}
                            />
                        );
                        setModalContent(
                            basicSRCSettings,
                            <span>
                                {translate('Room settings') + ' - '} <b>{roomName}</b>
                            </span>,
                            true,
                            'modal-xl'
                        );
                    }}
                    className={
                        (reservation?.RoomInfo?.remoteDeviceId3 === null ||
                        getRoomSRCStatusIcon(reservation?.RoomInfo?.remoteDeviceId3) === null
                            ? 'button-light  disabled mb-2 '
                            : 'button-light   mb-2') + ' mr-1'
                    }
                    style={{ padding: 0 }}
                >
                    <span className="d-inline-flex">
                        {getRoomSRCStatusIcon(reservation?.RoomInfo?.remoteDeviceId3)}{' '}
                    </span>
                    <i className="fa fa-cog" style={{ paddingRight: '6px' }} />
                </button>
            </PermissionCheck>
        </div>
    );
};

export default TabsBody;
