import { rest } from '../restWrapper';
import {loadUserCondoData} from "../../pages/newReservations/condoOwnerView/condoApi"
import useStore from '@data/state/zustand';

const reloadReservations = async ()=>{
	const loggedUser = useStore.getState().authTokenInfo?.user
	const permissions = useStore.getState().state?.userPermissions

	if(loggedUser?.role==="condoUser"){
		await loadUserCondoData();

	} else if(permissions?.readModels.includes("Reservation")){
		const incommingReservations = await rest('/getIncommingReservations');
    	const checkedInReservations = await rest('/getAllCheckedInReservations');
    	const Reservation = incommingReservations.concat(checkedInReservations);
		useStore.getState().setNestedProperty(['model', 'Reservation'], Reservation)
	}
}


export {reloadReservations}