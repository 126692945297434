import { InvoiceConclusionDetailsModel, ReservationGuestBoard, InvoiceConclusionItemModel } from "@common/modelDefinition";


export const getGroupedInvoiceConclusionsByTypeOfPlus = (invoiceConclusionDetails: InvoiceConclusionDetailsModel[]) => {
    const groupedInvoiceConclusionDetails = invoiceConclusionDetails.reduce((result: any, invoiceConclusionDetail: InvoiceConclusionDetailsModel) => {
        const type = invoiceConclusionDetail.type;
        if (type) {
            if (!result[type]) {
                result[type] = [];
            }
            result[type].push(invoiceConclusionDetail);
        }
        return result;
    }, {});
    return groupedInvoiceConclusionDetails;
};

export const getGroupedInvoiceConclusionsBySubTypeOfPlus = (invoiceConclusionDetails: InvoiceConclusionDetailsModel[]) => {
    const groupedInvoiceConclusionDetails = invoiceConclusionDetails.reduce((result: any, invoiceConclusionDetail: InvoiceConclusionDetailsModel) => {
        const subtype = invoiceConclusionDetail.subtype ?  invoiceConclusionDetail.subtype : 'null'


        if (subtype) {

            if (!result[subtype]) {
                result[subtype] = [];
            }

            result[subtype].push(invoiceConclusionDetail);
        }
        return result;
    }, {});

    return groupedInvoiceConclusionDetails;
};


export const getGroupedReservationBoardsByType = (reservationGuestBoards: ReservationGuestBoard[]) => {
    const groupedReservationBoardsByType = reservationGuestBoards.reduce((result: any, rgb: ReservationGuestBoard) => {
        const type = rgb.type 

        if (type) {

            if (!result[type]) {
                result[type] = [];
            }

            result[type].push(rgb);
        }
        return result;
    }, {});

    return groupedReservationBoardsByType;
};
export const getGroupedReservationBoardsByRoomInfo = (reservationGuestBoards: ReservationGuestBoard[]) => {
    const groupedReservationBoardsByRoomInfo = reservationGuestBoards.reduce((result: any, rgb: ReservationGuestBoard) => {
        const roomInfoId = rgb.roomInfoId 

        if (roomInfoId) {

            if (!result[roomInfoId]) {
                result[roomInfoId] = [];
            }

            result[roomInfoId].push(rgb);
        }
        return result;
    }, {});

    return groupedReservationBoardsByRoomInfo
}
export const getGroupedInvoiceConclusionItemsByClientType =  (
    invoiceConclusionItems:  InvoiceConclusionItemModel[]
) => {
    const groupedInvoiceConclusionItemsByClientType = invoiceConclusionItems.reduce((result: any, invoiceConclusionItem: any) => {
        const clientType = invoiceConclusionItem?.clientType ? invoiceConclusionItem.clientType : 2 // Enum, 1 je Račun na tvrtku, 2 je račun na gosta
        if (clientType) {
            if (!result[clientType]) {
                result[clientType] = [invoiceConclusionItem]
            } else {
                result[clientType].push(invoiceConclusionItem)
            }
        }
        return result;
    }, {});
    return groupedInvoiceConclusionItemsByClientType;
};