import rest from '../../data/restWrapper';
import { startGlobalLoading, stopGlobalLoading } from '../../components/loaders/globalLoading';
import { closeModal } from '../../components/modal';

//software backed ticket close
async function forceDiscardRoomEventTicket(id: number, notes: string) {
    startGlobalLoading();
    try {
        const ticket = await rest('/forceDiscardRoomEventTicket', 'POST', { id, notes });
        closeModal();
        stopGlobalLoading();
        return ticket;
    } catch (err) {
        stopGlobalLoading();
        console.warn(err);
    }
}

//this action will initiate hardware closure on SRC controller.
//if hardware is not connected, then closure would fail. Then you can use forceDiscardRoomEventTicket
async function hardwareCloseRoomEventTicket(remoteDeviceId: string, roomEvent: any) {
    startGlobalLoading();
    try {
        let eventClone = { ...roomEvent };
        eventClone.value = false; //turn off
        await rest('/src/receptionSetToggleStates/' + remoteDeviceId, 'POST', eventClone);
        closeModal();
        stopGlobalLoading();
    } catch (err) {
        stopGlobalLoading();
        console.warn(err);
    }
}

async function getAllEventsToClose(remoteDeviceId: string, roomInfoId: number) {
    startGlobalLoading();
    try {
        const oldEvents = await rest('/getAllEventsToClose', 'POST', { remoteDeviceId, roomInfoId });
        stopGlobalLoading();
        return oldEvents;
    } catch (err) {
        stopGlobalLoading();
        console.warn(err);
    }
}

async function closeAllOldEvents(events: any) {
    startGlobalLoading();
    try {
        await rest('/closeAllEvents', 'POST', { events });
        stopGlobalLoading();
    } catch (err) {
        stopGlobalLoading();
        console.warn(err);
    }
}

async function getActiveEventsWithoutAssignedRoom() {
    startGlobalLoading();
    try {
        const events = await rest('/getActiveEventsWithoutAssignedRoom', 'GET');
        stopGlobalLoading();
        return events;
    } catch (err) {
        stopGlobalLoading();
        console.warn(err);
    }
}

export {
    forceDiscardRoomEventTicket,
    hardwareCloseRoomEventTicket,
    getAllEventsToClose,
    closeAllOldEvents,
    getActiveEventsWithoutAssignedRoom,
};
