import React, { useState } from 'react';
import rest from '@data/restWrapper';
//@ts-ignore
import { withRouter } from 'react-router';
import { CompanyModel } from '@common/modelDefinition';
import translate from '@data/translations';
import { setModalContent } from '@components/modal';
import Form from '../company/form';

interface CompanySearchProps {
    forceDrawCreateForm?: boolean | undefined;
    callback?: any;
    createButtonClickedCallback?: any;
    cancelButtonClickedCallback?: any;
    history: any;
    showCreateButton?: any;
    createButtonText?: any;
    createFormHeader?: any;
    createForm?: any;
}

const CompanySearch = (props: CompanySearchProps) => {
    const [companies, setCompanies] = useState([] as CompanyModel[]);
    const [showCompanyForm, setShowCompanyForm] = useState(
        props.forceDrawCreateForm !== undefined ? props.forceDrawCreateForm : false
    );
    const [cancelModal] = useState(false);
    let searchDebouncer: any = null;

    const onSearch = async (e: any) => {
        clearTimeout(searchDebouncer);
        const searchTerm = e.target.value;
        searchDebouncer = setTimeout(async () => {
            if (searchTerm.length > 1) {
                const path = '/searchCompany?search=' + searchTerm;
                const _companies = await rest(path);
                setCompanies(_companies);
            }else{
                setCompanies([])
            }
        }, 400);
    };

    const onSelectCompany = (company: CompanyModel) => {
        if (props.callback) {
            props.callback(company);
        } else {
            setModalContent(
                <Form history={props.history} companyId={company?.id} customHeader={''} showPageTitle={false} />,
                company?.id ? translate('Edit company') : translate('Create company'),
                false,
                'modal-md'
            );
            // props.history.push('/companies/' + company.id + '/edit');
        }
    };

    const showNewCompanyForm = () => {
        if (props.createButtonClickedCallback) {
            props.createButtonClickedCallback();
        }
        setShowCompanyForm(true);
    };

    const cancelShowFormHandler = () => {
        if (props.cancelButtonClickedCallback) {
            props.cancelButtonClickedCallback();
        }
        setShowCompanyForm(false);
    };

    const list = companies.map((c: CompanyModel) => {
        const doc =
            c.idNumber && c.idNumber !== '' ? (
                <span className="mr-2">
                    <b>
                        <i className="fa fa-id-card-o mr-1" />
                        {c.idNumber}
                    </b>
                </span>
            ) : null;
        const name =
            c.name && c.name !== '' ? (
                <span className="mr-2">
                    <i className="fa fa-user mr-1" aria-hidden="true"></i>
                    {c.name}
                </span>
            ) : null;
        const town =
            c.town && c.town !== '' ? (
                <span className="mr-2">
                    <i className="fa fa-building mr-1" aria-hidden="true"></i> {c.town}
                </span>
            ) : null;
        const web =
            c.web && c.web !== '' ? (
                <span className="elipsis">
                    <i className="fa fa-globe mr-1" aria-hidden="true"></i>
                    {c.web}
                </span>
            ) : null;

        return (
            <li key={'c' + c.id} className="list-group-item">
                <button
                    onClick={()=>onSelectCompany(c)}
                    className="btn btn-sm btn-outline-primary pull-left mr-3"
                >
                    {translate('Select')}
                </button>
                <div className="mt-1 pull-left">
                    <small>
                        {doc}
                        {name}
                        {town}
                        {web}
                    </small>
                </div>
            </li>
        );
    });

    if (showCompanyForm) {
        const CompanyForm = props.createForm;
        return (
            <CompanyForm
                showPageTitle={false}
                customHeader={props.createFormHeader}
                cancelHandler={cancelShowFormHandler}
                cancelModal={cancelModal}
                callback={props.callback}
            />
        );
    } else {
        return (
            <div>
                <div className="input-group ">
                    {props.showCreateButton ? (
                        <div className="input-group-prepend">
                            <div
                                onClick={()=>showNewCompanyForm()}
                                className="button-primary"
                                // type="button"
                                // id="button-addon1"
                            >
                                {props.createButtonText ? props.createButtonText : translate('CREATE')}
                            </div>
                        </div>
                    ) : null}

                    <input
                        onChange={(e: any)=>onSearch(e)}
                        placeholder={translate('SEARCH FOR COMPANY (by name, town, idNumber)')}
                        type="text"
                        className="form-control form-control-sm"
                    />
                </div>

                {list.length > 0 ? <ul className="list-group list-group-flush">{list}</ul> : null}
            </div>
        );
    }
};

export default withRouter(CompanySearch);
