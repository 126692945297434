import restLib from 'rest';
import mime from 'rest/interceptor/mime';
import _ from 'lodash';
import { attachAuthorizationHeader } from '../../keycloak';
import { toast } from 'react-toastify';
import useStore from '@data/state/zustand';

const client = restLib.wrap(mime, { mime: 'application/json' });

const rest = async (path, method = 'GET', obj, headers = {}, useTokenHeader = true) => {
    return new Promise((resolve, reject) => {
        const token = useStore.getState().authTokenInfo?.token;
        let _headers = headers ? headers : {};
        if (useTokenHeader) {
            _headers = headers ? _.extend(headers, { token }) : { token };
        }

        let payload = { method: method, path: path, entity: obj, headers: _headers };

        attachAuthorizationHeader(payload.headers);
        client(payload)
            .then((response) => {
                if (response.entity) {
                    if (response.status.code >= 400) {
                        if (response.status.code === 401) {
                            useStore.getState().setNestedProperty(['authTokenInfo'], null)
                            window.location = '/';
                        } else if (response.status.code === 403) {
                            //new authorization module 25.3.2020
                            if (method !== 'GET') {
                                toast(response.status.text + ' ' + method + ' ' + path, { type: toast.TYPE.ERROR });
                            }

                            if (response.entity && response.entity.data) {
                                return resolve(response.entity.data);
                            } else {
                                const err = new Error(response.entity.details);
                                err.desc = response.entity.desc;
                                return reject(err);
                            }
                        } else {
                            if (response.entity.details) {
                                toast(response.entity.details, { type: toast.TYPE.ERROR });
                                const err = new Error(response.entity.details);
                                err.desc = response.entity.desc;
                                return reject(err);
                            } else {
                                toast(response.status.text + ' ' + method + ' ' + path, { type: toast.TYPE.ERROR });
                                const err = new Error(response.status.text + ' ' + method + ' ' + path);
                                err.desc = response.entity.desc;
                                return reject(err);
                            }
                        }
                    } else {
                        return resolve(response.entity.data);
                    }
                } else {
                    let err = new Error('Unexpected response');
                    toast(err.message, { type: toast.TYPE.ERROR });
                    return reject(err);
                }
            })
            .catch((err) => {
                toast(err.message, { type: toast.TYPE.ERROR });
                return reject(err);
            });
    });
};

const corsPost = async (url, payload) => {
    return await fetch(url, {
        mode: 'no-cors',
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    });
};

const corsGet = async (path) => {
    const response = await fetch(path);
    const myJson = await response.json();
    return myJson;
};

const fileUpload = async (path, inputFile) => {
    return new Promise((resolve, reject) => {
        let token = useStore.getState().authTokenInfo?.token
        let data = new FormData();
        data.append('file', inputFile);
        var fetchParamObj = {
            method: 'POST',
            body: data,
            headers: new Headers({ token: token }), //browser sets Content-Type header automatically
        };
        fetch(path, fetchParamObj)
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            })
            .then((parsedData) => {
                if (parsedData.data) {
                    return resolve(parsedData.data); //pimatico standard response
                } else {
                    return resolve(parsedData);
                }
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

const multipageLoad = async (baobabRoute, route, callCounter) => {
    var resultCount = 50; //<--------------------------SET RESULTS FOR ONE STAGE QUERY......
    var offset = 0;
    var querySymbol = '?';

    if (route.lastIndexOf('?') !== -1) {
        querySymbol = '&';
    }

    if (!_.isUndefined(callCounter)) {
        //it is not a first call
        offset = callCounter * resultCount;
    }

    return new Promise(async (resolve, reject) => {
        return rest(route + querySymbol + 'offset=' + offset + '&count=' + resultCount, 'GET', {}).then(async (res) => {
            if (res.length !== 0) {
                if (offset === 0) {
                    useStore.getState().setNestedProperty([baobabRoute], res)
                } else {
                    var existingData =  useStore.getState().baobabRoute
                    useStore.getState().setNestedProperty([baobabRoute], existingData.concat(res))
                }
                return resolve(await multipageLoad(baobabRoute, route, 1 + offset / resultCount));
            } else {
                return resolve(true); //done loading
            }
        });
    });
};

const get = async (path) => {
    return await rest(path, 'GET', null);
};
const post = async (path, obj) => {
    return await rest(path, 'POST', obj);
};
const put = async (path, obj) => {
    return await rest(path, 'PUT', obj);
};
const del = async (path) => {
    return await rest(path, 'DELETE');
};

export { multipageLoad, rest, corsGet, fileUpload, corsPost, get, post, put, del };

export default rest;
