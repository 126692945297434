import React from 'react';

import { ReactComponent as Kitchen } from '../../assets/custom/icons/oven.svg';
import { ReservationEditModel, ReservationGuestBoard } from '@common/modelDefinition';
import translate from '@data/translations';
import moment from 'moment';


export const getReservationGuestBoardsForDate = (reservations: ReservationEditModel[], day: string) => {
    const dayEnd = moment(new Date(day).getTime()).endOf('day').valueOf();
    const dayStart = moment(new Date(day).getTime()).startOf('day').valueOf();
    const result: ReservationGuestBoard[] = [];

    // Prolazak kroz sve rezervacije
    reservations.forEach((reservation: ReservationEditModel) => {
        const reservationGuestBoard: ReservationGuestBoard = {
            timestamp: null,
            reservationId: reservation.id,
            roomInfoId: reservation.roomInfoId,
            checkInTimestamp: reservation.checkInTimestamp,
            checkOutTimestamp: reservation.checkOutTimestamp,
            customerId: reservation.customerId ? reservation.customerId : null,
            firstName: null,
            lastName: null,
            reservationGuestReferenceId: null,
            type: null,
            board: reservation.board,
        };
        const { board, checkInTimestamp, checkOutTimestamp, adultsNumber, childrenNumber } = reservation;
        const totalPersonsCount =   Number(adultsNumber) + Number(childrenNumber);

        // Provjera da li je rezervacija unutar traženog perioda
        if (checkOutTimestamp > dayEnd && checkInTimestamp < dayStart) {
            // stay - svi obroci
            if (board === 'bb' || board === 'hb' || board === 'fb' || board === 'ai' || !board) {
                if (board === 'bb' || !board) {
                    for (let i = 1; i <= totalPersonsCount; i++) {
                        const newResBoard = { ...reservationGuestBoard };
                        newResBoard.type = 'firstMeal';
                        result.push(newResBoard);
                    }
                } else if (board === 'hb') {
                    for (const meal of ['firstMeal', 'thirdMeal']) {
                        for (let i = 1; i <= totalPersonsCount; i++) {
                            const newResBoard = { ...reservationGuestBoard };
                            newResBoard.type = meal;
                            result.push(newResBoard);
                        }
                    }
                } else if (board === 'fb' || board === 'ai') {
                    for (const meal of ['firstMeal', 'secondMeal', 'thirdMeal']) {
                        for (let i = 1; i <= totalPersonsCount; i++) {
                            const newResBoard = { ...reservationGuestBoard };
                            newResBoard.type = meal;
                            result.push(newResBoard);
                        }
                    }
                    if (reservation.id === 1868) {
                    }
                }
            }
        } else if (moment(checkInTimestamp).isSame(dayStart, 'day')) {
            // danas prijava, nema dorucka
            if (board === 'bb' || board === 'hb' || board === 'fb' || board === 'ai' || !board) {
                if (board === 'hb') {
                    for (const meal of ['secondMeal']) {
                        for (let i = 1; i <= totalPersonsCount; i++) {
                            const newResBoard = { ...reservationGuestBoard };
                            newResBoard.type = meal;
                            result.push(newResBoard);
                        }
                    }
                } else if (board === 'fb' || board === 'ai') {
                    for (const meal of ['secondMeal', 'thirdMeal']) {
                        for (let i = 1; i <= totalPersonsCount; i++) {
                            const newResBoard = { ...reservationGuestBoard };
                            newResBoard.type = meal;
                            result.push(newResBoard);
                        }
                    }
                }
            }
        } else if (moment(checkOutTimestamp).isSame(dayEnd, 'day')) {
            // danas odjava, samo dorucak
            if (board === 'bb' || board === 'hb' || board === 'fb' || board === 'ai' || !board) {
                for (const meal of ['firstMeal']) {
                    for (let i = 1; i <= totalPersonsCount; i++) {
                        const newResBoard = { ...reservationGuestBoard };
                        newResBoard.type = meal;
                        result.push(newResBoard);
                    }
                }
            }
        }
    });
    return result;
};

export interface ReservationGuestBoardsProps {
    reservations: ReservationEditModel[];
    from: number;
    to: number;
    period: string;
}

export const ReservationGuestBoards = (props: ReservationGuestBoardsProps) => {
    const { reservations, from, to } = props;
    const filteredReservations = reservations.filter(r=>r.statusEnum === 1 || r.statusEnum === 2)
    const filterReservationGuestBoards = getByMealTypeTotal(filteredReservations, from, to);
    // Korištenje funkcije
    // console.log('filterReservationGuestBoards', filterReservationGuestBoards);

    const firstMeals = filterReservationGuestBoards?.firstMeal;

    return (
        <div className="display-flex align-items-center mt-2">
            <div
                className="color-dark mt-1 ml-4"
                style={{
                    height: '40px',
                    width: '40px',
                    borderRadius: '80px',
                    padding: '4px',
                    backgroundColor: '#F3F6FB',
                }}
            >
                <Kitchen />
            </div>
            <div className="ml-16p">
                <div style={{ fontWeight: '700', fontSize: '20px' }}>{firstMeals.length}</div>
                <div style={{ fontWeight: '400', fontSize: '12px', color: '#69778C' }}>{translate('breakfasts')}</div>
            </div>
        </div>
    );
};

const getByMealTypeTotal = (reservations: ReservationEditModel[], from: number, to: number) => {
    const mealTypesTotal: any = {
        firstMeal: [],
        secondMeal: [],
        thirdMeal: [],
    };
    const daysArray = getDaysArray(from, to, false);

    for (const day of daysArray) {
        const reservationGuestBoardForDay = getReservationGuestBoardsForDate(reservations, day);
        reservationGuestBoardForDay.forEach((rgb) => {
            const { type } = rgb;
            if (type && typeof type === 'string') {
                mealTypesTotal[type].push(rgb);
            }
        });
    }

    return mealTypesTotal;
};

const getDaysArray = (start: number, end: number, forceFullISODayMatch = false) => {
    let arr = [];
    if (forceFullISODayMatch) {
        let startFrom = moment(new Date(start));
        let endISOString = new Date(end).toISOString().substr(0, 10);
        let tempStartISOString = new Date(start).toISOString().substr(0, 10);
        if (start < end) {
            if (tempStartISOString === endISOString) {
                arr.push(tempStartISOString);
            } else {
                while (tempStartISOString !== endISOString) {
                    tempStartISOString = new Date(startFrom.valueOf()).toISOString().substr(0, 10);
                    arr.push(tempStartISOString);
                    startFrom.add(1, 'd');
                }
            }
        }
    } else {
        for (var dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(moment(new Date(dt)).toISOString(true).substr(0, 10));
        }
    }
    return arr;
};
