import * as React from 'react';
import { getReservationNotifications, sendNotificationOnAction } from '../api';
import { MessageBrokerInformationModel, ReservationStatus } from '@common/modelDefinition';
import { Reservation } from '@pages/newReservations/resController';
import { translate } from '@data/translations';
import moment from 'moment';
import ConfirmModal from '@components/confirmModal';
import { setModalContent } from '@components/modal';
import useStore from '@data/state/zustand';

export enum MessageStatus {
    Pending = 0,
    Delivered = 1,
    Failed = 2,
    anceled = 3,
    aborted = 4,
    PendingThirdParty = 5,
    FailedThirdParty = 6,
    rejectedThirdParty = 7,
}

interface ReservationNotificationsProps {
    reservation: Reservation;
    history: any;
}

function ReservationNotifications(props: ReservationNotificationsProps) {
    const [reservationNotifications, setReservationNotifications] = React.useState<MessageBrokerInformationModel[]>([]);
    const [selfServiceCheckInId, setSelfServiceCheckInId] = React.useState(null as any);
    const [newInfo] = React.useState(false);

    const { reservation } = props;
    const otherSettings = useStore.getState().otherSettings;

    const loadDataSetState = async () => {
        if (reservation?.SelfServiceCheckIn?.id) {
            const _selfServiceCheckInId = reservation?.SelfServiceCheckIn?.id;
            setSelfServiceCheckInId(_selfServiceCheckInId);
        }
        if (reservation && reservation.id) {
            const _reservationNotifications = await getReservationNotifications(reservation.id, selfServiceCheckInId);
            setReservationNotifications(_reservationNotifications);
        }
    };

    React.useEffect(() => {
        const loadData = async () => {
            await loadDataSetState();
        };
        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [reservation?.id, selfServiceCheckInId, newInfo]);

    const sendNotificationAgain = async (model: string | undefined) => {
        if (model) {
            const textContent =
                model === 'Reservation'
                    ? 'Are you sure you want to send reservation confirmation email?'
                    : model === 'SelfServiceCheckIn'
                    ? 'Are you sure you want to send self check in email?'
                    : '';
            setModalContent(
                <ConfirmModal
                    actionForYes={async () => {
                        try {
                            await sendNotificationOnAction(model, reservation?.id);
                            await loadDataSetState();
                        } catch (err) {
                            console.warn(err);
                        }
                    }}
                    content={translate(textContent)}
                />,
                translate('Confirm'),
                false,
                'modal-md'
            );
        }
    };

    const sortedReservationNotifications = reservationNotifications.sort((a, b) => a.timestamp - b.timestamp);

    return (
        <div className="">
            <p></p>
            {sortedReservationNotifications.length === 0 ? (
                <small>{translate('List is empty')}</small>
            ) : (
                <div className="custom-list-wrapper" style={{ margin: 0 }}>
                    <div className="custom-list-header" style={{ margin: 0 }}>
                        <div style={{ width: '120px' }}>{translate('Date')}</div>
                        <div style={{ width: '150px' }}>{translate('Title')}</div>
                        <div style={{ width: '500px' }}>{translate('Message')}</div>
                        <div style={{ width: '100px' }}>{translate('Status')}</div>
                    </div>
                    {sortedReservationNotifications.map((t, key) => {
                        const statusType =
                            t.statusFlag || t.statusFlag === 0
                                ? (MessageStatus[t.statusFlag] as keyof typeof MessageStatus)
                                : '';
                        return (
                            <div
                                key={key}
                                className="custom-list-item custom-list-no-flex"
                                style={{ margin: 0, maxHeight: '700px', overflowY: 'auto', marginBottom: "8px" }}
                            >
                                <div style={{ width: '120px',  }}>{moment(t.timestamp).format('DD/MM/YYYY HH:mm')}</div>
                                <div style={{ width: '150px', }}>{t.subject}</div>
                                <div style={{ width: '500px', }}>{t.message}</div>
                                <div style={{ width: '100px', }}>{statusType}</div>
                            </div>
                        );
                    })}
                </div>
            )}
            {reservation?.statusEnum === ReservationStatus.confirmed ||
            reservation?.statusEnum === ReservationStatus.checkedIn ? (
                <div className="mt-16p" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {sortedReservationNotifications?.length > 0 && otherSettings?.sendReservationConfirmationToGuest ? (
                        <div
                            onClick={() => {
                                sendNotificationAgain('Reservation');
                            }}
                            className="m-2 button-info pointer"
                        >
                            <i className="fa fa-envelope pr-1"></i>
                            {translate('Send confirmation email again')}
                        </div>
                    ) : null}
                    {selfServiceCheckInId ? (
                        <div
                            onClick={() => {
                                sendNotificationAgain('SelfServiceCheckIn');
                            }}
                            className="m-2 button-info pointer"
                        >
                            <i className="fa fa-envelope pr-1"></i>
                            {translate('Send self check in email again')}
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}

export default ReservationNotifications;
