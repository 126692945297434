import React from 'react';
import { closeModal } from '@components/modal';
import translate from '@data/translations';

interface DeleteProps {
    actionForYes?: Function | null;
    actionForNo?: Function;
    buttons?: any;
    content?: any;
}

function Delete(props: DeleteProps) {
    const yesHandler = async() => {
        if (props.actionForYes) {
            await props.actionForYes();
            closeModal();
        }
    };

    const noHandler = async() => {
        if (props.actionForNo) {
            await props.actionForNo();
        }
        closeModal();
    };

    let buttons = [];
    if (props.buttons) {
        buttons = props.buttons;
    } else {
        buttons = [
            <div key={1} onClick={() => yesHandler()} className="button-primary mr-4p">
                {translate('Yes')}
            </div>,
            <div key={2} onClick={() => noHandler()} className="button-light ml-4p">
                {translate('No')}
            </div>,
        ];
    }

    return (
        <div>
            <div className="text-center ">
                {props.content ? translate(props.content) : <div>{translate('Are you sure you want to delete?')}</div>}
                <div className="flex-center-center my-standard mt-16p">{buttons}</div>
            </div>
        </div>
    );
}

export default Delete

//required props
//content nije required
//showOnlyCloseButton nije required
