import * as React from 'react';
import { ReservationController, ValidationParams } from '../resController';
import { translate } from '@data/translations';
import { InvalidIcon } from '../tabsWrapper';

interface ReservationValidationMessageListProps {
    children: any;
    validationParams: ValidationParams;
}

function ReservationValidationMessageList(props: ReservationValidationMessageListProps) {
    const { children, validationParams } = props;
    const ctrl = new ReservationController();
    const fullValidationReport = ctrl.getFullValidationReport(validationParams);

    const { isFullyValid, validationInfo } = fullValidationReport;
    
    if (isFullyValid) {
        return <React.Fragment>{children}</React.Fragment>;
    } else if (validationInfo) {
        const { messages } = validationInfo;

        if (messages.length > 0) {
            return (
                <div className="">
                    {messages.map((m, index) => {
                        return (
                            <div className="custom-list-item-text-right" style={{ margin: 0 }} key={index}>
                                <div className="display-flex align-items-center">
                                    <div className="fill-red">
                                        {' '}
                                        <InvalidIcon />
                                    </div>{' '}
                                    &ensp; {m}
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return <div className="text-center">{translate('Action not available due to validation errors')}</div>;
        }
    } else {
        return <div className="text-center">{translate('Action not available due to validation errors')}</div>;
    }
}

export default ReservationValidationMessageList;
