import React, { Component } from 'react';
import Toaster from '../components/toaster';
import ModalController from '../components/modal';
import BackendDrivenToaster from './backendDrivenToaster';
import { GlobalLoading } from '../components/loaders/globalLoading';
import SideMenu from '../components/sideMenu';
import SideMenuMobile from '../components/sideMenu/sideMenuMobile';
import rest from '../data/restWrapper';


class MasterLayout extends Component {
    constructor() {
        super();
        this.myRef = React.createRef();
        this.state = {
            otherSettings: {},
            disableSidebarMenu: false,
            mobileView: false,
        };
    }
    getWidth() {
        return Math.max(
            document.body.scrollWidth,
            document.documentElement.scrollWidth,
            document.body.offsetWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname && this.myRef.current) {
            this.myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
        if (prevProps.fixedRoute !== this.props.fixedRoute) {
            this.redrawComponent();
        }
        this.hideWidget();
    }

    async redrawComponent() {
        try {
            if (this.props.fixedRoute && this.props.fixedRoute !== '') {
                this.setState({ disableSidebarMenu: true });
            }
        } catch (error) {
            console.log(error);
        }
    }

    async componentDidMount() {
        try {
            if (this.getWidth() <= 600) {
                this.setState({ mobileView: true });
            }

            if (process.env.NODE_ENV === 'development') {
                window.addEventListener('error', (e) => {
                    if (e.message === 'ResizeObserver loop limit exceeded') {
                        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
                        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
                        if (resizeObserverErr) {
                            resizeObserverErr.setAttribute('style', 'display: none');
                        }
                        if (resizeObserverErrDiv) {
                            resizeObserverErrDiv.setAttribute('style', 'display: none');
                        }
                    }
                });
            }

            const data = await rest('/getGeneralSettingByKey/otherSettings');
            if (data?.params) {
                this.setState({ otherSettings: JSON.parse(data.params) });
            }
            if (this.props.fixedRoute && this.props.fixedRoute !== '') {
                this.setState({ disableSidebarMenu: true });
            }
        } catch (error) {
            console.log(error);
        }
    }


    hideWidget() {
        if (window.zE && this.props.location.pathname.lastIndexOf('/help') === -1) {
            window.zE('webWidget', 'close');
            window.zE('webWidget', 'hide');
        }
    }

    render() {
        if (
            this.props.location.pathname.lastIndexOf('/invoiceView') !== -1 ||
            this.props.location.pathname.lastIndexOf('/proformaView') !== -1 ||
            this.props.location.pathname.lastIndexOf('/printPreview') !== -1 ||
            this.props.location.pathname.lastIndexOf('/cashRegReportView') !== -1 ||
            this.props.location.search.lastIndexOf('printJournal') !== -1 ||
            this.props.location.pathname.lastIndexOf('/billingSummaryInvoiceView') !== -1
        ) {
            return (
                <div>
                    {' '}
                    <ModalController />
                    <GlobalLoading />
                    {this.props.children}{' '}
                    <div className="d-print-none">
                        <Toaster />
                    </div>
                    <BackendDrivenToaster />
                </div>
            );
        } else if (this.props.location.pathname.startsWith('/documentScanner')) {
            const bgCl = ' docScan-bg ';
            return (
                <div>
                    <div className={bgCl + ' docScanner-background'}>{this.props.children}</div>
                    <div className="d-print-none">
                        <Toaster />
                    </div>
                    <BackendDrivenToaster />
                </div>
            );
        } else {
            return (
                <div
                    ref={this.myRef}
                    id={
                        this.state.mobileView ? 'master-layout-mobile' : 'master-layout'
                    } /* style={{ userSelect: 'none' }} */
                >
                    <GlobalLoading />
                    {/* <Navbar {...this.props} showSideMenu={this.state?.otherSettings?.showSideMenu} /> */}
                    <ModalController />
                    <div className={'reception-background  w-100'}>
                        <div className="d-flex h-100 app-flex-div">
                            {/* {showSideMenu ? <SideMenu {...this.props} /> : null} */}

                            <SideMenu {...this.props} />
                            <div className="scrollX" id="main-app-div" style={{ width: '100%' }}>
                                {!this.props.authTokenInfo?.user?.role ? (
                                    <div>Insufficient permissions</div>
                                ) : (
                                    this.props.children
                                )}
                            </div>

                            <div className="master-layout-clear clear pt-2">&ensp;</div>
                        </div>
                    </div>

                    <div className="d-print-none">
                        <Toaster />
                    </div>
                    {/* <Foother {...this.props} /> */}
                    <BackendDrivenToaster />

                    <SideMenuMobile {...this.props}></SideMenuMobile>
                </div>
            );
        }
    }
}

export default MasterLayout;
