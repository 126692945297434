import React from 'react';
import { Reservation } from './resController';
import translate from '@data/translations';
import { ReservationStatus } from '@common/modelDefinition';
import { condoStatusCheck } from './utils';
import { BasicSRCSettingsButton } from './reservations/ActionsToolbar';

interface CondoOwnerProps {
    history: any;
    reservation: Reservation;
    children: any;
}

function CondoOwnerRestrictionWrapper(props: CondoOwnerProps) {
    const { history, reservation, children } = props;
    const { isReservationMadeByOwner, isReservationMadeForOwner } = condoStatusCheck(reservation);

    if (isReservationMadeForOwner) {
        //ovdje ponudi opciju manualnog checkina od recpecionara
        return <div>{'isReservationMadeForOwner to do!!!!'}</div>;
    }
    const minibarConsumption = reservation.MinibarLogs
        ? reservation.MinibarLogs.filter((ml: any) => !ml.InvoiceItem)
        : [];
    if (isReservationMadeByOwner && minibarConsumption.length === 0) {
        return (
            <div className="text-center  justify-content-center">
                <h3 className="text-center mt-5">{translate('Reservation managed by aparment owner!')}</h3>
                <p>
                    {translate('Reservation status')}: {translate(ReservationStatus[reservation.statusEnum])}
                </p>

                {reservation.statusEnum === ReservationStatus.checkedIn && (
                    <div>
                        <BasicSRCSettingsButton reservation={reservation} room={reservation.RoomInfo} />
                        {!reservation.guest1CodeCopy ? (
                            <div className="text-danger">
                                {translate('Room is NOT encoded with condo owner card code!')}
                            </div>
                        ) : null}
                    </div>
                )}

                <br />
                <div className="display-flex justify-content-center">
                    <button
                        className="button-primary"
                        onClick={() => {
                            history.push(`/checkoutAndInvoice/${reservation.id}`);
                        }}
                    >
                        {translate('CHECKOUT')}
                    </button>
                </div>
            </div>
        );
    } else {
        return <React.Fragment>{children}</React.Fragment>;
    }
}

//registered props are required to trigger component update when monkeys are updated
export default CondoOwnerRestrictionWrapper;
