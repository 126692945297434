import React, { useState, useEffect, useRef } from 'react';
import rest from '@data/restWrapper';
import Image from '@components/image';
import Select from 'react-select/creatable';
import { closeModal } from '@components/modal';
import { toast } from 'react-toastify';
import Tooltip from '@components/tooltip/tooltip';
import PermissionCheck from '@components/PermissionCheck';
import translate from '@data/translations';
import { createOrUpdatePosItemAndSkuPluRate, getSkuPluRate, searchSkuItem } from '@pages/goodsManagement/api';
import { unitOfMeasure } from '@pages/goodsManagement/skuItems/form';
import CustomInput from '@components/custom/input';
import { ReactComponent as Search } from '../../../../assets/custom/icons/search.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/custom/icons/trash.svg';
import { PosItemModel, SkuPluRateForForm, StockKeepingUnitModel, TaxModel } from '@common/modelDefinition';
//@ts-ignore
import _ from 'lodash';
import FeatureFlag from '@components/FeatureFlag';
import { getPosItems } from '@pages/posTerminal/posView/terminalViewActions';
import useStore from '@data/state/zustand';
import { markInvalidInputRefs } from '@components/validationRefs';

const types = {
    posTerminal: 'Goods & services',
    accommodation: 'Accommodation',
    tax: 'Accommodation tax',
};

export enum PosItemSubTypes {
    null = 'Other',
    food = 'Food',
    beverage = 'Beverage',
}

export enum PosItemTypes {
    posTerminal = 'Goods & services',
    accommodation = 'Accommodation',
    tax = 'Accommodation tax',
    minibar = 'Minibar',
}

const subtypes = {
    null: 'Other',
    food: 'Food',
    beverage: 'Beverage',
};

var emptyFormTemplate = {
    id: null,
    barcode: null, //add support for barcodes later on if neccecary
    plu: null,
    type: 'posTerminal',
    subtype: null,
    name: '',
    description: '',
    category: '',
    subcategory: null,
    image: null,
    thumbnail: null,
    props: '{}',
    enabled: true,
    tags: '', //add this later on
    unitMeasure: 'piece',
    composites: null,
    price: 0,
    flexiblePrice: false,
    taxId: null,
    tax2Id: null,
    tax3Id: null,
    isComboItem: false,
    relatedArticlesIds: [],
    suplements: '',
    preparationRequired: false,
    specialOffer: false,
    specialOfferValidFrom: null,
    specialOfferValidTo: null,
    // showCounterOnInvoice: false,
};

const skuPluRateTemplate = {
    id: undefined,
    skuId: null,
    skuMeasureCode: '',
    pluMeasureCode: '',
    multiplier: 1,
    posItemId: null,
    description: null,
    StockKeepingUnit: null,
    code: '',
    edit: false,
    delete: false,
};

export interface PosItemWithNormative extends PosItemModel {
    normative?: boolean;
    relatedArticlesIds?: number[];
    suplements?: string | null;
}

export const renderFirstAvailablePlu = async (): Promise<number> => {
    return await rest('/getFirstAvailablePosItemPlu', 'GET');
};

const getPosItemTags = (_posItems: PosItemModel[]) => {
    const arrayOfTags: string[] = [];
    _posItems.forEach((p) => {
        if (p.category) {
            arrayOfTags.push(p.category);
        }
    });

    const posItemsTags: string[] = [];
    arrayOfTags.forEach((opt) => {
        opt.split(';').forEach((o) => {
            if (!posItemsTags.includes(o)) {
                posItemsTags.push(o);
            }
        });
    });
    return posItemsTags;
};

const getPosItemSuplements = (_posItems: PosItemModel[]) => {
    const arrayOfSuplements: string[] = [];
    _posItems.forEach((p) => {
        if (p.suplements) {
            arrayOfSuplements.push(p.suplements);
        }
    });
    const posItemsSuplementsTags: string[] = [];
    arrayOfSuplements.forEach((opt) => {
        opt.split(';').forEach((o) => {
            if (!posItemsSuplementsTags.includes(o)) {
                posItemsSuplementsTags.push(o);
            }
        });
    });
    return posItemsSuplementsTags;
};

interface OptionsAndValue {
    label: any;
    value: string | null;
}

interface PosItemFormProps {
    getPageData: Function;
    posItemId: number | null | undefined;
    history?: any;
    callback?: Function;
    closeFunc?: Function;
}

function PosItemForm(props: PosItemFormProps) {
    const [data, setData] = useState(_.clone(emptyFormTemplate));
    const [tagsValues, setTagsValues] = useState([] as OptionsAndValue[]);
    const [tagsOptions, setTagsOptions] = useState([] as OptionsAndValue[]);
    const [subcategory, setSubcategory] = useState(null as OptionsAndValue | null);
    const [message, setMessage] = useState(null as null | string);
    const [skuPluRates, setSkuPluRates] = useState([] as SkuPluRateForForm[]);
    const [searchTermNormative, setSearchTerm] = useState('');
    const [skuItemsFromSearchBox, setSkuItemsFromSearchBox] = useState([] as StockKeepingUnitModel[]);
    const [itemsFromSearchBox, setItemsFromSearchBox] = useState([] as PosItemWithNormative[]);
    const [searchTermRelatedArticle, setSearchTermRelatedArticle] = useState('');
    const [relatedArticlesPosItems, setRelatedArticlesPosItems] = useState([] as PosItemModel[]);
    const [suplements, setSuplements] = useState([] as OptionsAndValue[]);
    const [suplementsTagsOptions, setSuplementsTagsOptions] = useState([] as OptionsAndValue[]);
    const [posItems, setPosItems] = useState([] as PosItemModel[]);

    const taxes: TaxModel[] = useStore((appState: any) => appState.model.Tax);
    const invoicingSettings = useStore((appState: any) => appState.invoicingSettings);
    const fiscalGeneralSettings = useStore((appState: any) => appState.fiscal.fiscalGeneralSettings);
    const defaultCurrency: string = useStore((appState: any) => appState.defaultCurrency);

    const name = useRef(null);
    const plu = useRef(null);
    const tax = useRef(null);
    let searchDebouncer: any = null;

    const { getPageData } = props;
    const country = fiscalGeneralSettings && fiscalGeneralSettings?.country ? fiscalGeneralSettings.country : '';
    const { generalTax } = invoicingSettings ? invoicingSettings : { generalTax: null };

    useEffect(() => {
        const loadData = async () => {
            const { data: _posItems } = await getPosItems();
            setPosItems([..._posItems]);
            let _data = { ...data };
            const { posItemId } = props;
            if (posItemId) {
                //editing
                loadFormData(posItemId, _posItems);
            } else {
                const _plu = await renderFirstAvailablePlu();
                _data.plu = _plu;
                _data.taxId = generalTax ? generalTax : null;
                setData(_data);
                checkValidationInputs(_data);
            }

            const _tagsOptions: OptionsAndValue[] = [];
            const PosItemTags = getPosItemTags(_posItems);
            PosItemTags.forEach((t) => {
                const obj: OptionsAndValue = {
                    value: t,
                    label: t,
                };
                _tagsOptions.push(obj);
            });
            setTagsOptions(_tagsOptions);

            const _suplementsOptions: OptionsAndValue[] = [];
            const PosItemSuplements = getPosItemSuplements(_posItems);
            PosItemSuplements.forEach((t) => {
                const obj: OptionsAndValue = {
                    value: t,
                    label: t,
                };
                _suplementsOptions.push(obj);
            });
            setSuplementsTagsOptions(_suplementsOptions);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [props.posItemId]);

    const loadFormData = async (serviceId: number | null | undefined, _posItems = posItems) => {
        if (!_.isUndefined(serviceId) && serviceId !== null && _.isNaN(serviceId) === false) {
            const currentData: PosItemModel | undefined = _posItems?.find((t) => t.id === Number(serviceId));
            const tags = currentData?.category ? currentData.category.split(';') : [];
            const suplements = currentData?.suplements ? currentData.suplements.split(';') : [];
            const _tagsValue: OptionsAndValue[] = [];
            tags.forEach((t) => {
                const obj: OptionsAndValue = {
                    value: t,
                    label: t,
                };
                _tagsValue.push(obj);
            });
            setTagsValues(_tagsValue);
            if (currentData?.subcategory) {
                const obj: OptionsAndValue = {
                    value: currentData?.subcategory,
                    label: currentData?.subcategory,
                };
                setSubcategory(obj);
            }

            const _suplementsValue: OptionsAndValue[] = [];
            suplements?.forEach((t: any) => {
                const obj: OptionsAndValue = {
                    value: t,
                    label: t,
                };
                _suplementsValue.push(obj);
            });
            setSuplements(_suplementsValue);

            const _skuPluRates = await getSkuPluRate(null, Number(serviceId));
            let totalNormativePrice = 0;
            //@ts-ignore
            const preparedSkuPluRates: any[] = _skuPluRates
                ?.map((r) => {
                    if (r) {
                        const normativeRetailPrice = Number(r.multiplier) * Number(r?.StockKeepingUnit?.salesPrice);
                        const withCode: SkuPluRateForForm = {
                            ...r,
                            code: r.StockKeepingUnit?.code,
                            normativeRetailPrice: normativeRetailPrice,
                            edit: false,
                        };
                        totalNormativePrice = Number(totalNormativePrice) + Number(normativeRetailPrice);
                        return withCode;
                    } else {
                        return null;
                    }
                })
                .filter((r: any | null) => r !== null);
            if (currentData) {
                let _data: PosItemModel = { ...currentData };
                _data.normativeRetailPriceTotal = Number(totalNormativePrice);
                _data.additionalMargin = Number(Number(_data.price) - Number(totalNormativePrice));

                if (currentData?.type === 'posTerminal' && currentData?.subtype === null) {
                    _data.subtype = null;
                }
                setData(_data);
                checkValidationInputs(_data);
            } else {
                setData(currentData);
                checkValidationInputs(currentData);
            }

            setSkuPluRates([...preparedSkuPluRates]);
        }
    };

    useEffect(() => {
        const loadData = async () => {
            let _data = { ...data };
            const relatedArticlesPosItems: PosItemModel[] = [];

            if (_data?.relatedArticlesIds?.length > 0) {
                if (typeof _data?.relatedArticlesIds === 'string') {
                    _data.relatedArticlesIds = JSON.parse(_data?.relatedArticlesIds);
                }
                for (const item of _data?.relatedArticlesIds) {
                    if (Number(item)) {
                        let posItem = await rest('/PosItem/' + item, 'GET');
                        relatedArticlesPosItems.push(posItem);
                    }
                }
            }
            setRelatedArticlesPosItems(relatedArticlesPosItems);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [data, itemsFromSearchBox?.length]);

    const handleInputChange = (e: any) => {
        let _data = { ...data };
        if (e.target.type === 'text') {
            _data[e.target.name] = e.target.value;
            setData(_data);
        } else if (e.target.type === 'number') {
            _data[e.target.name] = parseFloat(e.target.value);
            setData(_data);
        }
        if (e.target.name === 'plu') {
            setData(_data);
        }
        if (e.target.name === 'name') {
            checkValidationInputs(_data);
        }
        if (e.target.name === 'price') {
            const result = recalculateRetailPriceAndMargin(_data);
            setData({ ...result.recalcData });
            //@ts-ignore
            setSkuPluRates([...result.recalcSkuPluRate]);
        }
    };

    const setRetailPriceBasedOnCummulativeNormativePrice = (cumNorm: number) => {
        let _data = { ...data };

        _data['price'] = cumNorm;
        const result = recalculateRetailPriceAndMargin(_data);
        setData({ ...result.recalcData });
        //@ts-ignore
        setSkuPluRates([...result.recalcSkuPluRate]);
    };

    const recalculateRetailPriceAndMargin = (_data: PosItemModel, _skuPluRates = skuPluRates) => {
        let totalNormativePrice = 0;

        const editedSkuPluRate = _skuPluRates
            ?.map((r) => {
                if (r) {
                    const unitRetailPrice = Number(r.multiplier) * Number(r?.StockKeepingUnit?.salesPrice);
                    let _editedItem = { ...r };
                    _editedItem.normativeRetailPrice = unitRetailPrice;
                    if (!r?.delete) {
                        totalNormativePrice = Number(totalNormativePrice) + unitRetailPrice;
                    }
                    return _editedItem;
                } else {
                    return null;
                }
            })
            ?.filter((r) => r !== null);
        let currentData: PosItemModel = { ..._data };
        currentData.normativeRetailPriceTotal = Number(totalNormativePrice);
        currentData.additionalMargin = Number(Number(currentData.price) - Number(totalNormativePrice));
        return { recalcData: currentData, recalcSkuPluRate: editedSkuPluRate };
    };

    const handleSelectChange = (e: any) => {
        let _data = { ...data };
        _data[e.target.name] = e.target.value;
        if (e.target.name === 'taxId' || e.target.name === 'tax2Id' || e.target.name === 'tax3Id') {
            if (isNaN(parseFloat(e.target.value))) {
                _data[e.target.name] = null;
                checkValidationInputs(_data);
            }
            checkValidationInputs(_data);
        }
        if (_data['type'] === 'posTerminal' && _data['subtype'] === null) {
            _data['subtype'] = null;
        }
        if (_data['type'] !== 'posTerminal') {
            _data['subtype'] = null;
        }
        setData(_data);
    };

    const handleIntegerInputChange = (e: any) => {
        if (e.target.type === 'number') {
            let _data = { ...data };
            _data[e.target.name] = parseInt(e.target.value, 10);
            setData(_data);
            if (e.target.name === 'plu') {
                checkValidationInputs(_data);
            }
        }
    };

    const handleCategoryChange = (tags: any) => {
        const category: string[] = [];
        let categoryToSave: string | null;
        if (tags !== null) {
            tags.forEach((t: OptionsAndValue) => {
                if (t.value) {
                    category.push(t.value);
                }
            });
            categoryToSave = category.join(';');
        } else {
            categoryToSave = null;
        }
        let _data = { ...data };
        _data['category'] = categoryToSave;
        setTagsValues(tags);
        setData(_data);
    };

    const handleSuplemets = (suplements: any) => {
        const _suplements: string[] = [];
        let suplementsToSave: string | null;
        if (suplements !== null) {
            suplements.forEach((t: OptionsAndValue) => {
                if (t.value) {
                    _suplements.push(t.value);
                }
            });
            suplementsToSave = _suplements.join(';');
        } else {
            suplementsToSave = null;
        }
        let _data = { ...data };
        _data['suplements'] = suplementsToSave;
        setSuplements(suplements);
        setData(_data);
    };

    const handleSubCategoryChange = (tags: any | null) => {
        let _subcategory = null;
        if (tags !== null) {
            _subcategory = tags[0].value;
        } else {
            _subcategory = null;
        }
        const subCat = _subcategory ? _subcategory.split(':') : null;
        let _data = { ...data };
        if (subCat) {
            if (Number(subCat[0]) && subCat[1]) {
                const obj: OptionsAndValue = {
                    value: _subcategory,
                    label: _subcategory,
                };
                setSubcategory(obj);
                _data['subcategory'] = _subcategory;
            } else {
                setMessage('Format of subcategory must be number:tag');
            }
        } else {
            setSubcategory(null);
            _data['subcategory'] = null;
        }
        setData(_data);
    };

    const handleIsActiveChange = () => {
        let _data = { ...data };
        _data['enabled'] = !_data.enabled;
        setData(_data);
    };

    const handleIsComboItem = () => {
        const nonDeletedSkuPluRates = skuPluRates?.filter((r) => !r.delete);
        let _data = { ...data };
        _data['isComboItem'] = !_data.isComboItem;
        if ((!_data.isComboItem || _data.isComboItem === false) && nonDeletedSkuPluRates?.length === 1) {
            _data.price = nonDeletedSkuPluRates[0]?.StockKeepingUnit?.salesPrice;
            _data.flexiblePrice = false;
            let _skuPluRates = [...skuPluRates]
                .map((r) => {
                    if (r.posItemId === _data.id) {
                        let editSkuPluRate = { ...r };
                        editSkuPluRate.multiplier = 1;
                        editSkuPluRate.edit = true;
                        return { ...editSkuPluRate };
                    } else {
                        return null;
                    }
                })
                ?.filter((r) => r !== null);
            //@ts-ignore
            setSkuPluRates([..._skuPluRates]);
        } else if ((!_data.isComboItem || _data.isComboItem === false) && skuPluRates?.length > 1) {
            let _skuPluRates = skuPluRates.map((r) => {
                let _deletedRates = { ...r };
                _deletedRates.multiplier = 1;
                _deletedRates.delete = true;
                return { ..._deletedRates };
            });
            setSkuPluRates([..._skuPluRates]);
        }
        setData(_data);
    };

    const handleFileChange = (e: any) => {
        let _data = { ...data };
        var selectedFile = e.target.files;
        if (selectedFile.length > 0) {
            var imageFile = selectedFile[0];
            var fileReader = new FileReader();

            fileReader.onload = (e: any) => {
                var dataurl: any = e.target.result;
                var img: any = document.createElement('img');
                img.src = dataurl;
                img.onload = () => {
                    var canvas = document.createElement('canvas');
                    var MAX_WIDTH = 100;
                    var MAX_HEIGHT = 100;
                    var width = img.width;
                    var height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;

                    var ctx: any = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    var url = canvas.toDataURL('image/png');

                    _data.image = url;
                    setData(_data);
                };
            };
            fileReader.readAsDataURL(imageFile);
        }
    };

    const checkValidationInputs = (objToValidate: any) => {
        let validationResults = getValidationInputs(objToValidate);
        markInvalidInputRefs(validationResults);
    }

    const getValidationInputs = (_data: PosItemModel) => {
        const invalidInputs = [],
            validInputs = [];
        if (_data.name === null || _data.name.trim() === '') {
            invalidInputs.push(name);
        } else {
            validInputs.push(name);
        }
        if (_data.plu === null || _.isNaN(_data.plu)) {
            invalidInputs.push(plu);
        } else {
            validInputs.push(plu);
        }
        if (_data.type !== 'tax' && (!_data.taxId || _data.taxId === null)) {
            invalidInputs.push(tax);
        } else {
            validInputs.push(tax);
        }
        return { invalidInputs, validInputs };
    };

    const handleRateChange = (
        skuItem: StockKeepingUnitModel | null,
        action: string,
        value?: number | string | null,
        skuPluRateIndex?: number
    ) => {
        let _skuPluRates: any[] = [...skuPluRates];
        let _newSkuPluRate: any = { ...skuPluRateTemplate };
        let _data = { ...data };
        if (skuItem?.id && action === 'add') {
            if (skuItem.id && !_skuPluRates.includes(skuItem.id)) {
                _newSkuPluRate.skuId = Number(skuItem.id);
                _newSkuPluRate.posItemId = props.posItemId ? Number(props.posItemId) : null;
                _newSkuPluRate.skuMeasureCode = skuItem.stockMeasureUnit;
                _newSkuPluRate.pluMeasureCode = skuItem.stockMeasureUnit;
                _newSkuPluRate.StockKeepingUnit = skuItem;
                _newSkuPluRate.code = skuItem.code;
                _newSkuPluRate.edit = true;
                _skuPluRates.push(_newSkuPluRate);

                if ((!_data.isComboItem || _data.isComboItem === false) && _skuPluRates?.length === 1) {
                    _data.price = _skuPluRates[0]?.StockKeepingUnit?.salesPrice;
                    _data.flexiblePrice = false;
                } else if ((!_data.isComboItem || _data.isComboItem === false) && _skuPluRates?.length > 1) {
                    _data.isComboItem = true;
                }
                setData({ ..._data });
            }
        } else if ((skuPluRateIndex || skuPluRateIndex === 0) && action === 'multiplier') {
            let _editSkuPluRate = _skuPluRates?.find((r, index) => index === skuPluRateIndex);
            if (_editSkuPluRate) {
                if (_editSkuPluRate?.StockKeepingUnit?.isCounter) {
                    _editSkuPluRate.multiplier = 1;
                } else {
                    if (Number(value) && Number(value) > 0) {
                        _editSkuPluRate.multiplier = Number(value);
                    } else {
                        _editSkuPluRate.multiplier = 0;
                    }
                }
                _editSkuPluRate.edit = true;
            }

            _skuPluRates = _skuPluRates?.map((r, index) => {
                if (index === skuPluRateIndex) {
                    return { ..._editSkuPluRate };
                } else {
                    return { ...r };
                }
            });
        } else if ((skuPluRateIndex || skuPluRateIndex === 0) && action === 'delete') {
            let _editSkuPluRate = _skuPluRates?.find((r, index) => index === skuPluRateIndex);
            if (_editSkuPluRate) {
                _editSkuPluRate.delete = true;
            }
            _skuPluRates = _skuPluRates?.map((r, index) => {
                if (index === skuPluRateIndex) {
                    return { ..._editSkuPluRate };
                } else {
                    return { ...r };
                }
            });
        }

        const result = recalculateRetailPriceAndMargin(_data, _skuPluRates);
        setData({ ...result.recalcData });
        //@ts-ignore
        setSkuPluRates([...result.recalcSkuPluRate]);
        setSkuItemsFromSearchBox([]);
        setSearchTerm('');
    };

    const onSearchSkuItem = async (e: any) => {
        clearTimeout(searchDebouncer);
        const _searchTerm = e.target.value;
        setSearchTerm(_searchTerm);
        searchDebouncer = setTimeout(async () => {
            const _itemsFromSearchBox = await searchSkuItem(_searchTerm);
            const filteredItems = _itemsFromSearchBox?.filter((i) => !i.parentId);
            setSkuItemsFromSearchBox(filteredItems);
        }, 400);
    };

    const saveFormData = async (dataToSave = data, skuPluRatesToSave = skuPluRates) => {
        try {
            let service = { ...dataToSave };
            //  service.params = JSON.stringify(service.params);
            service.plu = service.plu === '' ? null : service.plu;

            let pluRepeats = { count: 0 }; //for null
            if (service.plu !== null) {
                pluRepeats = await rest('/checkIfPosItemPluIsUnique/' + service.id, 'POST', { plu: service.plu });
            } else {
                service.plu = await renderFirstAvailablePlu();
            }
            if (service.price === '') {
                service.price = 0;
                setData(service);
            }

            if (pluRepeats.count > 0) {
                throw new Error('PLU code already exists!');
            } else {
                let validationResults = getValidationInputs(service);
                markInvalidInputRefs(validationResults);
                if (validationResults.invalidInputs.length === 0) {
                    await createOrUpdatePosItemAndSkuPluRate(service, skuPluRatesToSave);
                    if (!service.id) {
                        toast(translate('PosItem added!'), {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            type: toast.TYPE.SUCCESS,
                        });
                    }
                    if (props.callback) {
                        props.callback();
                    } else {
                        if (props.history) {
                            props.history.push('/priceLists/posItem');
                        }
                    }
                    setData({ ...emptyFormTemplate });
                    await getPageData();
                    closeModal();
                }
            }
        } catch (err: any) {
            toast(err.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.ERROR,
            });
        }
    };

    const copyItemData = async () => {
        let _newCopiedData = { ...data };
        _newCopiedData.id = null;
        _newCopiedData.plu = await renderFirstAvailablePlu();
        const _skuPluRates = skuPluRates?.map((r) => {
            let _changedRate = { ...r };
            _changedRate.posItemId = _newCopiedData.id;
            _changedRate.PosItem = { ..._newCopiedData };
            //@ts-ignore
            delete _changedRate.id;
            _changedRate.edit = true;
            return _changedRate;
        });
        await saveFormData(_newCopiedData, _skuPluRates);
    };

    const onSearch = async (e: any, type: string) => {
        const path = '/searchService?search=' + e.target.value; //searchPosItem
        const _searchTerm = e.target.value;
        const _itemsFromSearchBox = await rest(path);
        // const _posItems = await _getSkuPluRate(_itemsFromSearchBox);
        if (type === 'relatedArticles') {
            setItemsFromSearchBox(_itemsFromSearchBox);
            setSearchTermRelatedArticle(_searchTerm);
        } else {
            // setSuplemetsItemsFromSearchBox(_itemsFromSearchBox);
            // setSearchTermSuplements(_searchTerm);
        }
    };

    const addRelatedArticle = (posItem: PosItemModel) => {
        let newData = { ...data };

        if (newData?.relatedArticlesIds?.length > 0) {
            if (typeof newData?.relatedArticlesIds === 'string') {
                newData.relatedArticlesIds = JSON.parse(newData?.relatedArticlesIds);
            }
        }
        const alreadyIncludedInSkuPlurates = skuPluRates.find((skuItem) => skuItem.posItemId === posItem.id);
        let relatedArticlesIds = newData?.relatedArticlesIds ? [...newData.relatedArticlesIds] : [];
        if (alreadyIncludedInSkuPlurates) {
            toast(translate('Već postoji normativ s tim artiklom.'), {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.ERROR,
            });
        } else {
            if (relatedArticlesIds.includes(posItem.id)) {
                toast(translate('Već postoji artikal.'), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.ERROR,
                });
            } else {
                relatedArticlesIds.push(posItem.id);
            }
            newData.relatedArticlesIds = relatedArticlesIds;
            setData(newData);
            setItemsFromSearchBox([]);
            setSearchTermRelatedArticle('');
        }
    };

    const removeRelatedArticle = (posItem: PosItemModel) => {
        let newData = { ...data };

        if (newData?.relatedArticlesIds?.length > 0) {
            if (typeof newData?.relatedArticlesIds === 'string') {
                newData.relatedArticlesIds = JSON.parse(newData?.relatedArticlesIds);
            }
        }
        let relatedArticlesIds = newData?.relatedArticlesIds ? [...newData.relatedArticlesIds] : [];

        if (relatedArticlesIds.includes(posItem.id)) {
            relatedArticlesIds = relatedArticlesIds.filter((s) => s !== posItem.id);
        }
        newData.relatedArticlesIds = relatedArticlesIds;
        setData(newData);
        setItemsFromSearchBox([]);
        setSearchTermRelatedArticle('');
    };

    const subCatOpt: string[] = [];
    posItems &&
        posItems.forEach((i) => {
            if (i.subcategory) {
                if (!subCatOpt.includes(i.subcategory)) {
                    subCatOpt.push(i.subcategory);
                }
            }
        });
    const subcategoryOptions = subCatOpt.map((o) => {
        const obj = {
            value: o,
            label: o,
        };
        return obj;
    });

    const isEnabled = data?.enabled ? (
        <i className="fa fa-lg mr-8p fa-check-square-o pointer" onClick={handleIsActiveChange} />
    ) : (
        <i className="fa fa-lg mr-8p fa-square-o pointer" onClick={handleIsActiveChange} />
    );

    const isComboItem = data?.isComboItem ? (
        <i className="fa fa-lg mr-8p fa-check-square-o pointer" onClick={handleIsComboItem} />
    ) : (
        <i className="fa fa-lg mr-8p fa-square-o pointer" onClick={handleIsComboItem} />
    );

    const flexiblePrice = data?.flexiblePrice ? 'fa fa-check-square-o fa-lg pointer' : 'fa fa-square-o fa-lg pointer';
    const preparationRequired = data?.preparationRequired
        ? 'fa fa-lg mr-8p fa-check-square-o pointer'
        : 'fa fa-lg mr-8p fa-square-o pointer';

    const separateItem = data?.separateItem
        ? 'fa fa-lg mr-8p fa-check-square-o pointer'
        : 'fa fa-lg mr-8p fa-square-o pointer';

    const nonDeletedSkuPluRates = skuPluRates?.filter((r) => !r.delete);

    // const showCounterOnInvoice = data?.showCounterOnInvoice ? (
    //     <i className="fa fa-lg mr-8p fa-check-square-o pointer" onClick={handleShowCounterOnInvoice} />
    // ) : (
    //     <i className="fa fa-lg mr-8p fa-square-o pointer" onClick={handleShowCounterOnInvoice} />
    // );

    return (
        <div className="">
            <div className="display-flex">
                <div className="flex mr-8p">
                    <div className="display-flex mt-12p">
                        <div className="mr-4p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Name')}: <span className="color-secondary">({translate('required')})</span>
                            </label>
                            <input
                                ref={name}
                                autoComplete="off"
                                name="name"
                                placeholder={translate('Name')}
                                type="text"
                                className="input input-modal"
                                value={data.name ? data.name : ''}
                                onChange={(e) => handleInputChange(e)}
                            />
                        </div>

                        <div
                            className={
                                data?.type && data?.type === 'posTerminal' ? 'ml-4p display-flex flex' : 'ml-4p flex'
                            }
                        >
                            <div className="flex">
                                <label className="text-bold ml-4p" htmlFor="">
                                    {translate('Type')}
                                </label>
                                <select
                                    name="type"
                                    autoComplete="off"
                                    className="input input-modal"
                                    value={data.type ? data.type : ''}
                                    onChange={(e) => handleSelectChange(e)}
                                >
                                    {Object.keys(types).map((key) => (
                                        <option key={key} value={key}>
                                            {/* @ts-ignore */}
                                            {translate(types[key])}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {data?.type && data?.type === 'posTerminal' ? (
                                <div className="ml-4p flex">
                                    <label className="text-bold ml-4p" htmlFor="">
                                        {translate('Subtype')}
                                    </label>
                                    <select
                                        name="subtype"
                                        autoComplete="off"
                                        className="input input-modal"
                                        value={data.subtype ? data.subtype : ''}
                                        onChange={(e) => handleSelectChange(e)}
                                    >
                                        {Object.keys(subtypes).map((key) => (
                                            <option key={key} value={key}>
                                                {/* @ts-ignore */}
                                                {translate(PosItemSubTypes[key])}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div className="display-flex mt-12p">
                        <div className="mr-4p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Description')}:
                            </label>
                            <input
                                autoComplete="off"
                                name="description"
                                placeholder={translate('Description')}
                                type="text"
                                className="input input-modal"
                                value={data.description ? data.description : ''}
                                onChange={(e) => handleInputChange(e)}
                            />
                        </div>

                        <div className="ml-4p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('PLU')}:
                            </label>
                            <input
                                ref={plu}
                                autoComplete="off"
                                name="plu"
                                placeholder={translate('Next available PLU: ') + data?.plu}
                                type="number"
                                className="input input-modal"
                                value={data.plu ? data.plu : ''}
                                onChange={(e) => handleIntegerInputChange(e)}
                            />
                        </div>

                        <div className="flex ml-8p">
                            <div className="mr-4p flex">
                                <label className="text-bold ml-4p" htmlFor="">
                                    {translate('Unit of measure')}:
                                </label>
                                <select
                                    name="unitMeasure"
                                    autoComplete="off"
                                    className="input input-modal"
                                    value={data.unitMeasure ? data.unitMeasure : ''}
                                    onChange={(e) => handleSelectChange(e)}
                                >
                                    {Object.keys(unitOfMeasure).map((key) => (
                                        <option key={key} value={key}>
                                            {translate(unitOfMeasure[key])}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="display-flex mt-12p">
                        <div className="mr-4p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Category')}:
                            </label>
                            <Select
                                isMulti
                                className="mt-2"
                                placeholder={translate('Add tags')}
                                options={tagsOptions}
                                onChange={(changes) => handleCategoryChange(changes)}
                                value={tagsValues}
                            ></Select>
                        </div>

                        <div className="ml-4p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Subcategory')}:
                            </label>
                            <Select
                                isMulti
                                className="mt-2"
                                placeholder={translate('number:tag')}
                                options={subcategoryOptions}
                                onChange={(changes) => handleSubCategoryChange(changes)}
                                value={subcategory}
                            ></Select>
                        </div>
                    </div>

                    {message ? (
                        <div className="mt-12p">
                            <div className="text-bold ml-4p"></div>
                            <div className="col-md-8 text-danger">
                                <small>{message}</small>
                            </div>
                        </div>
                    ) : null}

                    <div className="display-flex mt-12p">
                        <div className="mr-4p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Tax')}:
                            </label>
                            <select
                                ref={tax}
                                name="taxId"
                                className="input input-modal"
                                onChange={(e) => handleSelectChange(e)}
                                value={data.taxId ? data.taxId : ''}
                            >
                                <option>{translate('No tax')}</option>
                                {taxes?.map((t) => {
                                    if (t.isActive) {
                                        return t.id !== Number(data.tax2Id) && t.id !== Number(data.tax3Id) ? (
                                            <option key={t.id} value={t.id}>
                                                {t.name}
                                            </option>
                                        ) : null;
                                    }
                                    return null;
                                })}
                            </select>
                        </div>

                        <div className="mr-4p ml-4p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Tax 2')}:
                            </label>
                            <select
                                disabled={country === 'BIH' ? true : false}
                                name="tax2Id"
                                className="input input-modal"
                                onChange={(e) => handleSelectChange(e)}
                                value={data.tax2Id ? data.tax2Id : ''}
                            >
                                <option>{translate('No tax')}</option>
                                {taxes?.map((t) => {
                                    if (t.isActive) {
                                        return t.id !== Number(data.taxId) && t.id !== Number(data.tax3Id) ? (
                                            <option key={t.id} value={t.id}>
                                                {t.name}
                                            </option>
                                        ) : null;
                                    }
                                    return null;
                                })}
                            </select>
                        </div>

                        <div className="ml-4p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Tax 3')}:
                            </label>
                            <select
                                disabled={country === 'BIH' ? true : false}
                                name="tax3Id"
                                className="input input-modal"
                                onChange={(e) => handleSelectChange(e)}
                                value={data.tax3Id ? data.tax3Id : ''}
                            >
                                <option>{translate('No tax')}</option>
                                {taxes?.map((t) => {
                                    if (t.isActive) {
                                        return t.id !== Number(data.taxId) && t.id !== Number(data.tax2Id) ? (
                                            <option key={t.id} value={t.id}>
                                                {t.name}
                                            </option>
                                        ) : null;
                                    }
                                    return null;
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="mt-12p">
                        <label className="text-bold ml-4p" htmlFor="">
                            {translate('Image')}
                        </label>
                        <div className="display-flex">
                            <div className="">
                                <input
                                    autoComplete="off"
                                    name="image"
                                    placeholder={translate('Image')}
                                    type="file"
                                    id="fotka"
                                    onChange={(e) => handleFileChange(e)}
                                />
                                <div id="image"></div>
                            </div>
                            <div className="">
                                <Image
                                    posItemId={props.posItemId}
                                    alt={data.name}
                                    style={{ height: '50px', width: '50px' }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-12p display-flex align-items-center">
                        {isEnabled}
                        <label className="text-bold ml-4p mr-8p mt-4p" htmlFor="">
                            {translate('Enable item at POS')}
                        </label>
                    </div>

                    <div className="mt-12p display-flex align-items-center">
                        <div>
                            <i
                                onClick={(e) => {
                                    let _data = { ...data };
                                    _data['preparationRequired'] = !_data.preparationRequired;
                                    setData(_data);
                                }}
                                className={preparationRequired}
                            />
                        </div>
                        <label className="text-bold ml-4p mr-8p mt-4p " htmlFor="">
                            {translate('Preparation required')}
                        </label>
                    </div>

                    <div className="mt-12p display-flex align-items-center">
                        <div>
                            <i
                                onClick={(e) => {
                                    let _data = { ...data };
                                    _data['separateItem'] = !_data.separateItem;
                                    setData(_data);
                                }}
                                className={separateItem}
                            />
                        </div>
                        <label className="text-bold ml-4p mr-8p mt-4p" htmlFor="">
                            {translate('Separate Item')}
                        </label>
                    </div>
                </div>
                <div className="flex ml-8p">
                    <FeatureFlag flag="goodsManagement">
                        <PermissionCheck name="goodsManagementPanel">
                            <div style={{ borderRadius: '8px', padding: '6px ', backgroundColor: '#f9f9f9' }}>
                                <div className="text-center mb-2">{translate('NORMATIVE')}</div>
                                <div className="mb-2">
                                    <CustomInput
                                        onInputChange={(e: any) => {
                                            onSearchSkuItem(e);
                                        }}
                                        value={searchTermNormative}
                                        icon={<Search />}
                                        placeholder={translate('Sku item by name, code or barcode')}
                                        width={'300px'}
                                    />
                                    {skuItemsFromSearchBox?.length > 0 ? (
                                        <div className="options-wrapper" style={{ width: '300px' }}>
                                            {skuItemsFromSearchBox?.map((i, index) => {
                                                if (i?.active) {
                                                    return (
                                                        <div
                                                            className="option"
                                                            style={{ width: '300px' }}
                                                            key={index}
                                                            onClick={(e) => {
                                                                handleRateChange(i, 'add');
                                                            }}
                                                        >
                                                            <span className="mx-2">{i.name}</span>
                                                            <span className="mx-2">{i.code}</span>
                                                            <span className="mx-2">
                                                                {unitOfMeasure[i.stockMeasureUnit]}
                                                            </span>
                                                        </div>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </div>
                                    ) : null}
                                </div>

                                {skuPluRates?.length > 0 ? (
                                    <div>
                                        <div
                                            className="custom-list-header mx-0"
                                            style={{ borderRadius: '4px', padding: '6px' }}
                                        >
                                            <div>{translate('Name')}</div>
                                            <div>{translate('measureUnit')}</div>
                                            <div>{translate('Multiplier')}</div>
                                            <div style={{ flex: 2 }} className="list-item-text-right">
                                                {translate('Normative retail price')}
                                            </div>
                                            <div></div>
                                        </div>
                                        <div className="my-3" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                            {skuPluRates?.map((r, index) => {
                                                if (!r.delete) {
                                                    return (
                                                        <div className="custom-list-item mt-2 mx-0" key={index}>
                                                            <div
                                                                className=""
                                                                style={{ fontSize: '12px', fontWeight: 'bold' }}
                                                            >
                                                                {r.StockKeepingUnit?.name}{' '}
                                                            </div>
                                                            <div>{r.StockKeepingUnit?.stockMeasureUnit}</div>
                                                            <div>
                                                                {' '}
                                                                <input
                                                                    className="bg-white input input-modal"
                                                                    value={
                                                                        r?.multiplier || r?.multiplier === 0
                                                                            ? r.multiplier
                                                                            : ''
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleRateChange(
                                                                            null,
                                                                            'multiplier',
                                                                            e.target.value,
                                                                            index
                                                                        )
                                                                    }
                                                                    name="multiplier"
                                                                    placeholder={translate('Multiplier')}
                                                                    autoComplete="off"
                                                                    type="number"
                                                                    min={0}
                                                                />{' '}
                                                            </div>
                                                            <div className="list-item-text-right" style={{ flex: 2 }}>
                                                                {r?.normativeRetailPrice
                                                                    ? Number(r.normativeRetailPrice)?.toFixed(4)
                                                                    : 0.0}{' '}
                                                            </div>
                                                            <div>
                                                                <div
                                                                    onClick={() => {
                                                                        handleRateChange(null, 'delete', null, index);
                                                                    }}
                                                                    className="button-white-w-icon-only fill-red text-right align-items-right"
                                                                >
                                                                    <DeleteIcon />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </div>
                                        <div
                                            className="custom-list-item mx-0"
                                            style={{
                                                borderTop: '1px solid lightgrey',
                                                borderTopLeftRadius: 0,
                                                borderTopRightRadius: 0,
                                                paddingTop: '6px',
                                            }}
                                        >
                                            <div className="text-bold">{translate('Total norm retail price')}: </div>
                                            <div></div>
                                            <div style={{ flex: 2 }} className="list-item-text-right">
                                                {data?.normativeRetailPriceTotal
                                                    ? Number(data?.normativeRetailPriceTotal)?.toFixed(4)
                                                    : 0.0}
                                            </div>

                                            <div>
                                                {skuPluRates?.length > 0 &&
                                                Number(data?.normativeRetailPriceTotal) !== Number(data.price) ? (
                                                    <div
                                                        className="button-primary"
                                                        style={{
                                                            flex: 'none',
                                                            paddingLeft: '5px',
                                                            paddingRight: '5px',
                                                        }}
                                                        onClick={() => {
                                                            setRetailPriceBasedOnCummulativeNormativePrice(
                                                                data?.normativeRetailPriceTotal
                                                                    ? Number(
                                                                          data?.normativeRetailPriceTotal?.toFixed(4)
                                                                      )
                                                                    : 0.0
                                                            );
                                                        }}
                                                    >
                                                        {translate('Apply price')}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div>{translate('List is empty')}</div>
                                )}
                            </div>
                        </PermissionCheck>
                    </FeatureFlag>

                    <FeatureFlag flag="goodsManagement">
                        <PermissionCheck name="goodsManagementPanel">
                            <div className="mt-12p ml-4p display-flex align-items-center">
                                {isComboItem}
                                <label className="text-bold ml-4p mr-8p mt-4p" htmlFor="">
                                    {translate('Is combo item')}
                                </label>
                            </div>
                        </PermissionCheck>
                    </FeatureFlag>

                    <div className="display-flex mt-12p ml-4p">
                        <div className="mr-8p flex">
                            <label className="text-bold ml-4p" htmlFor="">
                                {translate('Price')}:
                            </label>
                            <div className=" display-flex">
                                <input
                                    disabled={!data?.isComboItem && nonDeletedSkuPluRates?.length === 1}
                                    autoComplete="off"
                                    name="price"
                                    placeholder={translate('Price')}
                                    type="number"
                                    className="input input-modal"
                                    value={data?.price || data?.price === 0 ? data.price : ''}
                                    onChange={(e) => handleInputChange(e)}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text input input-modal">
                                        <i
                                            onClick={() => {
                                                if (!data.isComboItem && nonDeletedSkuPluRates?.length === 1) {
                                                } else {
                                                    let _data = { ...data };
                                                    _data['flexiblePrice'] = !_data.flexiblePrice;
                                                    setData(_data);
                                                }
                                            }}
                                            className={flexiblePrice}
                                        ></i>
                                        <Tooltip
                                            message={translate(
                                                'If the square is marked the price can be changed in the terminal'
                                            )}
                                            style={{ marginTop: '-30px', marginLeft: '-90px', width: 'auto' }}
                                        >
                                            <i className="fa fa-info-circle text-primary ml-2"></i>
                                        </Tooltip>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <FeatureFlag flag="goodsManagement">
                            <div className="flex ml-8p">
                                <label className="text-bold ml-4p" htmlFor="">
                                    {translate('Additional margin')}:
                                </label>
                                <input
                                    disabled={true}
                                    autoComplete="off"
                                    name="additionalMargin"
                                    placeholder={translate('Additional margin')}
                                    type="number"
                                    className="input input-modal"
                                    value={
                                        data?.additionalMargin || data?.additionalMargin === 0
                                            ? Number(data.additionalMargin).toFixed(4)
                                            : ''
                                    }
                                    onChange={(e) => {}}
                                />
                            </div>
                        </FeatureFlag>
                    </div>
                    <div className="mt-12p">
                        <label className="text-bold ml-4p">{translate('RELATED ITEMS')}</label>
                        <ShowRelatedOrSuplementsList
                            listFromSearchBox={itemsFromSearchBox}
                            itemArray={relatedArticlesPosItems}
                            addFunction={addRelatedArticle}
                            removeFunction={removeRelatedArticle}
                            type={'relatedArticles'}
                            onSearch={onSearch}
                            searchTerm={searchTermRelatedArticle}
                            defaultCurrency={defaultCurrency}
                        />
                    </div>
                    <div className="mt-12p">
                        <label className="text-bold ml-4p">{translate('SUPLEMENTS')}</label>
                        <div className="justify-content-center align-items-center mt-2 mb-2 ml-8p mr-8p">
                            <Select
                                isMulti
                                placeholder={translate('Add suplement')}
                                options={suplementsTagsOptions}
                                onChange={(changes) => handleSuplemets(changes)}
                                value={suplements}
                            ></Select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="display-flex justify-content-end mt-20p">
                {props.posItemId ? (
                    <div data-dismiss="modal" onClick={() => copyItemData()} className="button-info mr-8p">
                        {translate('Copy item')}
                    </div>
                ) : null}
                <div
                    data-dismiss="modal"
                    onClick={() => {
                        if (props.closeFunc) {
                            props.closeFunc();
                        } else {
                            closeModal();
                        }
                    }}
                    className="button-secondary mr-8p"
                >
                    {translate('Cancel')}
                </div>
                <div data-dismiss="modal" onClick={() => saveFormData()} className="button-primary">
                    {translate('Save')}
                </div>
            </div>
        </div>
    );
}

interface ShowRelatedOrSuplementsListProps {
    listFromSearchBox: PosItemModel[];
    itemArray: PosItemModel[];
    addFunction: Function;
    removeFunction: Function;
    searchTerm: string;
    onSearch: Function;
    type: string;
    defaultCurrency: string;
}

const ShowRelatedOrSuplementsList = (props: ShowRelatedOrSuplementsListProps) => {
    const { listFromSearchBox, itemArray, addFunction, removeFunction, searchTerm, onSearch, type, defaultCurrency } =
        props;
    return (
        <>
            <div className="mb-2 ml-8p mr-8p align-items-center">
                <CustomInput
                    onInputChange={(e: any) => {
                        onSearch(e, type);
                    }}
                    icon={<Search />}
                    value={searchTerm}
                    placeholder={translate('Name')}
                    width={'380px'}
                />
            </div>
            <div className="my-2">
                {listFromSearchBox?.length > 0 ? (
                    <div className="options-wrapper" style={{ width: '300px' }}>
                        {listFromSearchBox?.map((i: PosItemModel, index: number) => {
                            return (
                                <div
                                    className="option"
                                    style={{ width: '300px' }}
                                    key={index}
                                    onClick={(e) => {
                                        addFunction(i);
                                    }}
                                >
                                    <span className="mx-2">{i.name}</span>
                                    <span className="mx-2">{i.plu}</span>
                                    <span className="mx-2">{i.unitMeasure ? unitOfMeasure[i.unitMeasure] : ''}</span>
                                    <span className="mx-2">{i.price ? i.price + ' ' + defaultCurrency : ''}</span>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
            </div>
            <div className="mr-2 mt-1">
                {itemArray?.map((posItem: PosItemModel, key: number) => {
                    if (posItem) {
                        return (
                            <div className="custom-list-item" key={key}>
                                <div
                                    className="text-bold"
                                    style={{ fontSize: '12px', width: '80%', fontWeight: 'bold' }}
                                >
                                    {posItem.name}
                                </div>
                                <div>{posItem?.plu}</div>
                                <div>{posItem?.unitMeasure ? unitOfMeasure[posItem.unitMeasure] : ''}</div>
                                <div>
                                    <div
                                        onClick={() => {
                                            removeFunction(posItem);
                                        }}
                                        className="button-white-w-icon-only fill-red align-items-right"
                                    >
                                        <DeleteIcon />
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
            </div>
        </>
    );
};

export default PosItemForm;
