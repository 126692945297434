import * as React from 'react';
import Select from 'react-select';
import { Reservation, ReservationController } from '../resController';
import { translate } from '@data/translations';
import OpenPrice from './OpenPrice';
//import Board from './Board';
import { AccommodationPricelistModel, RoomInfoModel } from '@common/modelDefinition';
import useStore from '@data/state/zustand';

const parseTags = (tagsString: string) => {
    return tagsString
        ? tagsString
              .trim()
              .split(';')
              .filter((t) => {
                  return t !== '';
              })
        : [];
};

interface AssignPriceListsProps {
    reservation: Reservation;
    reservations: Reservation[];
    room: RoomInfoModel;
}

function AssignPriceLists(props: AssignPriceListsProps) {
    const { reservation, /* reservations, */ room } = props;
    const ctrl = new ReservationController();
    const roomTags = room?.tags ? parseTags(room.tags) : [];

    const acp: AccommodationPricelistModel[] = useStore((appState: any) => appState.model.AccommodationPriceList); 
    const acpTags: string[] = useStore((appState: any) => appState.tag.AccommodationPriceList()); 

    //prođi preko ssvih tagova na cjenicima i izdvoji one koji se preklapaju
    const commonTags: string[] = [];
    acpTags && acpTags.forEach((plTag: string) => {
        if (roomTags.includes(plTag)) {
            commonTags.push(plTag);
        }
    });

    const _reservation = { ...reservation };
    if (!reservation.tags) {
        _reservation.tags = commonTags.join(';');
    } else {
        _reservation.tags = reservation.tags;
    }

    // const accPriceListSubset = [];
    const reservationTags = _reservation.tags ? parseTags(_reservation.tags) : [];

    const pricelists: AccommodationPricelistModel[] = [];
    let pricelistsCandidates = getTargetPricelists(acp, reservationTags);
    for (const pricelistsCandidate of pricelistsCandidates) {
        const targetPricelist = acp?.find((p) => p.id === pricelistsCandidate.id);
        if (targetPricelist) {
            pricelists.push(targetPricelist);
        }
    }

    //AccommodationPricelistModel[] = getRoomPricelist(room, acp);
    let tagsOptions: any[] = [];

    acp && acp.forEach((a) => {
        if (a.tags) {
            const tags = a.tags.split(';');
            let hitCount = 0;
            tags.forEach((t: string) => {
                if (reservationTags.includes(t)) {
                    hitCount++;
                }
            });
            if (hitCount === reservationTags.length) {
                tags.forEach((t: string) => {
                    if (t !== 'condo' && !tagsOptions.includes(t) && !reservationTags.includes(t)) {
                        tagsOptions.push(t);
                    }
                });
            }
        }
    });

    const priceListOptions = pricelists.map((pl: any) => {
        return {
            value: pl.id,
            label: pl.name,
        };
    });

    const acpTagOptions = tagsOptions.map((tag: string) => {
        return {
            value: tag,
            label: tag,
        };
    });

    const reservationTagsOptions = reservationTags.map((tag: string) => {
        return {
            value: tag,
            label: tag,
        };
    });

    return (
        <React.Fragment>
            {reservation.canceledReservationInvoicingStatus === 0 ? (
                <div className="display-flex mobile-page-form">
                    <div className="flex mr-8p mb-8p">
                        <label className="text-bold ml-4p mb-4p mt-2p">{translate('Filter pricelists')}</label>
                        <div className="">
                            <Select
                                isMulti
                                className=""
                                placeholder={translate('Slice pricelist by tags')}
                                options={acpTagOptions}
                                onChange={(values: any) => {
                                    const res = { ...reservation };
                                    const input = values ? values : [];
                                    //uvijek ostavi tagove sobe tako da se ne mogu izbrisati
                                    const tagsAfterUpdate = commonTags;
                                    input.forEach((t: any) => {
                                        if (!tagsAfterUpdate.includes(t.value)) {
                                            tagsAfterUpdate.push(t.value);
                                        }
                                    });
                                    res.tags = tagsAfterUpdate.map((t) => t).join(';');
                                    ctrl.updateActiveReservation(res);
                                }}
                                value={reservationTagsOptions}
                            ></Select>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="display-flex mobile-page-form">
                            <div className="flex  mr-8p mb-8p">
                                <label className="text-bold ml-4p mb-4p mt-2p">{translate('Select pricelist')}</label>
                                <Select
                                    className=""
                                    placeholder={translate('Select pricelist')}
                                    options={priceListOptions}
                                    onChange={(selected: any) => {
                                        const value = selected.value;
                                        const res = { ...reservation };
                                        res.accommodationPriceListId = value ? value : null;
                                        ctrl.updateActiveReservation(res);
                                    }}
                                    selectValue={reservation.accommodationPriceListId}
                                ></Select>
                            </div>
                            <div className="flex mb-8p">
                                <OpenPrice reservation={reservation} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
}

export const getRoomPricelist = (targetRoom: RoomInfoModel, priceLists: AccommodationPricelistModel[]) => {
    const roomTags = targetRoom && targetRoom.tags && targetRoom.tags.length > 0 ? parseTags(targetRoom.tags) : [];
    const result: any[] = [];
    const targetPriceLists: any[] = getTargetPricelists(priceLists, roomTags);
    for (const targetPriceList of targetPriceLists) {
        if (targetPriceList.tags.length === roomTags.length && targetPriceList.otherTags.length === 0) {
            result.push(targetPriceList);
        }
    }
    return result;
};

const getTargetPricelists = (priceLists: AccommodationPricelistModel[], roomTags: string[]) => {
    const targetPriceLists: any[] = [];
    priceLists.forEach((p: AccommodationPricelistModel) => {
        const aplTags = p?.tags ? parseTags(p.tags) : [];
        const plObj: any = { id: p.id, tags: [], otherTags: [] };
        for (const aplTag of aplTags) {
            const found = roomTags.find((r) => r === aplTag);
            if (found) {
                plObj.tags.push(aplTag);
            } else {
                plObj.otherTags.push(aplTag);
            }
        }
        if (plObj.tags.length > 0 && plObj.tags.length >= roomTags.length) {
            targetPriceLists.push(plObj);
        }
    });
    const sortedTargetPriceLists = targetPriceLists.sort(
        (a, b) => parseFloat(b.tags.length) - parseFloat(a.tags.length)
    );

    return sortedTargetPriceLists;
};

export default AssignPriceLists;
