import React from 'react';
import _ from 'lodash';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import { ReactComponent as TickInsideCircle } from '../../assets/custom/icons/tick_inside_circle.svg';
import { ReactComponent as SignOut } from '../../assets/custom/icons/sign_out.svg';

import { ReactComponent as Tick } from '../../assets/custom/icons/tick.svg';
import translate from '@data/translations';
import {ReservationGuestBoards} from './reservationGuestBoards'
const moment = extendMoment(originalMoment);

const SidebarDailyInfo = (props) => {
    return (
        <React.Fragment>
            <div
                className="sidebarDailyInfo mt-3"
                style={{
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 8px 20px rgba(105, 119, 140, 0.15)',
                }}
            >
                <div
                    className="mt-2"
                    style={{
                        fontSize: '12px',
                        fontWeight: '400',
                        left: '24px',
                        position: 'relative',
                        color: '#00112B',
                    }}
                >
                    {translate('Current situation')}
                </div>
                <div className="mt-2" style={{ position: 'relative' }}>
                    <AvailableRoomsTakenRooms {...props} />
                </div>
                <div style={{ position: 'relative' }}>
                    <CurrentGuestNumber2 {...props} />
                </div>
            </div>

            <div
                className="sidebarDailyInfo"
                style={{
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 8px 20px rgba(105, 119, 140, 0.15)',
                }}
            >
                <div
                    className="mt-2"
                    style={{
                        fontSize: '12px',
                        fontWeight: '400',
                        left: '24px',
                        position: 'relative',
                        color: '#00112B',
                    }}
                >
                    {translate('Today')}
                </div>
                <div className="mt-2">
                    <HotelOccupancy2 {...props} from={moment().startOf('day')} to={moment().endOf('day')} />
                </div>
                <div>
                    <CheckinsRoomsCheckinsGuests {...props} from={moment().startOf('day')} to={moment().endOf('day')} />
                </div>
                <div>
                    <CheckoutsRoomsCheckoutsGuests
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().endOf('day')}
                    />
                </div>
                <div>
                    <ReservationGuestBoards
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().endOf('day')}
                        period={'Today'}
                    />
                </div>
            </div>

            <div
                className="sidebarDailyInfo"
                style={{
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 8px 20px rgba(105, 119, 140, 0.15)',
                }}
            >
                <div
                    className="mt-2"
                    style={{
                        fontSize: '12px',
                        fontWeight: '400',
                        left: '24px',
                        position: 'relative',
                        color: '#00112B',
                    }}
                >
                    {translate('Tomorrow')}
                </div>
                <div className="mt-2">
                    <HotelOccupancy2
                        {...props}
                        from={moment().add(1, 'days').startOf('day')}
                        to={moment().add(1, 'days').endOf('day')}
                    />
                </div>
                <div>
                    <CheckinsRoomsCheckinsGuests
                        {...props}
                        from={moment().add(1, 'days').startOf('day')}
                        to={moment().add(1, 'days').endOf('day')}
                    />
                </div>
                <div>
                    <CheckoutsRoomsCheckoutsGuests
                        {...props}
                        from={moment().add(1, 'days').startOf('day')}
                        to={moment().add(1, 'days').endOf('day')}
                    />
                </div>
                <div>
                    <ReservationGuestBoards
                        {...props}
                        from={moment().add(1, 'days').startOf('day')}
                        to={moment().add(1, 'days').endOf('day')}
                        period={'Tomorow'}
                    />
                </div>
            </div>

            <div
                className="sidebarDailyInfo"
                style={{
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 8px 20px rgba(105, 119, 140, 0.15)',
                }}
            >
                <div
                    className="mt-2"
                    style={{
                        fontSize: '12px',
                        fontWeight: '400',
                        left: '24px',
                        position: 'relative',
                        color: '#00112B',
                    }}
                >
                    {translate('Next 15 days')}
                </div>
                <div className="mt-2">
                    <HotelOccupancy2
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(14, 'days').endOf('day')}
                    />
                </div>
                <div>
                    <CheckinsRoomsCheckinsGuests
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(14, 'days').endOf('day')}
                    />
                </div>
                <div>
                    <CheckoutsRoomsCheckoutsGuests
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(14, 'days').endOf('day')}
                    />
                </div>
                <div>
                    <ReservationGuestBoards
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(14, 'days').endOf('day')}
                        period={'15 days'}
                    />
                </div>
            </div>

            <div
                className="sidebarDailyInfo"
                style={{
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 8px 20px rgba(105, 119, 140, 0.15)',
                }}
            >
                <div
                    className="mt-2"
                    style={{
                        fontSize: '12px',
                        fontWeight: '400',
                        left: '24px',
                        position: 'relative',
                        color: '#00112B',
                    }}
                >
                    {translate('Next 30 days')}
                </div>
                <div className="mt-2">
                    <HotelOccupancy2
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(29, 'days').endOf('day')}
                    />
                </div>
                <div>
                    <CheckinsRoomsCheckinsGuests
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(29, 'days').endOf('day')}
                    />
                </div>
                <div>
                    <CheckoutsRoomsCheckoutsGuests
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(29, 'days').endOf('day')}
                    />
                </div>
                <div>
                    <ReservationGuestBoards
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(29, 'days').endOf('day')}
                        period={'Month'}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};
export default SidebarDailyInfo;

const CheckinsRooms = ({ reservations, from, to }) => {
    const checkins = reservations.filter(
        (r) => (r.statusEnum === 1 || r.statusEnum === 2) && moment(r.checkInTimestamp).isBetween(from, to)
    );

    return (
        <div className="col-6">
            <div className="pl-3 pr-3">
                <small>
                    {window.translate('Rooms')}: {checkins.length}
                </small>
            </div>
        </div>
    );
};

const CheckinsGuests = ({ reservations, from, to }) => {
    const checkins = reservations.filter(
        (r) => (r.statusEnum === 1 || r.statusEnum === 2) && moment(r.checkInTimestamp).isBetween(from, to)
    );
    const adultsNumberIncoming = _.sumBy(checkins, (a) => {
        return Number(a.adultsNumber);
    });
    const childrenNumberIncoming = _.sumBy(checkins, (a) => {
        return Number(a.childrenNumber);
    });

    const totalGuestsIncoming = adultsNumberIncoming + childrenNumberIncoming;

    return (
        <div className="col-6">
            <div className="pl-3 pr-3">
                <small>
                    {window.translate('Guests')}: {totalGuestsIncoming}
                </small>
            </div>
        </div>
    );
};

const CheckoutsGuests = ({ reservations, from, to }) => {
    const checkouts = reservations.filter(
        (r) => (r.statusEnum === 1 || r.statusEnum === 2) && moment(r.checkOutTimestamp).isBetween(from, to)
    );
    const adultsNumberOngoing = _.sumBy(checkouts, (a) => {
        return Number(a.adultsNumber);
    });
    const childrenNumberOngoing = _.sumBy(checkouts, (a) => {
        return Number(a.childrenNumber);
    });

    const totalGuestsOngoing = adultsNumberOngoing + childrenNumberOngoing;
    return (
        <div className="col-6">
            <div className="pl-3 pr-3">
                <small>
                    {window.translate('Guests')}: {totalGuestsOngoing}
                </small>
            </div>
        </div>
    );
};

const CheckoutsRooms = ({ reservations, from, to }) => {
    const checkouts = reservations.filter(
        (r) => (r.statusEnum === 1 || r.statusEnum === 2) && moment(r.checkOutTimestamp).isBetween(from, to)
    );
    return (
        <div className="col-6">
            <div className="pl-3 pr-3">
                <small>
                    {window.translate('Rooms')}: {checkouts.length}
                </small>
            </div>
        </div>
    );
};

const IncomingsDeparturesHeader = () => {
    return (
        <div className=" text-center p-0  mt-1 d-flex">
            <div className="primary-bg-1 text-white col-6 border-radius-4">
                <small>
                    <b>{window.translate('Incomings')}</b>
                </small>
            </div>
            <div className="primary-bg-7 text-white col-6 border-radius-4">
                <small>
                    <b>{window.translate('Departures')}</b>
                </small>
            </div>
        </div>
    );
};

const HotelOccupancy = ({ from, to, reservations, rooms }) => {
    const numberOfRooms = rooms.length;
    const maxHotelOccupancyDays = moment(to).diff(moment(from), 'days') + 1;
    const maxCapacity = maxHotelOccupancyDays * numberOfRooms;

    const range = moment.range(from, to);

    const filteredReservations = reservations.filter((r) => r.statusEnum === 2 || r.statusEnum === 1);
    const daysTakenRangesArr = [];
    for (const reservation of filteredReservations) {
        const range1 = moment.range(moment(reservation.checkInTimestamp), moment(reservation.checkOutTimestamp));

        const res = range1.intersect(range);
        if (res) {
            daysTakenRangesArr.push(res);
        }
    }
    let daysTaken = 0;
    for (const dayTakenRanges of daysTakenRangesArr) {
        daysTaken += moment(dayTakenRanges.end).diff(moment(dayTakenRanges.start), 'days') + 1;
    }

    return (
        <React.Fragment>
            <div className=" text-center menu-item-selected border-radius-4 font-16" style={{ padding: '4px' }}>
                <small>
                    <b>{window.translate('Potential hotel occupancy')}</b>
                </small>
            </div>
            <div className=" p-0">
                <div className="d-flex justify-content-between">
                    <div className="p-2">
                        <small>
                            {window.translate('Days')}: {daysTaken}
                        </small>
                    </div>
                    <div className="p-2">
                        <small>
                            {window.translate('Percentage')}: {parseFloat((daysTaken * 100) / maxCapacity).toFixed(2)}%
                        </small>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

const CheckinsRoomsCheckinsGuests = ({ reservations, from, to }) => {
    const checkins = reservations.filter(
        (r) => (r.statusEnum === 1 || r.statusEnum === 2) && moment(r.checkInTimestamp).isBetween(from, to)
    );

    const adultsNumberIncoming = _.sumBy(checkins, (a) => {
        return Number(a.adultsNumber);
    });
    const childrenNumberIncoming = _.sumBy(checkins, (a) => {
        return Number(a.childrenNumber);
    });

    const totalGuestsIncoming = adultsNumberIncoming + childrenNumberIncoming;

    return (
        <div className="display-flex align-items-center mt-2">
            <div
                className="color-primary mt-1 ml-4"
                style={{
                    height: '40px',
                    width: '40px',
                    borderRadius: '80px',
                    padding: '4px',
                    backgroundColor: '#DBE8FC',
                }}
            >
                <TickInsideCircle />
            </div>
            <div className="ml-16p">
                <div style={{ fontWeight: '700', fontSize: '20px' }}>
                    {checkins.length} / {totalGuestsIncoming}
                </div>
                <div style={{ fontWeight: '400', fontSize: '12px', color: '#69778C' }}>
                    {translate('rooms / guests incoming')}
                </div>
            </div>
        </div>
    );
};

const CheckoutsRoomsCheckoutsGuests = ({ reservations, from, to }) => {
    const checkouts = reservations.filter(
        (r) => (r.statusEnum === 1 || r.statusEnum === 2) && moment(r.checkOutTimestamp).isBetween(from, to)
    );

    const adultsNumberOngoing = _.sumBy(checkouts, (a) => {
        return Number(a.adultsNumber);
    });
    const childrenNumberOngoing = _.sumBy(checkouts, (a) => {
        return Number(a.childrenNumber);
    });

    const totalGuestsOngoing = adultsNumberOngoing + childrenNumberOngoing;

    return (
        <div className="display-flex align-items-center mt-2">
            <div
                className="color-dark mt-1 ml-4"
                style={{
                    height: '40px',
                    width: '40px',
                    borderRadius: '80px',
                    padding: '4px',
                    backgroundColor: '#F3F6FB',
                }}
            >
                <SignOut />
            </div>
            <div className="ml-16p">
                <div style={{ fontWeight: '700', fontSize: '20px' }}>
                    {checkouts.length} / {totalGuestsOngoing}
                </div>
                <div style={{ fontWeight: '400', fontSize: '12px', color: '#69778C' }}>
                    {translate('rooms / guests outgoing')}
                </div>
            </div>
        </div>
    );
};

const CurrentGuestNumber2 = ({ reservations }) => {
    const allCheckedInReservations = reservations.filter((r) => r.statusEnum === 2);
    const adultsNumber = _.sumBy(allCheckedInReservations, (a) => {
        return Number(a.adultsNumber);
    });
    const childrenNumber = _.sumBy(allCheckedInReservations, (a) => {
        return Number(a.childrenNumber);
    });

    const totalGuests = adultsNumber + childrenNumber;
    return (
        <div className="display-flex align-items-center mt-2">
            <div
                className="color-white mt-1 ml-4"
                style={{
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    backgroundColor: totalGuests > 0 ? '#1068EC' : '#DBE8FC',
                    transform: 'scale(1)',
                }}
            >
                <Tick height={'50'} width={'50'} />
            </div>
            <div className="ml-16p">
                <div style={{ fontWeight: '700', fontSize: '26px' }}>{totalGuests}</div>
                <div style={{ fontWeight: '400', fontSize: '12px', color: '#69778C' }}>
                    {translate('guests currently checked in')}
                </div>
            </div>
        </div>
    );
};

const AvailableRoomsTakenRooms = ({ reservations, rooms }) => {
    const takenRoomInfoIds = [];
    for (const reservation of reservations) {
        if (reservation.statusEnum === 2) {
            takenRoomInfoIds.push(reservation.roomInfoId);
        }
    }
    const roomsTaken = rooms.filter((r) => takenRoomInfoIds.includes(r.id));

    return (
        <div className="display-flex align-items-center">
            <div className="mt-1 ml-4" style={{ height: '50px', width: '50px' }}>
                <Pie
                    percentage={parseFloat((roomsTaken.length / rooms.length) * 100).toFixed(2)}
                    colour={'#1068EC'}
                    width4050={50}
                    heigth4050={50}
                    radius={20}
                    cx={175}
                    cy={25}
                    strokeWidth={'10px'}
                />
            </div>

            <div className="ml-16p">
                <div style={{ fontWeight: '700', fontSize: '26px' }}>
                    {parseFloat((roomsTaken.length / rooms.length) * 100).toFixed(2)}%
                </div>
                <div
                    style={{
                        fontWeight: '400',
                        fontSize: '12px',
                        color: '#69778C',
                    }}
                >
                    {roomsTaken.length}/{rooms.length} {translate('rooms occupied')}
                </div>
            </div>
        </div>
    );
};

const Circle = ({ colour, percentage, radius, cx, cy, strokeWidth }) => {
    const r = radius;
    const circ = 2 * Math.PI * r;
    const strokePct = ((100 - percentage) * circ) / 100; // where stroke will start, e.g. from 15% to 100%.
    return (
        <circle
            r={r}
            cx={cx}
            cy={cy}
            fill="transparent"
            stroke={strokePct.toFixed(2) !== circ.toFixed(2) ? colour : ''} // remove colour as 0% sets full circumference
            strokeWidth={strokeWidth}
            strokeDasharray={circ}
            strokeDashoffset={percentage ? strokePct : 0}
        ></circle>
    );
};

const cleanPercentage = (percentage) => {
    const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
    const isTooHigh = percentage > 100;
    return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

const Pie = ({ percentage, colour, width4050, heigth4050, radius, cx, cy, strokeWidth }) => {
    const pct = cleanPercentage(percentage);
    return (
        <svg width={width4050} height={heigth4050}>
            <g transform={`rotate(-90 ${'100 100'})`}>
                <Circle colour="#DBE8FC" radius={radius} cx={cx} cy={cy} strokeWidth={strokeWidth} />
                <Circle colour={colour} percentage={pct} radius={radius} cx={cx} cy={cy} strokeWidth={strokeWidth} />
            </g>
        </svg>
    );
};

const HotelOccupancy2 = ({ from, to, reservations, rooms }) => {
    const numberOfRooms = rooms.length;
    const maxHotelOccupancyDays = moment(to).diff(moment(from), 'days') + 1;
    const maxCapacity = maxHotelOccupancyDays * numberOfRooms;
    const range = moment.range(from, to);

    const filteredReservations = reservations.filter((r) => r.statusEnum === 2 || r.statusEnum === 1);
    const daysTakenRangesArr = [];
    for (const reservation of filteredReservations) {
        let range1 = moment.range(moment(reservation.checkInTimestamp), moment(reservation.checkOutTimestamp));
        if (reservation && moment(reservation.checkOutTimestamp).isSame(moment(to), 'day')) {
            range1 = moment.range(
                moment(reservation.checkInTimestamp),
                moment(reservation.checkOutTimestamp).subtract(1, 'day')
            );
        }
        const res = range1.intersect(range);
        if (res) {
            daysTakenRangesArr.push(res);
        }
    }
    let daysTaken = 0;
    for (const dayTakenRanges of daysTakenRangesArr) {
        daysTaken += moment(dayTakenRanges.end).diff(moment(dayTakenRanges.start), 'days') + 1;
    }

    return (
        <React.Fragment>
            <div className="display-flex align-items-center mt-2">
                <div style={{ height: '40px', width: '40px' }} className="mt-1 ml-4">
                    <Pie
                        percentage={parseFloat((daysTaken * 100) / maxCapacity).toFixed(2)}
                        colour={'#1068EC'}
                        width4050={40}
                        heigth4050={40}
                        radius={16}
                        cx={180}
                        cy={20}
                        strokeWidth={'7px'}
                    />
                </div>
                <div className="ml-16p">
                    <div style={{ fontWeight: '700', fontSize: '20px' }}>
                        {parseFloat((daysTaken * 100) / maxCapacity).toFixed(2)}%
                    </div>
                    <div
                        style={{
                            fontWeight: '400',
                            fontSize: '12px',
                            color: '#69778C',
                        }}
                    >
                        {translate('potential hotel occupancy')}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};







export { CheckoutsRooms, CheckoutsGuests, CheckinsGuests, CheckinsRooms, HotelOccupancy, IncomingsDeparturesHeader };
