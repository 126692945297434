import * as React from 'react';
import RoomAttributes from '@components/attributes';
import { AttributesModel, RoomInfoModel } from '@common/modelDefinition';
import useStore from '@data/state/zustand';

interface ReservationRoomAttributesProps {
    attributes: any[];
    room?: RoomInfoModel;
}

function ReservationRoomAttributes(props: ReservationRoomAttributesProps) {
    const { room = undefined } = props;
    const attributes: AttributesModel[] = useStore((appState: any) => appState.model.RoomAttribute);

    if (!room) {
        return null;
    }
    const roomAttributesList = room.roomAttributes ? JSON.parse(room.roomAttributes) : [];

    if (roomAttributesList.length === 0) {
        return null;
    } else {
        return (
            <div className="pt-1">
                <RoomAttributes room={room} attributes={attributes} />
            </div>
        );
    }
}

export default ReservationRoomAttributes;
