import React from 'react';
import {
    CheckinsRooms,
    CheckinsGuests,
    CheckoutsGuests,
    CheckoutsRooms,
    HotelOccupancy,
    IncomingsDeparturesHeader,
} from './sidebarDailyInfo';

import moment from 'moment';
import useStore from '@data/state/zustand';
import translate from '@data/translations';
import { Reservation } from '@pages/newReservations/resController';
import { RoomInfoEditModel } from '@common/modelDefinition';

interface SidebarWeeklyAndMonthlyInfoProps {
    availableToTimestamp: number | null;
    availableFromTimestamp: number;
    reservations: Reservation[];
    rooms: RoomInfoEditModel[];
}

const SidebarWeeklyAndMonthlyInfo = (props: SidebarWeeklyAndMonthlyInfoProps) => {
    const { availableToTimestamp } = props;
    const availableFromTimestamp = props.availableFromTimestamp ? props.availableFromTimestamp : moment().valueOf();

    const locale = useStore.getState().locale;

    return (
        <React.Fragment>
            <div className=" mt-3 border-top">
                <div className=" text-center p-2">
                    <b>{translate('Next 15 days')}</b>
                </div>

                <HotelOccupancy {...props} from={moment().startOf('day')} to={moment().add(14, 'days').endOf('day')} />
                <IncomingsDeparturesHeader />
                <div className=" text-center p-0  mt-1 d-flex">
                    <CheckinsRooms
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(14, 'days').endOf('day')}
                    />
                    <CheckoutsRooms
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(14, 'days').endOf('day')}
                    />
                </div>
                <div className=" text-center p-0  mt-1 d-flex">
                    <CheckinsGuests
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(14, 'days').endOf('day')}
                    />
                    <CheckoutsGuests
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(14, 'days').endOf('day')}
                    />
                </div>
            </div>

            <div className=" mt-3 border-top">
                <div className=" text-center p-2">
                    <b>{translate('Next 60 days')}</b>
                </div>
                <HotelOccupancy {...props} from={moment().startOf('day')} to={moment().add(59, 'days').endOf('day')} />
                <IncomingsDeparturesHeader />
                <div className=" text-center p-0  mt-1 d-flex">
                    <CheckinsRooms
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(59, 'days').endOf('day')}
                    />
                    <CheckoutsRooms
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(59, 'days').endOf('day')}
                    />
                </div>
                <div className=" text-center p-0  mt-1 d-flex">
                    <CheckinsGuests
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(59, 'days').endOf('day')}
                    />
                    <CheckoutsGuests
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(59, 'days').endOf('day')}
                    />
                </div>
            </div>

            <div className=" mt-3 border-top">
                <div className=" text-center p-2">
                    <div>
                        <b>{translate('Availability range')}</b>
                    </div>
                    {availableToTimestamp ? (
                        <div>
                            <small>
                                {moment(availableFromTimestamp).locale(locale).format('ll')} -{' '}
                                {moment(availableToTimestamp).locale(locale).format('ll')}
                            </small>
                        </div>
                    ) : null}
                </div>
                {availableToTimestamp ? (
                    <React.Fragment>
                        <HotelOccupancy
                            {...props}
                            from={moment(availableFromTimestamp).startOf('day')}
                            to={moment(availableToTimestamp).endOf('day')}
                        />
                        <IncomingsDeparturesHeader />
                        <div className=" text-center p-0  mt-1 d-flex">
                            <CheckinsRooms
                                {...props}
                                from={moment(availableFromTimestamp).startOf('day')}
                                to={moment(availableToTimestamp).endOf('day')}
                            />
                            <CheckoutsRooms
                                {...props}
                                from={moment(availableFromTimestamp).startOf('day')}
                                to={moment(availableToTimestamp).endOf('day')}
                            />
                        </div>
                        <div className=" text-center p-0  mt-1 d-flex">
                            <CheckinsGuests
                                {...props}
                                from={moment(availableFromTimestamp).startOf('day')}
                                to={moment(availableToTimestamp).endOf('day')}
                            />
                            <CheckoutsGuests
                                {...props}
                                from={moment(availableFromTimestamp).startOf('day')}
                                to={moment(availableToTimestamp).endOf('day')}
                            />
                        </div>
                    </React.Fragment>
                ) : (
                    <div className=" text-center p-1">
                        <small>{translate('Select a period on the dashboard toolbar!')}</small>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default SidebarWeeklyAndMonthlyInfo;
