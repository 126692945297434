import {
    HouseholdRefillSetupModel,
    ReservationEditModel,
    RoomInfoEditModel,
    SkuPluRateWithItem,
    StockDocItemModel,
    StockLogModel,
    TaskManagementHeaderEditModel,
    TaskManagementHeaderModel,
    TaskManagementItemModel,
    UserInfo,
    WarehouseItemModel,
    WarehouseModel,
    WarehouseStock,
} from '@common/modelDefinition';
import {
    Assigments,
    ChangeTasksRoom,
    GetRequiresCleaningRooms,
    LastCleaneadTimes,
    MaidInterface,
    RoomAndReservation,
    TaskStatusEnum,
    WarehouseItemType,
} from './interfaces';
import {
    getMaintananceTaskItems,
    getTaskItemsByTaskHeaderId,
    getLostAndFoundTaskItems,
    getRequiresCleaningRooms as _getRequiresCleaningRooms,
    getHouseholdStaff as _getHouseholdStaff,
} from './api';
import useStore from '@data/state/zustand';

export const getTimeForTimePicker = (workingHours: number) => {
    // Izračunajte sat i minutu
    var hours = Math.floor(workingHours); // Pretvara float u cijeli broj sati
    var minutes = (workingHours - hours) * 60; // Pretvara decimalni dio u minute
    const newDate = new Date(new Date().setHours(hours)).setMinutes(minutes);
    const result = new Date(newDate).getTime();
    return result;
};

export const setTaskItems = (taskItems: TaskManagementItemModel[]) => {
    useStore.getState().setNestedProperty(['model', 'TaskManagementItem'], taskItems)

};
export const setTaskHeader = (taskHeader: TaskManagementHeaderModel) => {
    useStore.getState().setNestedProperty(['model', 'TaskManagementHeader'], [taskHeader])
};

export const getBaobabTaskHeader = () => {
    return  useStore.getState().model.TaskManagementHeader
};

export const getHouseholdStaff = async () => {
    const householdStaff = await _getHouseholdStaff();
    return householdStaff;
};

export const reloadTaskItems = async (taskHeader: TaskManagementHeaderEditModel) => {
    if (taskHeader) {
        const _todaysTaskItems = await getTaskItemsByTaskHeaderId(taskHeader.id);
        const _maintenanceTasks = await getMaintananceTaskItems({});
        const _lostAndFoundTasks = await getLostAndFoundTaskItems();

        const _taskItems: TaskManagementItemModel[] = [];
        for (const todaysTaskItem of _todaysTaskItems) {
            _taskItems.push(todaysTaskItem);
        }
        for (const _maintenanceTask of _maintenanceTasks) {
            const alreadySet = _taskItems?.find((t) => t.id === _maintenanceTask.id);
            if (!alreadySet) {
                _taskItems.push(_maintenanceTask);
            }
        }
        for (const _lostAndFoundTask of _lostAndFoundTasks) {
            const alreadySet = _taskItems?.find((t) => t.id === _lostAndFoundTask.id);
            if (!alreadySet) {
                _taskItems.push(_lostAndFoundTask);
            }
        }
        setTaskItems(_taskItems);
    }
};

export const getRoomsByTaskPriority = (roomInfoIds: number[], roomReservationStatuses: any) => {
    const arrivalRooms = roomReservationStatuses?.arrival
        ? roomReservationStatuses?.arrival?.filter((a: number) => {
              if (roomInfoIds.includes(a)) {
                  return a;
              } else {
                  return null;
              }
          })
        : [];
    const dueOutRooms = roomReservationStatuses?.dueOut
        ? roomReservationStatuses?.dueOut?.filter((a: number) => {
              if (roomInfoIds.includes(a)) {
                  return a;
              } else {
                  return null;
              }
          })
        : [];
    const checkedOutRooms = roomReservationStatuses?.checkedOut
        ? roomReservationStatuses?.checkedOut?.filter((a: number) => {
              if (roomInfoIds.includes(a)) {
                  return a;
              } else {
                  return null;
              }
          })
        : [];
    const realDueOutRooms = dueOutRooms?.filter((r: number) => {
        if (!arrivalRooms.includes(r)) {
            return r;
        } else {
            return null;
        }
    });
    const realCheckedOutRooms = checkedOutRooms?.filter((r: number) => {
        if (!arrivalRooms.includes(r)) {
            return r;
        } else {
            return null;
        }
    });
    const stayRooms = roomReservationStatuses?.stay
        ? roomReservationStatuses?.stay?.filter((a: number) => {
              if (roomInfoIds.includes(a)) {
                  return a;
              } else {
                  return null;
              }
          })
        : [];

    const roomsByTaskPriority = arrivalRooms.concat(realCheckedOutRooms, realDueOutRooms, stayRooms);
    return roomsByTaskPriority;
};

export const getFilteredTasks = (filter: string, taskItems: TaskManagementItemModel[]) => {
    let newTaskItems: TaskManagementItemModel[] = [...taskItems];
    switch (filter) {
        case 'unassigned':
            newTaskItems = taskItems?.filter((t) => {
                if (!t.userAssigned) {
                    return t;
                } else {
                    return null;
                }
            });
            break;
        case 'assigned':
            newTaskItems = taskItems?.filter((t) => {
                if (t.userAssigned) {
                    return t;
                } else {
                    return null;
                }
            });
            break;
        case 'pending':
            newTaskItems = taskItems?.filter((t) => {
                if (t.startTimestamp && t.startTimestamp < new Date().getTime() && !t.endTimestamp) {
                    return t;
                } else {
                    return null;
                }
            });
            break;
        case 'postponed':
            newTaskItems = taskItems?.filter((t) => {
                if (
                    (t.startTimestamp && t.startTimestamp > new Date().getTime()) ||
                    t.taskStatus === TaskStatusEnum.taskPostponed
                ) {
                    return t;
                } else {
                    return null;
                }
            });
            break;
        case 'finished':
            newTaskItems = taskItems?.filter((t) => {
                if (t.userFinished || t.taskStatus === TaskStatusEnum.taskFinished) {
                    return t;
                } else {
                    return null;
                }
            });
            break;
        default:
            break;
    }
    return newTaskItems;
};

export const clearTaskItemsFromMaid = (
    taskItems: TaskManagementItemModel[],
    targetTask: ChangeTasksRoom | TaskManagementItemModel | null
) => {
    const newTaskItems: TaskManagementItemModel[] = taskItems?.map((task) => {
        if (
            (targetTask &&
                !targetTask.id &&
                task.roomInfoId === targetTask.roomInfoId &&
                task.userAssigned === targetTask.userAssigned &&
                task.taskGroup === targetTask?.taskGroup) ||
            (targetTask && targetTask.id && targetTask.id === task.id)
        ) {
            const newTask = { ...task };
            newTask.userAssigned = null;
            // newTask.taskStatus = TaskStatusEnum.noActionTaken;

            return newTask;
        } else {
            return task;
        }
    });
    return newTaskItems;
};

export const assignTaskItemsToMaid = (
    taskItems: TaskManagementItemModel[],
    targetTask: ChangeTasksRoom | TaskManagementItemModel | null,
    userUuid: string
) => {
    const newTaskItems = taskItems?.map((task) => {
        if (
            (targetTask &&
                !targetTask.id &&
                task.roomInfoId === targetTask.roomInfoId &&
                task.userAssigned === targetTask.userAssigned &&
                task.taskGroup === targetTask?.taskGroup) ||
            (targetTask && targetTask.id && targetTask.id === task.id)
        ) {
            const newTask = { ...task };

            newTask.userAssigned = userUuid;
            newTask.userSrcEncoded = 0;
            // newTask.taskStatus = TaskStatusEnum.noActionTaken;
            // newTask.taskStatus = TaskStatusEnum.stagedForTask;
            return newTask;
        } else {
            return task;
        }
    });
    return newTaskItems;
};

export const findBestMatches = (targetTask: TaskManagementItemModel, comparisonTasks: TaskManagementItemModel[]) => {
    const matches: TaskManagementItemModel[] = [];

    for (const item of comparisonTasks) {
        const title1 = targetTask.taskTitle ? targetTask.taskTitle.toLowerCase() : '';
        const title2 = item.taskTitle ? item.taskTitle.toLowerCase() : '';

        const taskStatus = item.taskStatus;

        // Check if taskStatus is "taskFinished" and skip if it is
        if (taskStatus === TaskStatusEnum.taskFinished) {
            continue;
        }

        // Check if no words match and skip if that's the case
        const titleWords1 = title1.split(' ');
        const titleWords2 = title2.split(' ');
        const commonWords = titleWords1?.filter((word) => titleWords2.includes(word));
        if (commonWords.length === 0) {
            continue;
        }

        // Calculate the score for title based on the number of common words
        const titleScore = (commonWords.length / Math.max(titleWords1.length, titleWords2.length)) * 100;

        // Calculate the score for title

        // Calculate the score for roomInfoId (you can use any similarity metric you prefer)

        // Combine scores for title and roomInfoId
        const score = titleScore;
        const newItem: any = { ...item };
        newItem.score = score;
        matches.push(newItem);
    }

    // Filter out matches where no words match and where taskStatus is "taskFinished"
    const filteredMatches = matches?.filter((match: any) => match.score > 0);

    // Sort matches by descending score
    filteredMatches.sort((a: any, b: any) => {
        if (a.score !== b.score) {
            return b.score - a.score;
        } else if (a.roomInfoId === targetTask.roomInfoId) {
            return -1; // A should come first
        } else if (b.roomInfoId === targetTask.roomInfoId) {
            return 1; // B should come first
        } else {
            return a.roomInfoId - b.roomInfoId; // Sort by roomInfoId otherwise
        }
    });

    return filteredMatches.slice(0, 5)?.map((match) => {
        const newMatc: any = { ...match };
        delete newMatc.score;
        return newMatc;
    });
};

export async function getRequiresCleaningRooms(
    taskHeader: TaskManagementHeaderEditModel,
    roomReservationStatusesForPeriod:any,
    taskType: string,
    includeEmptyRooms: boolean,
    roomsFilter: string,
    selectedTag: string,
    rooms: RoomInfoEditModel[],
    selectedLastCleaningDate: string | null,
    availableRoomsBySelectedLastCleaningDate: number[],
    searchTerm: string,
    householdStaff: UserInfo[]
): Promise<GetRequiresCleaningRooms> {
    const assigments: Assigments = {
        selectedMaids: [],
        roomInfoIds: [],
        taskHeaderId: null,
        roomReservationStatuses: null,
    };
    const initialTaskType = taskType.length === 0 ? 'cleaningTask' : taskType;

    // const householdStaff: UserInfo[] = await getHouseholdStaff();
    const maids: UserInfo[] = householdStaff?.filter((hs) => hs.role === 'maid');
    const availableRooms = getAvailableRoomsFilter(
        rooms,
        roomsFilter,
        selectedTag,
        roomReservationStatusesForPeriod,
        selectedLastCleaningDate,
        availableRoomsBySelectedLastCleaningDate,
        searchTerm
    );

    const roomInfoIds = availableRooms?.map((ar) => {
        return ar.id;
    });
    const selectedMaids: MaidInterface[] = [];

    for (const maid of maids) {
        const newMaidInterface: MaidInterface = {
            uuid: maid.uuid,
            workingHours: maid.workingHours ? Number(maid.workingHours) : 8,
        };
        selectedMaids.push(newMaidInterface);
    }

    const _assigments = { ...assigments };

    _assigments.selectedMaids = selectedMaids;
    _assigments.roomInfoIds = roomInfoIds;
    _assigments.taskHeaderId = taskHeader?.id;
    _assigments.roomReservationStatuses = roomReservationStatusesForPeriod;
    const requiresCleaningRooms = await _getRequiresCleaningRooms(
        _assigments,
        initialTaskType,
        includeEmptyRooms,
        'assigments'
    );

    return requiresCleaningRooms;
}

export const getAvailableRoomsFilter = (
    rooms: RoomInfoEditModel[],
    roomsFilter: string,
    selectedTag: string,
    roomReservationStatusesForPeriod: any,
    selectedLastCleaningDate: string | null,
    availableRoomsBySelectedLastCleaningDate: number[],
    searchTerm: string
) => {
    let availableRooms = rooms ? [...rooms] : [];
    if (selectedTag && selectedTag.length > 0) {
        availableRooms = availableRooms?.filter((r) => {
            if (r.tags && r.tags.includes(selectedTag)) {
                return r;
            } else {
                return null;
            }
        });
    }
    if (selectedLastCleaningDate && selectedLastCleaningDate.length > 0) {
        availableRooms = availableRooms?.filter((r) => {
            if (availableRoomsBySelectedLastCleaningDate && availableRoomsBySelectedLastCleaningDate.includes(r.id)) {
                return r;
            } else {
                return null;
            }
        });
    }

    if (searchTerm && searchTerm.length > 0) {
        availableRooms = availableRooms?.filter((r) => {
            if (r.name && r.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                return r;
            } else {
                return null;
            }
        });
    }

    const roomFilterIds: number[] = [];
    const roomReservationStatusArray: RoomAndReservation[] =
        roomReservationStatusesForPeriod && roomReservationStatusesForPeriod[roomsFilter] && roomsFilter.length > 0
            ? roomReservationStatusesForPeriod[roomsFilter]
            : [];
    for (const roomReservationStatus of roomReservationStatusArray) {
        roomFilterIds.push(roomReservationStatus.roomInfoId);
    }

    if (roomFilterIds && roomFilterIds.length > 0) {
        availableRooms = availableRooms?.filter((r) => {
            if (roomFilterIds.includes(r.id)) {
                return r;
            }
            return null;
        });
    } else if (roomsFilter === 'emptyRooms') {
        const roomsWithStatuses: number[] = [];

        ['arrival', 'checkedIn', 'dueOut', 'checkedOut', 'stay'].forEach((_status: string) => {
            const roomReservationStatusArray: RoomAndReservation[] =
                roomReservationStatusesForPeriod && roomReservationStatusesForPeriod[_status]
                    ? roomReservationStatusesForPeriod[_status]
                    : [];
            for (const roomReservationStatus of roomReservationStatusArray) {
                roomsWithStatuses.push(roomReservationStatus.roomInfoId);
            }
        });
        availableRooms = availableRooms?.filter((a) => {
            if (!roomsWithStatuses.includes(a.id)) {
                return a;
            } else {
                return null;
            }
        });
    } else if (roomFilterIds.length === 0 && roomsFilter.length > 0 && roomsFilter !== 'emptyRooms') {
        availableRooms = [];
    }
    return availableRooms;
};

export const getGroupedTasksByMaidsAndRoomInfo = (
    taskItems: TaskManagementItemModel[],
    sugestedTaskitems: TaskManagementItemModel[],
    roomsFilter: string,
    selectedTag: string,
    roomReservationStatusesForPeriod: any,
    availableRoomsByTag: RoomInfoEditModel[],
    requiresCleaningTaskItems: TaskManagementItemModel[],
    availableRoomsBySelectedLastCleaningDate: number[],
    searchTerm: string,
    taskType: string
) => {
    const createdAndSugestedTaskItems: TaskManagementItemModel[] = taskItems
        ?.filter(
            (t) => (t.taskGroup === 'cleaningTask' || t.taskGroup === 'inspectionTask') && !t.parentId && t.userAssigned
        )
        .concat(sugestedTaskitems, requiresCleaningTaskItems);

    const sortedTasks = createdAndSugestedTaskItems.sort((a, b) =>
        a.userAssigned === null ? -1 : b.userAssigned === null ? 1 : a.userAssigned.localeCompare(b.userAssigned)
    );
    const groupedTasksByMaidsAndRoomInfo: Record<
        string,
        Record<number, TaskManagementItemModel[]>
    > = sortedTasks.reduce((groupedByUser: any, task: TaskManagementItemModel) => {
        const { userAssigned, roomInfoId } = task;

        let showRoom = true;
        if (roomsFilter.length > 0) {
            const filteredRoomsAndReservations = roomReservationStatusesForPeriod[roomsFilter]
                ? roomReservationStatusesForPeriod[roomsFilter]
                : [];
            if (filteredRoomsAndReservations.length === 0) {
                if (roomsFilter !== 'emptyRooms') {
                    showRoom = false;
                } else {
                    const roomsWithStatuses: number[] = [];
                    ['arrival', 'checkedIn', 'dueOut', 'checkedOut', 'stay'].forEach((_status: string) => {
                        const roomReservationStatusArray: RoomAndReservation[] =
                            roomReservationStatusesForPeriod && roomReservationStatusesForPeriod[_status]
                                ? roomReservationStatusesForPeriod[_status]
                                : [];
                        for (const roomReservationStatus of roomReservationStatusArray) {
                            roomsWithStatuses.push(roomReservationStatus.roomInfoId);
                        }
                    });
                    if (roomInfoId && roomsWithStatuses.includes(roomInfoId)) {
                        showRoom = false;
                    }
                }
            }
            const filteredRooms: number[] = [];
            filteredRoomsAndReservations.forEach((rr: RoomAndReservation) => {
                filteredRooms.push(rr.roomInfoId);
            });
            if (filteredRooms.length > 0 && !filteredRooms.includes(Number(roomInfoId))) {
                showRoom = false;
            }
        }
        if (selectedTag.length > 0) {
            const findRoom = availableRoomsByTag?.find((r: RoomInfoEditModel) => r.id === Number(roomInfoId));
            if (!findRoom) {
                showRoom = false;
            }
        }

        if (availableRoomsBySelectedLastCleaningDate) {
            const findRoom = availableRoomsBySelectedLastCleaningDate.includes(Number(roomInfoId));
            if (!findRoom) {
                showRoom = false;
            }
        }
        if (searchTerm && searchTerm.length > 0) {
            const findRoom = availableRoomsByTag?.find(
                (r: RoomInfoEditModel) =>
                    r.name && r.name.toLowerCase().includes(searchTerm.toLowerCase()) && r.id === Number(roomInfoId)
            );

            if (!findRoom) {
                showRoom = false;
            }
        }

        const _userAssinged = userAssigned ? userAssigned : 'null';

        if (_userAssinged && roomInfoId && showRoom && task.taskGroup === taskType) {
            if (!groupedByUser[_userAssinged]) {
                groupedByUser[_userAssinged] = {};
            }

            if (!groupedByUser[_userAssinged][roomInfoId]) {
                groupedByUser[_userAssinged][roomInfoId] = [];
            }

            groupedByUser[_userAssinged][roomInfoId].push(task);
        }
        return groupedByUser;
    }, {});
    return groupedTasksByMaidsAndRoomInfo;
};

export const getGroupedTasksByMaidsAndRoomInfoWithoutFilter = (
    createdAndSugestedTaskItems: TaskManagementItemModel[]
) => {
    const sortedTasks = createdAndSugestedTaskItems.sort((a, b) =>
        a.userAssigned === null ? 1 : b.userAssigned === null ? -1 : a.userAssigned.localeCompare(b.userAssigned)
    );
    const groupedTasksByMaidsAndRoomInfo: Record<
        string,
        Record<number, TaskManagementItemModel[]>
    > = sortedTasks.reduce((groupedByUser: any, task: TaskManagementItemModel) => {
        const { userAssigned, roomInfoId } = task;
        if (userAssigned && roomInfoId) {
            if (!groupedByUser[userAssigned]) {
                groupedByUser[userAssigned] = {};
            }

            if (!groupedByUser[userAssigned][roomInfoId]) {
                groupedByUser[userAssigned][roomInfoId] = [];
            }

            groupedByUser[userAssigned][roomInfoId].push(task);
        }
        return groupedByUser;
    }, {});
    return groupedTasksByMaidsAndRoomInfo;
};

export const getTotalTaskTimeGroupedByMaidUuid = (
    maids: UserInfo[],
    createdAndSugestedTaskItems: TaskManagementItemModel[],
    groupedTasksByMaidsAndRoomInfo: any,
    taskGroup: string
) => {
    const result: any = {};

    maids.forEach((maid) => {
        const groupByRoomInfoId = groupedTasksByMaidsAndRoomInfo[maid.uuid];

        let totalTasksTime = 0;

        if (groupByRoomInfoId) {
            Object.keys(groupByRoomInfoId).forEach((roomInfoId) => {
                const taskIds = groupByRoomInfoId[roomInfoId]
                    ?.filter((r: TaskManagementItemModel) => r.taskGroup === taskGroup)
                    ?.map((t: TaskManagementItemModel) => {
                        return t.id;
                    });
                const tasks: TaskManagementItemModel[] = groupByRoomInfoId[roomInfoId]?.filter(
                    (cr: TaskManagementItemModel) => cr.taskGroup === taskGroup && !cr.parentId
                );

                const otherUserTasks = createdAndSugestedTaskItems?.filter((f) => {
                    for (const task of tasks) {
                        const targetSameRoomUser: boolean =
                            task.taskManagementHeaderId === f.taskManagementHeaderId &&
                            task.roomInfoId === f.roomInfoId &&
                            task.userAssigned !== f.userAssigned &&
                            task.id !== f.id &&
                            task.roomStatuses === f.roomStatuses &&
                            !f.parentId &&
                            f.taskGroup === taskGroup;
                        if (targetSameRoomUser) {
                            return f;
                        } else {
                            return null;
                        }
                    }
                    return null;
                });
                otherUserTasks.forEach((out) => {
                    taskIds.push(out.id);
                });

                createdAndSugestedTaskItems?.forEach((task) => {
                    if (
                        (taskIds.includes(task.parentId) ||
                            (task.userAssigned === maid.uuid && task.taskGroup === taskGroup)) &&
                        task.taskTimeEstimate &&
                        task.roomInfoId === Number(roomInfoId)
                    ) {
                        totalTasksTime += Number(task.taskTimeEstimate);
                    }
                });
            });
        }

        result[maid.uuid] = totalTasksTime;
    });

    return result;
};

export const getGroupedTasksByTaskTitle = (taskItems: TaskManagementItemModel[]) => {
    const groupedTasks = taskItems?.reduce((result: any, task: TaskManagementItemModel) => {
        const taskTitle = task.taskTitle;
        if (taskTitle) {
            if (!result[taskTitle]) {
                result[taskTitle] = [];
            }
            result[taskTitle].push(task);
        }
        return result;
    }, {});
    return groupedTasks;
};

export const getGroupedTasksByRoomInfo = (taskItems: TaskManagementItemModel[]) => {
    const groupedTasks = taskItems?.reduce((result: any, task: TaskManagementItemModel) => {
        const roomInfoId = task.roomInfoId;
        if (roomInfoId) {
            if (!result[roomInfoId]) {
                result[roomInfoId] = [];
            }
            result[roomInfoId].push(task);
        }
        return result;
    }, {});
    return groupedTasks;
};

export const getTasksByGroup = (
    taskItems: TaskManagementItemModel[],
    taskGroup: string,
    taskHeaderId?: number | null
) => {
    const taskIds: number[] = [];

    const filteredTasks = taskItems
        ? taskItems
              ?.filter((task) => {
                  if (task.taskGroup === taskGroup && task.id) {
                      taskIds.push(task.id);
                      return true;
                  } else {
                      return false;
                  }
              })
              .concat(
                  taskItems?.filter(
                      (task) => task.taskGroup === taskGroup && task.parentId && taskIds.includes(task.parentId)
                  )
              )
        : [];
    const finalResult = taskHeaderId
        ? filteredTasks?.filter((ts) => ts.taskManagementHeaderId === taskHeaderId)
        : filteredTasks;

    return finalResult;
};

export const getPosItemsGroupedBySkuId = (posItems: any[]) => {
    const groupedPosItems = posItems.reduce((result: any, posItem: any) => {
        const skuId = posItem.skuId;
        if (skuId) {
            if (!result[skuId]) {
                result[skuId] = [];
            }
            result[skuId].push(posItem);
        }
        return result;
    }, {});
    return groupedPosItems;
};

export const getFilteredStockLogsByRoomInfoId = (stockLogs: StockLogModel[], roomInfoId?: number) => {
    const filteredStockLogs = stockLogs?.filter((sl) => {
        if (sl.roomInfoId && Number(sl.roomInfoId) === roomInfoId) {
            return sl;
        } else {
            return null;
        }
    });
    return filteredStockLogs;
};

export const getGroupedTasksByJanitorsAndRoomInfo = (taskItems: TaskManagementItemModel[]) => {
    const sortedTasks = taskItems?.sort((a, b) =>
        a.userAssigned === null ? 1 : b.userAssigned === null ? -1 : a.userAssigned.localeCompare(b.userAssigned)
    );

    const groupedTasksByJanitorsAndRoomInfo: Record<
        string,
        Record<number, TaskManagementItemModel[]>
    > = sortedTasks.reduce((groupedByUser: any, task: TaskManagementItemModel) => {
        const { userAssigned, roomInfoId } = task;

        if (userAssigned && roomInfoId) {
            if (!groupedByUser[userAssigned]) {
                groupedByUser[userAssigned] = {};
            }

            if (!groupedByUser[userAssigned][roomInfoId]) {
                groupedByUser[userAssigned][roomInfoId] = [];
            }

            groupedByUser[userAssigned][roomInfoId].push(task);
        }
        return groupedByUser;
    }, {});
    return groupedTasksByJanitorsAndRoomInfo;
};

export const getTotalTaskTimeGroupedByJanitorUuid = (
    janitors: UserInfo[],
    taskItems: TaskManagementItemModel[],
    groupedTasksByJanitorsAndRoomInfo: any
) => {
    const result: any = {};

    janitors.forEach((janitor) => {
        const groupByRoomInfoId = groupedTasksByJanitorsAndRoomInfo[janitor.uuid];

        let totalTasksTime = 0;

        if (groupByRoomInfoId) {
            Object.keys(groupByRoomInfoId).forEach((roomInfoId) => {
                const tasks = groupByRoomInfoId[roomInfoId]
                    ?.filter((r: TaskManagementItemModel) => r.taskGroup === 'maintainanceTask')
                    ?.map((t: TaskManagementItemModel) => {
                        return t.id;
                    });

                    taskItems && taskItems.forEach((task) => {
                    if (
                        (tasks.includes(task.parentId) || task.userAssigned === janitor.uuid) &&
                        task.taskTimeEstimate &&
                        task.roomInfoId === Number(roomInfoId)
                    ) {
                        totalTasksTime += Number(task.taskTimeEstimate);
                    }
                });
            });
        }
        result[janitor.uuid] = totalTasksTime;
    });

    return result;
};

export const getGroupedLastCleaneadTimesByRoomInfo = (lastCleaneadTimes: LastCleaneadTimes[]) => {
    const groupedLastCleaneadTimes = lastCleaneadTimes.reduce((result: any, cleanTimes: LastCleaneadTimes) => {
        const roomInfoId = cleanTimes.roomInfoId;
        if (roomInfoId) {
            if (!result[roomInfoId]) {
                result[roomInfoId] = cleanTimes.endTimestamp;
            }
        }
        return result;
    }, {});
    return groupedLastCleaneadTimes;
};

export const getGroupedLastCleaneadTimesByDate = (lastCleaneadTimes: LastCleaneadTimes[]) => {
    const sortedLastCleaneadTimes = lastCleaneadTimes.sort((a, b) =>
        a.endTimestamp === null ? -1 : b.endTimestamp === null ? 1 : a.endTimestamp - b.endTimestamp
    );
    const groupedLastCleaneadTimes = sortedLastCleaneadTimes.reduce((result: any, cleanTimes: LastCleaneadTimes) => {
        const endTimestamp = cleanTimes.endTimestamp;
        const roomInfoId = cleanTimes.roomInfoId;

        if (roomInfoId) {
            const date = endTimestamp ? new Date(endTimestamp) : null;
            const dateString = date ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` : 'never';

            if (!result[dateString]) {
                result[dateString] = [roomInfoId];
            } else {
                result[dateString].push(roomInfoId);
            }
        }

        return result;
    }, {});

    return groupedLastCleaneadTimes;
};

export const getGroupedReservationIdsByRoomInfoId = (roomsWithReservations: any[]) => {
    const groupedTasks = roomsWithReservations.reduce((result: any, roomReservation: any) => {
        const roomInfoId = roomReservation.roomInfoId;
        if (roomInfoId) {
            if (!result[roomInfoId]) {
                result[roomInfoId] = roomReservation.reservationId;
            } else {
                result[roomInfoId] = result[roomInfoId] + `;${roomReservation.reservationId}`;
            }
        }
        return result;
    }, {});
    return groupedTasks;
};

export const getGroupedRoomsByBedsCount = (roomInfos: RoomInfoEditModel[]) => {
    const groupedRooms = roomInfos.reduce((result: any, room: RoomInfoEditModel) => {
        const bedCount = room.bedCount;
        if (bedCount) {
            if (!result[bedCount]) {
                result[bedCount] = [room];
            } else {
                result[bedCount].push(room);
            }
        }
        return result;
    }, {});
    return groupedRooms;
};

export const getGroupedHouseholdRefillsByBedsCount = (householdRefillSetups: HouseholdRefillSetupModel[]) => {
    const groupedRooms = householdRefillSetups.reduce(
        (result: any, householdRefillSetup: HouseholdRefillSetupModel) => {
            const roomType = householdRefillSetup.roomType;
            if (roomType) {
                if (!result[roomType]) {
                    result[roomType] = [householdRefillSetup];
                } else {
                    result[roomType].push(householdRefillSetup);
                }
            }
            return result;
        },
        {}
    );
    return groupedRooms;
};

export const getGroupedHouseholdRefillsByBedsCountAndGuests = (householdRefillSetups: HouseholdRefillSetupModel[]) => {
    const groupedRooms = householdRefillSetups?.reduce(
        (result: any, householdRefillSetup: HouseholdRefillSetupModel) => {
            const guestsNumber = householdRefillSetup.guestsNumber;
            if (guestsNumber) {
                if (!result[guestsNumber]) {
                    result[guestsNumber] = [householdRefillSetup];
                } else {
                    result[guestsNumber].push(householdRefillSetup);
                }
            }
            return result;
        },
        {}
    );
    return groupedRooms;
};

export const getWarehouseItemsGroupedByPosItemId = (warehouseItems: WarehouseItemModel[]) => {
    const groupedWarehouseItems = warehouseItems.reduce((result: any, warehouseItem: any) => {
        const posItemId = warehouseItem.posItemId;
        if (posItemId) {
            if (!result[posItemId]) {
                result[posItemId] = [];
            }
            result[posItemId].push(warehouseItem);
        }
        return result;
    }, {});
    return groupedWarehouseItems;
};

export const getGroupedHouseholdRefillSetupsByRoomStatus = (householdRefillSetups: HouseholdRefillSetupModel[]) => {
    const groupedRooms = householdRefillSetups?.reduce(
        (result: any, householdRefillSetup: HouseholdRefillSetupModel) => {
            const reservationStatus = householdRefillSetup.reservationStatus;
            if (reservationStatus) {
                if (!result[reservationStatus]) {
                    result[reservationStatus] = [householdRefillSetup];
                } else {
                    result[reservationStatus].push(householdRefillSetup);
                }
            }
            return result;
        },
        {}
    );
    return groupedRooms;
};

export const getGroupedReservationsById = (reservations: ReservationEditModel[]) => {
    const groupedReservationsById = reservations?.reduce((result: any, reservation: ReservationEditModel) => {
        const id = reservation.id;
        if (id) {
            if (!result[id]) {
                result[id] = [reservation];
            } else {
                result[id].push(reservation);
            }
        }
        return result;
    }, {});
    return groupedReservationsById;
};
export const getGroupedWarehouseStockBySkuId = (warehouseStocks: WarehouseStock[]) => {
    const groupedWarehouseStocks = warehouseStocks?.reduce((result: any, warehouseStock: WarehouseStock) => {
        const skuId = warehouseStock.skuId;
        if (skuId) {
            if (!result[skuId]) {
                result[skuId] = [warehouseStock];
            } else {
                result[skuId].push(warehouseStock);
            }
        }
        return result;
    }, {});
    return groupedWarehouseStocks;
};
export const getGroupedWarehouseStockLogsBySkuId = (warehouseStockLogs: StockLogModel[]) => {
    const groupedWarehouseStocks = warehouseStockLogs?.reduce((result: any, warehouseStockLog: StockLogModel) => {
        const skuId = warehouseStockLog.skuId;
        if (skuId) {
            if (!result[skuId]) {
                result[skuId] = [warehouseStockLog];
            } else {
                result[skuId].push(warehouseStockLog);
            }
        }
        return result;
    }, {});
    return groupedWarehouseStocks;
};


export const getGroupedWarehouseItemsByWarehouseId = (warehouseItems: WarehouseItemModel[], minibarOnly?:boolean) => {
    const groupedWarehouseItems = warehouseItems?.reduce((result: any, warehouseItem: WarehouseItemModel) => {
        const warehouseId = warehouseItem.warehouseId;
        if(minibarOnly){
            if (warehouseId && warehouseItem.type===WarehouseItemType.Minibar) {
                if (!result[warehouseId]) {
                    result[warehouseId] = [warehouseItem];
                } else {
                    result[warehouseId].push(warehouseItem);
                }
            }
        }else{
            if (warehouseId) {
                if (!result[warehouseId]) {
                    result[warehouseId] = [warehouseItem];
                } else {
                    result[warehouseId].push(warehouseItem);
                }
            } 
        }
   
        return result;
    }, {});
    return groupedWarehouseItems;
};

export const getWarehouseStockLogsGroupedByDateAndPosItemId = (stockLogs: StockLogModel[]) => {
    const groupedWarehouseStockLogs = stockLogs?.reduce((result: any, stockLog: StockLogModel) => {
        const posItemId = stockLog ? stockLog.posItemId : null;
        const dateTime = stockLog.dateTime ? Math.ceil(stockLog.dateTime / 2000) : null;

        if (posItemId && dateTime) {
            if (!result[dateTime]) {
                result[dateTime] = {};
            }
            if (!result[dateTime][posItemId]) {
                result[dateTime][posItemId] = [stockLog];
            } else {
                result[dateTime][posItemId].push(stockLog);
            }
        }
        return result;
    }, {});
    return groupedWarehouseStockLogs;
};

export const getWarehouseStockLogsGroupedByPosItemId = (stockLogs: StockLogModel[]) => {
    const groupedWarehouseStockLogs = stockLogs?.reduce((result: any, stockLog: StockLogModel) => {
        const posItemId = stockLog ? stockLog.posItemId : null;

        if (posItemId) {
            if (!result[posItemId]) {
                result[posItemId] = [stockLog];
            } else {
                result[posItemId].push(stockLog);
            }
        }
        return result;
    }, {});
    return groupedWarehouseStockLogs;
};

export const getMergedStockLog = (stockLogs: StockLogModel[]) => {
    if (!stockLogs) {
        return null;
    }

    const stockLogTemplate: StockLogModel = {
        quantity: 0,
        dateTime: stockLogs[0]?.dateTime,
        warehouseId: stockLogs[0]?.warehouseId,
        skuId: stockLogs[0]?.skuId,
        stockDocHeaderId: null,
        type: stockLogs[0]?.type,
        invoiceId: null,
        params: null,
        receivingWarehouseId: stockLogs[0]?.receivingWarehouseId,
        userCreated: stockLogs[0]?.userCreated,
        posItemId: stockLogs[0]?.posItemId,
        multiplier: stockLogs[0]?.multiplier,
        skuPluRatesCount: stockLogs[0]?.skuPluRatesCount,
        autoSuggestWriteOff: false,
    };
    stockLogs.forEach((stockLog) => {
        const multiplier = stockLog.multiplier ? stockLog.multiplier : 1;
        const skuPluRatesCount = stockLog.skuPluRatesCount ? stockLog.skuPluRatesCount : 1;

        stockLogTemplate.quantity += Number(stockLog.quantity) / multiplier / skuPluRatesCount;
    });

    stockLogTemplate.quantity = Number(parseFloat(stockLogTemplate.quantity.toString()).toFixed(4));
    // stockLogTemplate.quantity = Number(stockLogTemplate.quantity)/stockLogs.length

    return stockLogTemplate;
};

export const getMergedStockItemsQuantity = (stockItems: StockDocItemModel[], targetSkuInformation: any) => {
    if (!stockItems) {
        return 0;
    }
    let quantity = 0;

    stockItems.forEach((stockItem) => {
        const multiplier = targetSkuInformation
            ? targetSkuInformation.multiplier
                ? targetSkuInformation.multiplier
                : 1
            : 1;
        const skuPluRatesCount = targetSkuInformation
            ? targetSkuInformation.skuPluRatesCount
                ? targetSkuInformation.skuPluRatesCount
                : 1
            : 1;

        quantity += Number(stockItem.quantity) / multiplier / skuPluRatesCount;
    });

    quantity = Number(parseFloat(quantity.toString()).toFixed(4));
    // stockLogTemplate.quantity = Number(stockLogTemplate.quantity)/stockLogs.length

    return quantity;
};

export const getGroupedStocklogsByDateTime = (stockLogs: StockLogModel[]) => {
    const groupedStocklogsByDateTime = stockLogs?.reduce((result: any, stockLog: StockLogModel) => {
        const dateTime = stockLog.dateTime ? Math.ceil(stockLog.dateTime / 2000) : null;
        if (dateTime) {
            if (!result[dateTime]) {
                result[dateTime] = [stockLog];
            } else {
                result[dateTime].push(stockLog);
            }
        }
        return result;
    }, {});
    return groupedStocklogsByDateTime;
};

export const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
};

export const getGroupedStockItems = (stockItems: StockDocItemModel[]) => {
    const groupedWarehouseStockItems = stockItems?.reduce((result: any, stockItem: StockDocItemModel) => {
        const skuId = stockItem.skuId ? stockItem.skuId : null;

        if (skuId) {
            if (!result[skuId]) {
                result[skuId] = [stockItem];
            } else {
                result[skuId].push(stockItem);
            }
        }
        return result;
    }, {});
    return groupedWarehouseStockItems;
};
export const getGroupedSkuPluRates = (skuPluRates: SkuPluRateWithItem[]) => {
    const groupeSkuPluRates = skuPluRates?.reduce((result: any, skuPluRate: SkuPluRateWithItem) => {
        const posItemId = skuPluRate.posItemId ? skuPluRate.posItemId : null;

        if (posItemId) {
            if (!result[posItemId]) {
                result[posItemId] = [skuPluRate];
            } else {
                result[posItemId].push(skuPluRate);
            }
        }
        return result;
    }, {});
    return groupeSkuPluRates;
};

export const getGroupedSkuPluRatesBySkuId = (skuPluRates: SkuPluRateWithItem[]) => {
    const groupeSkuPluRates = skuPluRates?.reduce((result: any, skuPluRate: SkuPluRateWithItem) => {
        const skuId = skuPluRate.skuId ? skuPluRate.skuId : null;

        if (skuId) {
            if (!result[skuId]) {
                result[skuId] = [skuPluRate];
            } else {
                result[skuId].push(skuPluRate);
            }
        }
        return result;
    }, {});
    return groupeSkuPluRates;
};

export const getGroupedWarehouseItemsByType = (warehouseItems: WarehouseItemModel[]) => {
    const groupedWarehouseItems = warehouseItems?.reduce((result: any, warehouseItem: WarehouseItemModel) => {
        const type = warehouseItem.type;
        if (!result[type]) {
            result[type] = [warehouseItem];
        } else {
            result[type].push(warehouseItem);
        }
        return result;
    }, {});
    return groupedWarehouseItems;
};
export const getWarehouseStockItemsGroupedBySkuId = (stockDocItems: StockDocItemModel[]) => {
    const groupedWarehouseStockItems = stockDocItems?.reduce((result: any, stockDocItem: StockDocItemModel) => {
        const skuId = stockDocItem ? stockDocItem.skuId : null;

        if (skuId) {
            if (!result[skuId]) {
                result[skuId] = [stockDocItem];
            } else {
                result[skuId].push(stockDocItem);
            }
        }
        return result;
    }, {});
    return groupedWarehouseStockItems;
};

export const getPosItemsGroupedByPosItemId = (posItems: any[]) => {
    const groupedPosItems = posItems.reduce((result: any, posItem: any) => {
        const id = posItem.id;
        if (id) {
            if (!result[id]) {
                result[id] = [];
            }
            result[id].push(posItem);
        }
        return result;
    }, {});
    return groupedPosItems;
};
export const getGroupedWarehousesById = (warehouses: WarehouseModel[]) => {
    const groupedWarehouses = warehouses.reduce((result: any, warehouse: any) => {
        const id = warehouse.id;
        if (id) {
            if (!result[id]) {
                result[id] = [];
            }
            result[id].push(warehouse);
        }
        return result;
    }, {});
    return groupedWarehouses;
};
