import React from 'react';
import { ReactComponent as Dashboard } from '../../assets/custom/icons/dashboard.svg';
import { ReactComponent as TickInsideCircle } from '../../assets/custom/icons/tick_inside_circle.svg';
import { ReactComponent as Exit } from '../../assets/custom/icons/ex.svg';
import { ReactComponent as SignIn } from '../../assets/custom/icons/sign_in.svg';
import useStore from '@data/state/zustand';
import translate from '@data/translations';

interface ToolbarProps {
    showComponents: any;
    setSearchTerm: Function;
}

function Toolbar(props: ToolbarProps) {
    const toggleSidebarButtons = (sidebarType: string) => {
        const uiStates = useStore.getState().uiStates.dashboard.showComponents;
        let temp = uiStates ? [...uiStates] : [];

        temp = temp?.filter(
            (t) => t === sidebarType || t === 'timeline' || t === 'tiles' || t === 'activity' || t === 'journal'
        );
        temp.push(sidebarType);

        useStore.getState().setNestedProperty(['uiStates', 'dashboard', 'showComponents'], temp);
    };

    let sidebarButtonCheckedRule = 'sidebar_dailyInfo';
    if (props.showComponents.includes('sidebar_dailyInfo')) {
        sidebarButtonCheckedRule = 'sidebar_dailyInfo';
    }
    if (props.showComponents.includes('sidebar_incomming')) {
        sidebarButtonCheckedRule = 'sidebar_incomming';
    }
    if (props.showComponents.includes('sidebar_checkedIn')) {
        sidebarButtonCheckedRule = 'sidebar_checkedIn';
    }
    if (props.showComponents.includes('sidebar_canceled')) {
        sidebarButtonCheckedRule = 'sidebar_canceled';
    }

    return (
        <div className="">
            <div style={{ display: 'flex' }} className="pointer" aria-label="Sidebar filter">
                <div
                    style={{ flex: 1 }}
                    title={translate('Daily info')}
                    onClick={()=>toggleSidebarButtons('sidebar_dailyInfo')}
                    className={
                        sidebarButtonCheckedRule === 'sidebar_dailyInfo'
                            ? ' button-sidebar-dashboard-w-icon-only text-center'
                            : 'text-center'
                    }
                >
                    <Dashboard />
                </div>
                <div
                    style={{ flex: 1 }}
                    title={translate('Incomming reservations')}
                    onClick={()=>toggleSidebarButtons('sidebar_incomming')}
                    className={
                        sidebarButtonCheckedRule === 'sidebar_incomming'
                            ? ' button-sidebar-w-icon-only text-center'
                            : 'text-center'
                    }
                >
                    <TickInsideCircle />
                </div>
                <div
                    style={{ flex: 1 }}
                    title={translate('Checked in reservations')}
                    onClick={()=>toggleSidebarButtons('sidebar_checkedIn')}
                    className={
                        sidebarButtonCheckedRule === 'sidebar_checkedIn'
                            ? ' button-sidebar-w-icon-only text-center'
                            : 'text-center'
                    }
                >
                    <SignIn />
                </div>
                <div
                    style={{ flex: 1 }}
                    title={translate('Canceled reservations')}
                    onClick={()=>toggleSidebarButtons('sidebar_canceled')}
                    className={
                        sidebarButtonCheckedRule === 'sidebar_canceled'
                            ? ' button-sidebar-w-icon-only text-center'
                            : 'text-center'
                    }
                >
                    <Exit />
                </div>
            </div>
        </div>
    );
}

export default Toolbar;
