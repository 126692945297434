import React from 'react';
import useStore from '@data/state/zustand';

export interface DocumentProps {
    children?: any;
    setHeaderHeight?: Function;
    setFooterHeight?: Function;
}

const DocumentFooter = (props: DocumentProps) => {
    const { setFooterHeight, children } = props;
    const [style, setStyle] = React.useState({} as any);
    const imgRef = React.useRef(null);

    const otherSettings = useStore((appState: any) => appState.otherSettings);

    const getImageUrlFromDocumentFooterInfo = React.useCallback(() => {
        const documentFooterInfo = otherSettings?.documentFooter || '';
        const regex = /<img\s+[^>]*src="([^"]*)"/;
        const match = documentFooterInfo.match(regex);
        return match ? match[1] : '';
    }, [otherSettings]);

    React.useEffect(() => {
        const loadImage = new Image();
        const data = getImageUrlFromDocumentFooterInfo();
        if (data === '') {
            if (setFooterHeight) {
                setFooterHeight(60); // Prenesite visinu slike natrag u roditeljsku komponentu
                setStyle({ marginBottom: '12px' });
            }
        } else {
            loadImage.src = getImageUrlFromDocumentFooterInfo();
            loadImage.onload = () => {
                const coefficient = loadImage.width / 900;
                const height = loadImage.height / coefficient;
                if (setFooterHeight) {
                    setFooterHeight(height); // Prenesite visinu slike natrag u roditeljsku komponentu
                }
            };
        }
    }, [setFooterHeight, getImageUrlFromDocumentFooterInfo]);

    let documentFooterInfo = otherSettings && otherSettings.documentFooter ? otherSettings.documentFooter : '';

    return (
        <div className="text-center border-top" style={style}>
            <small>
                <small>
                    <small dangerouslySetInnerHTML={{ __html: documentFooterInfo }}></small>
                </small>
            </small>
            {children}
            <img ref={imgRef} src={getImageUrlFromDocumentFooterInfo()} alt="" style={{ display: 'none' }} />
        </div>
    );
};

export default DocumentFooter;
