import React, { useEffect } from 'react';
import pubsub from '../data/pubsub';
import { toast } from 'react-toastify';
// @ts-ignore
import _ from 'lodash';
import { showSRCAlarmToast } from '../data/SRCAlarmAndAlertController';
import useStore from '../data/state/zustand';
import { RoomInfoEditModel, UserInfo } from '@common/modelDefinition';

const BackendUIResponder = () => {
    const rooms: RoomInfoEditModel[] = useStore((appState: any) => appState.model.RoomInfo);
    const otherSettings = useStore((appState: any) => appState.otherSettings);
    const loggedUser: UserInfo = useStore((appState: any) => appState.authTokenInfo?.user);

    const toastMessage = (message: any, type: any, autoClose = 3000) => {
        let toastConfig = {};
        switch (type) {
            case 'error':
                toastConfig = {
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose,
                };
                break;
            default:
                toastConfig = {
                    type: toast.TYPE.INFO,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose,
                };
                break;
        }
        toast(message, toastConfig);
    };

    const socketUIHandlers = (payload: any) => {
        if (loggedUser?.role && !otherSettings?.rolesToNotShowSystemMessages?.includes(loggedUser.role)) {
            if (payload.model === 'ToastMessage') {
                let message = '';
                let toastConfig = payload.entity.toastConfig
                    ? payload.entity.toastConfig
                    : {
                          type: toast.TYPE.INFO,
                          position: toast.POSITION.BOTTOM_RIGHT,
                          autoClose: 3000,
                      };
                if (_.isString(payload.entity)) {
                    message = payload.entity;
                } else {
                    message = payload.entity.message;
                }
                toast(message, toastConfig); //console.log("ToastMessage", payload);
            } else if (payload.model === 'RoomEventTicket') {
                const ticket = payload.entity;
                const roomInfo = rooms?.find((r: any) => r.id === ticket.roomInfoId);
                showSRCAlarmToast(roomInfo, ticket);
            }
        }
    };

    useEffect(() => {
        pubsub.on('socketPayload', socketUIHandlers);
        pubsub.on('toastMessage', toastMessage);

        return () => {
            pubsub.removeListener('socketPayload', socketUIHandlers);
            pubsub.removeListener('toastMessage', toastMessage);
        };
        // eslint-disable-next-line
    }, [rooms.length]);

    return <div></div>;
};

export default BackendUIResponder;
