import React from 'react';
import { DocumentProps } from './DocumentFooter';
import useStore from '@data/state/zustand';

const DocumentHeader = (props: DocumentProps) => {
    const { setHeaderHeight } = props;
    const [style, setStyle] = React.useState({} as any);

    const imgRef = React.useRef(null);

    const otherSettings = useStore((appState: any) => appState.otherSettings);

    const getImageUrlFromDocumentHeaderInfo = React.useCallback(() => {
        const documentHeaderInfo = otherSettings?.documentHeader || '';
        const regex = /<img\s+[^>]*src="([^"]*)"/;

        const match = documentHeaderInfo.match(regex);
        if (match) {
            return match[1];
        } else {
            return '';
        }
    }, [otherSettings]);

    React.useEffect(() => {
        const loadImage = new Image();
        const data = getImageUrlFromDocumentHeaderInfo();
        if (data === '') {
            if (setHeaderHeight) {
                setHeaderHeight(100); // Prenesite visinu slike natrag u roditeljsku komponentu
                setStyle({ marginTop: '12px' });
            }
        } else {
            loadImage.src = getImageUrlFromDocumentHeaderInfo();
            loadImage.onload = () => {
                const coefficient = loadImage.width / 900;
                const height = loadImage.height / coefficient;
                if (setHeaderHeight) {
                    setHeaderHeight(height); // Prenesite visinu slike natrag u roditeljsku komponentu
                }
            };
        }
    }, [setHeaderHeight, getImageUrlFromDocumentHeaderInfo]);

    let documentHeaderInfo = otherSettings && otherSettings.documentHeader ? otherSettings.documentHeader : '';
    return (
        <div className="text-center" style={style}>
            <small>
                <small>
                    <small dangerouslySetInnerHTML={{ __html: documentHeaderInfo }}></small>
                </small>
            </small>
            {props.children}
            {/* Sakrijemo ovu sliku koristeći stil display: 'none', ali se učitava i izmjerava visina */}
            <img ref={imgRef} src={getImageUrlFromDocumentHeaderInfo()} alt="" style={{ display: 'none' }} />
        </div>
    );
};

export default DocumentHeader;
