import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// One of "default", "success", "info", "warning", "error". You can use
class Toaster extends Component {
    render() {
        return <ToastContainer />;
    }
}

export default Toaster;
