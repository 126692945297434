import React from 'react';
import './globalLoading.css';
import useStore from '@data/state/zustand';

var timeoutHolder: any = null;

function GlobalLoadingComponent() {
    const [actionTimeoutNotification, setActionTimeoutNotification] = React.useState(false);

    const translate: any = (window as any).translate;

    const loading: number = useStore((appState: any) => appState.state.globalLoading);

    if (loading === 0) {
        clearTimeout(timeoutHolder);
    } else {
        clearTimeout(timeoutHolder);
        timeoutHolder = setTimeout(() => {
            setActionTimeoutNotification(true);
        }, 5000);
    }

    if (loading > 0) {
        return (
            <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                className="loader-cover-spin"
            >
                {actionTimeoutNotification && (
                    <div
                        className="border rounded shadow bg-white p-4"
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <div className="text-secondary p-2 text-center">
                            {translate('reloadPageNote1')} <br />
                            {translate('reloadPageNote2')} <br />
                            {translate('reloadPageNote3')}
                        </div>

                        <div className="text-center mt-3">
                            <button
                                onClick={() => {
                                    setActionTimeoutNotification(false);
                                    window.location.reload();
                                }}
                                className="btn btn-outline-secondary p-2 "
                            >
                                {translate('Reload page')}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    } else {
        return null;
    }
}

export function startGlobalLoading() {
    const _globalLoading: number = useStore.getState().state.globalLoading;
    useStore.getState().setNestedProperty(['state', 'globalLoading'], _globalLoading + 1);
}

export function stopGlobalLoading() {
    const _globalLoading: number = useStore.getState().state.globalLoading;
    useStore.getState().setNestedProperty(['state', 'globalLoading'], _globalLoading - 1 < 0 ? 0 : _globalLoading - 1 );
}

const GlobalLoading = GlobalLoadingComponent;
export { GlobalLoading };
