import * as React from 'react';
import { translate } from '@data/translations';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
import { ReservationModel } from '@common/modelDefinition';
import { Reservation, ReservationController } from '../resController';

interface OverlappingReservationsProps {
    reservation: Reservation;
}

const OverlappingReservations = (props: OverlappingReservationsProps) => {
    const { reservation } = props;
    const ctrl = new ReservationController();
    const { overlaps } = ctrl.getReservationValidationReport(reservation);

    if (overlaps.length === 0) {
        return null;
    }

    return (
        <div className="text-center mt-8p mb-8p">
            <div className="bg-color-warning">
                <b>{translate('POSSIBLE CONFLICTS')}</b>
            </div>
            {overlaps.map((r: ReservationModel, index: number) => {
                return (
                    <div className="custom-list-item p-1" style={{ margin: 0, backgroundColor: '#fed4ae' }} key={index}>
                        {/* <div className="">#{r.id} </div> */}
                        <div className="">
                            #{r.id} <b className="pl-8p">{translate('Check in')}:</b>&ensp;
                            {moment(r.checkInTimestamp).format('LLL')}{' '}
                        </div>
                        <div className="">
                            <b>{translate('Check out')}:</b> &ensp;
                            {moment(r.checkOutTimestamp).format('LLL')}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default OverlappingReservations;
