import * as React from 'react';
import { translate } from '@data/translations';
import { ReservationController, Reservation } from '../resController';
import { ReservationStatus, MinibarLogWithIncludedModels, RoomInfoEditModel, InvoiceModelWithInvoiceItems } from '@common/modelDefinition';
import FeatureFlag from '@components/FeatureFlag';
import { setModalContent } from '@components/modal';
import Delete from '@components/confirmModal';
import { getMinibarData, MinibarLogsGroupByDate } from '@pages/householdNew/minibar/utils';
import { ConsumptionInvoices } from './posTerminalConsumption';
import useStore from '@data/state/zustand';

interface MinibarConsumptionProps {
    reservation: Reservation;
    history: any;
    roomInfoId?: number | null;
    match?: any;
}

interface ConsumptionLogProps {
    logs: MinibarLogsGroupByDate;
    defCurrency: string | null;
    deleteMinibarData: any;
    showRes: boolean | null;
    message?: string | null;
}

function MinibarConsumption(props: MinibarConsumptionProps) {
    const { reservation, history } = props;
    const ctrl = new ReservationController();
    const minibarData = getMinibarData(reservation?.MinibarLogs ? reservation?.MinibarLogs : []);
    const minibarProformaInvoices: InvoiceModelWithInvoiceItems[] = reservation?.Invoices ? reservation?.Invoices?.filter((inv: any) => inv.terminalType === 'minibar') : [];
    const roomInfoId = props?.match?.params?.roomInfoId
        ? Number(props.match.params.roomInfoId)
        : Number(props.roomInfoId);

    const defaultCurrency: string = useStore((appState: any) => appState.defaultCurrency);
    const rooms: RoomInfoEditModel[] = useStore((appState: any) => appState.model.RoomInfo);

    const reservationRoomInfoId = reservation ? reservation.roomInfoId : roomInfoId;
    const room = rooms?.find((r: RoomInfoEditModel) => r.id === reservationRoomInfoId);

    const deleteMinibarData = (item: MinibarLogWithIncludedModels) => {
        setModalContent(
            <Delete actionForYes={() => ctrl.deleteMinibarDataLog(item)} />,
            translate('Delete ') + translate('minibar data'),
            false,
            'modal-md'
        );
    };

    const showCreateMinibarProformaInvoice = () => {
        setModalContent(
            <Delete
                content="Are you sure you want create proforma?"
                actionForYes={async () => {
                    if (reservation.id) {
                        await ctrl.createMinibarProformaInvoice(reservation.id);
                    }
                }}
            />,
            translate('Confirm'),
            false,
            'modal-md'
        );
    };

    if (reservation.id === null || !reservation.id) {
        return null;
    }
    const message =
        reservation.statusEnum !== ReservationStatus.checkedIn
            ? translate('Reservation is not checked in or closed.')
            : room && room.posItemIds === null
            ? translate('First add items in room minibar.')
            : null;

    return (
        <FeatureFlag flag="minibar">
            <div className="">
                <div>
                    {room && props.history.location.pathname !== '/household/minibar/' + room.id ? (
                        <div className="display-flex mt-4p">
                            <button
                                onClick={() => {
                                    props.history.push('/household/minibar/' + room.id + '?resId=' + reservation.id);
                                }}
                                className="button-info mr-2"
                                disabled={
                                    (reservation.statusEnum !== ReservationStatus.checkedIn &&
                                        reservation.statusEnum !== ReservationStatus.closed)  //dozvoliti button 'Add' u formi rezervacije za dodavanje iz minibara kada je ReservationStatus=closed
                                }
                            >
                                <i className="fa fa-plus mr-4p" /> {translate('ADD')}
                            </button>
                            <button
                                onClick={() => {
                                    showCreateMinibarProformaInvoice();
                                }}
                                className="button-primary mr-2"
                                disabled={!minibarData || Object.keys(minibarData).length === 0}
                            >
                                <i className="fa fa-plus mr-4p" /> {translate('Create proforma')}
                            </button>
                        </div>
                    ) : null}

                    <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
                        {' '}
                        <ConsumptionInvoices
                            history={history}
                            reservation={reservation}
                            consumptionInvoices={minibarProformaInvoices}
                        />
                        <ConsumptionLog
                            logs={minibarData}
                            defCurrency={defaultCurrency}
                            deleteMinibarData={deleteMinibarData}
                            showRes={false}
                            message={message}
                        />
                    </div>
                </div>
            </div>
        </FeatureFlag>
    );
}

const ConsumptionLog = (props: ConsumptionLogProps) => {
    const { logs, defCurrency = null, deleteMinibarData, showRes = false, message } = props;

    if (!logs || Object.keys(logs).length === 0) {
        return <small>{message ? translate(message) : translate('No recorded minibar consumption')}</small>;
    }
    return (
        <div className="mt-2">
            {(Object.keys(logs) as Array<string>).map((date: string) => {
                let dailyTotal = 0;
                logs[date].forEach((l: MinibarLogWithIncludedModels) => {
                    const count = l.previousState - l.newState;
                    const posItemPrice = l.PosItem && l.PosItem.price ? l.PosItem.price : 0;
                    const total = posItemPrice * count;
                    dailyTotal = dailyTotal + total;
                });
                return (
                    <div key={date}>
                        <div className="display-flex" style={{ flexDirection: 'row-reverse' }}>
                            {date}
                        </div>
                        <div className="">
                            <div className="custom-list-header" style={{ width: '100%', margin: 0, padding: 0 }}>
                                <div>{translate('ITEM NAME')}</div>
                                <div>{translate('COUNT')}</div>
                                <div>{translate('PRICE')}</div>
                                <div>{translate('TOTAL')}</div>
                                {showRes ? <div>{translate('R.ID')}</div> : null}
                                {<div></div>}
                            </div>
                            {logs[date].map((log: MinibarLogWithIncludedModels) => {
                                const count = log.previousState - log.newState;
                                const posItemName = log.PosItem ? log.PosItem.name : '';
                                const posItemPrice = log.PosItem && log.PosItem.price ? log.PosItem.price : 0;
                                const total = posItemPrice * count;
                                return (
                                    <div
                                        key={log.id}
                                        className="custom-list-item"
                                        style={{ width: '100%', margin: 0, padding: 0 }}
                                    >
                                        <div className="text-bold">{posItemName}</div>
                                        <div>{count}</div>
                                        <div>
                                            {posItemPrice} {defCurrency}
                                        </div>
                                        <div>
                                            {total} {defCurrency}
                                        </div>
                                        {showRes ? <div>{log.reservationId ? log.reservationId : ''}</div> : null}
                                        {log.logType === 0 ? null : showRes ? (
                                            <div>
                                                <button
                                                    className="button-secondary mr-4p ml-auto"
                                                    onClick={()=>deleteMinibarData(log)}
                                                >
                                                    <i className="fa fa-trash" />
                                                </button>
                                            </div>
                                        ) : (
                                            <div>
                                                <button
                                                    className="button-secondary ml-auto mr-4p"
                                                    onClick={()=>deleteMinibarData(log)}
                                                >
                                                    <i className="fa fa-trash" />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        <br />
                        {showRes === false ? (
                            <div className="display-flex" style={{ flexDirection: 'row-reverse' }}>
                                <h6>
                                    <small>
                                        {translate('Daily total')}: {dailyTotal} {defCurrency}
                                    </small>
                                </h6>
                            </div>
                        ) : null}

                        <br />
                    </div>
                );
            })}
        </div>
    );
};

export { ConsumptionLog };

export default MinibarConsumption;
