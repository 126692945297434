import React from 'react';
import { ReactComponent as Search } from '../../assets/custom/icons/search.svg';
import CustomInput from '../../components/custom/input';
import translate from '@data/translations';

interface SearchFieldProps {
    history: any;
    onChangeHandler?: Function;
}

const SearchField = (props: SearchFieldProps) => {
    const onSearch = async (e: any) => {
        if (e.key === 'Enter') {
            props.history.push('/search?' + e.target.value);
        }
    };

    return (
        <CustomInput
            onInputKeyPress={(e:any)=>onSearch(e)}
            onInputChange={(e: any) => {
                if (props.onChangeHandler) {
                    props.onChangeHandler(e);
                }
            }}
            icon={<Search />}
            placeholder={translate('Search for a customer or company name')}
        />
    );
};

export default SearchField;
