import React from 'react';
import InlineModal from '@components/modalInline';
import { translate } from '@data/translations';

interface RouteLeavingGuardprops {
    modalVisible: boolean;
    setModalVisible: Function;
    toggleSelectRoomForReservation: Function;
    message: string | null;
}

function RouteLeavingGuard(props: RouteLeavingGuardprops) {
    const { modalVisible, setModalVisible, toggleSelectRoomForReservation, message } = props;

    return (
        <React.Fragment>
            <InlineModal show={modalVisible} header={translate('Warning')} setShow={setModalVisible}>
                {modalVisible ? <div className="text-center">
                    <div className="p-2">{translate(message)}</div>
                    <div className="flex-center-center mt-20p mb-20p">
                        <button
                            className="button-secondary mr-8p"
                            onClick={(e) => {
                                setModalVisible(false);
                            }}
                        >
                            {translate('Cancel')}
                        </button>
                        <button
                            className="button-primary ml-8p"
                            onClick={(e) => {
                                setModalVisible(false);
                                toggleSelectRoomForReservation(null);
                            }}
                        >
                            {translate('Leave anyway')}
                        </button>
                    </div>
                </div> : null}
            </InlineModal>
        </React.Fragment>
    );
}

export default RouteLeavingGuard;
