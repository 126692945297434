import * as React from 'react';
import { translate } from '@data/translations';
import { Reservation } from '../resController';
import { TaskManagementItemModel } from '@common/modelDefinition';

import { taskTemplateObject } from '@pages/householdNew/taskTemplates';
import { createReservationTaskItem, getTaskItemsByReservationId } from '@pages/newReservations/api';

interface HouseholdTaskProps {
    reservation: Reservation;
    history: any;
    roomInfoId?: number | null;
}

function HouseholdTask(props: HouseholdTaskProps) {
    const { reservation } = props;
    const [newTask, setNewTask] = React.useState<TaskManagementItemModel | null>(null);
    const [reservationTaskItems, setReservationTaskItems] = React.useState<TaskManagementItemModel[]>([]);

    React.useEffect(() => {
        const loadTaskiItems = async () => {
            if (reservation && reservation.id && !newTask) {
                const _reservationTaskItems = await getTaskItemsByReservationId(reservation.id);
                setReservationTaskItems(_reservationTaskItems);
            }
        };
        loadTaskiItems().catch((err) => {
            console.log(err);
        });
    }, [reservation, newTask]);
    return (
        <div className="">
            {newTask ? (
                <div className="mt-16p">
                    <div className="mr-8p">
                        <label>
                            <b>{translate('Title')}</b>
                        </label>
                        <input
                            autoFocus
                            type="text"
                            onChange={(e) => {
                                if (newTask) {
                                    const newCreateNewTask: TaskManagementItemModel = { ...newTask };
                                    newCreateNewTask.taskTitle = e.target.value;
                                    setNewTask(newCreateNewTask);
                                }
                            }}
                            value={newTask?.taskTitle ? newTask.taskTitle : ''}
                            className="form-control"
                        />
                    </div>

                    <div className="mt-12p mr-8p">
                        <label>
                            <b>{translate('Message')}</b>
                        </label>
                        <textarea
                            onChange={(e) => {
                                if (newTask) {
                                    const newCreateNewTask: TaskManagementItemModel = { ...newTask };
                                    newCreateNewTask.taskContent = e.target.value;
                                    setNewTask(newCreateNewTask);
                                }
                            }}
                            value={newTask?.taskContent ? newTask.taskContent : ''}
                            className="form-control"
                        />
                    </div>

                    <hr />
                    <div className="mt-16p display-flex justify-content-center">
                        <button
                            disabled={false}
                            className="button-primary mr-8p"
                            onClick={async () => {
                                if (newTask && reservation.id && reservation.roomInfoId) {
                                    const _newTask = { ...newTask };
                                    _newTask.reservationId = reservation.id;
                                    _newTask.roomInfoId = reservation.roomInfoId;
                                    _newTask.taskGroup = 'reservationTask';
                                    _newTask.userCreated = null; // ovdje
                                    await createReservationTaskItem(_newTask, reservation);
                                    setNewTask(null);
                                }
                            }}
                        >
                            {translate('Save')}
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="display-flex">
                        <div
                            onClick={() => {
                                setNewTask(taskTemplateObject);
                            }}
                            className="button-primary mt-16p"
                        >
                            {translate('New task')}
                        </div>
                    </div>
                    <div className="mt-16p color-sucess">
                        {reservationTaskItems.map((t) => {
                            if (t.taskGroup === 'reservationTask') {
                                return (
                                    <div key={t.id} className="custom-list-item" id="no-hover">
                                        {t.taskTitle}
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

export default HouseholdTask;
