//import _ from "lodash"
import EventEmitter from 'events';
import io from 'socket.io-client';
import baobabFunnel, { reloadModelData } from './socketBaobabFunnel';
import pubsub from '../pubsub';
import { handleSRCSettingsPayload } from './SRCServerStatesLogic';
import useStore from '@data/state/zustand';

class SocketClient extends EventEmitter {
    initSocket(authToken) {
        this.disconectedMessageTimeout = null;
        this.connectionLossConfirmed = false;
        let socketNamespaceAndParams = '/?token=' + authToken;
        this.socket = io(socketNamespaceAndParams, {
            //transports: ['websocket'],
            reconnection: true,
            reconnectionDelay: 5000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 200,
        });

        this.socket.on('connect', () => {
            this.emit('socket_connected');
            this.subscribeToPublicChannel();
            clearTimeout(this.disconectedMessageTimeout);
            this.connectionLossConfirmed = false;
        });

        const setNestedProperty = useStore.getState().setNestedProperty
        
        this.socket.on('disconnect', () => {
            this.disconectedMessageTimeout = setTimeout(() => {
                this.connectionLossConfirmed = true;
                // setNestedProperty(["socketConnection", {showConnectingScreen:true,connectingMessage:"DISCONNECTED", heading:"" }])
                setNestedProperty(["socketConnection"], {showConnectingScreen:true,connectingMessage:"DISCONNECTED", heading:"" })

            }, 10000);
        });
        this.socket.on('reconnect', (attempt) => {
            this.connectionLossConfirmed = false;
            // setNestedProperty(["socketConnection", {showConnectingScreen:false,connectingMessage:"", heading:"" }])
            setNestedProperty(["socketConnection"], {showConnectingScreen:false,connectingMessage:"", heading:"" })

        });
        this.socket.on('reconnecting', (attemptCount) => {
            if (this.connectionLossConfirmed) {
            // setNestedProperty(["socketConnection", {showConnectingScreen:rue,connectingMessage:"RECONNECTING", heading:'(attempt ' + attemptCount + ')' }])
            setNestedProperty(["socketConnection"], {showConnectingScreen:true,connectingMessage:"RECONNECTING", heading:'(attempt ' + attemptCount + ')' })


            }
        });
        this.socket.on('connect_error', (err) => {});
        this.socket.on('reconnect_attempt', () => {
        });
        this.socket.on('reconnect_failed', () => {
            // setNestedProperty(["socketConnection", { showConnectingScreen: true, connectingMessage: "RECONNECTING FAILED", heading: '(refresh to try again)' }])
            setNestedProperty(["socketConnection"], { showConnectingScreen: true, connectingMessage: "RECONNECTING FAILED", heading: '(refresh to try again)' })


        });

        this.socket.on('subscribed', (socketId) => {
            console.log('pipe connected ...');
            // setNestedProperty(["socketConnection", { showConnectingScreen: false, connectingMessage: "", heading: '' }])
            setNestedProperty(["socketConnection"], { showConnectingScreen: false, connectingMessage: "", heading: '' })

        });

        this.socket.on('leaved', (socketId) => {
            console.log('pipe disconnected ...');
            // setNestedProperty(["socketConnection", { showConnectingScreen: true, connectingMessage: "DISCONNECTED", heading: '' }])
            setNestedProperty(["socketConnection"], { showConnectingScreen: true, connectingMessage: "DISCONNECTED", heading: '' })


        });

        this.socket.on('message', (payload) => {
            //expected message payload object is: {entity, method, model, requestId}
            pubsub.emit('socketPayload', payload); //catch every special case on this one!!!!
            switch (payload.model) {
                case 'SRCInfo_virtualModel':
                    if (payload.entity.SRCSettings) {
                        handleSRCSettingsPayload(payload.entity);
                    }
                    break;
                case 'RELOAD_MODEL_DATA':
                    let modelName = payload.entity;
                    reloadModelData(modelName);
                    //reload model data
                    break;
                case 'SrcCardCodeCustom':
                    break;
                default:
                    baobabFunnel(payload.model, payload.method, payload.entity); //(modelName, method, payload)=>{
                    break;
            }
        });
    }

    subscribeToPublicChannel() {
        //messages on public channel - most commonly used....
        if (this.socket.connected) {
            this.socket.emit('subscribe', 'public');
        }
    }

    subscribeUser(uuid) {
        //messages for this user
        if (this.socket && this.socket.connected) {
            this.socket.emit('subscribe', uuid);
        }
    }
}

export default SocketClient;
