import translate from '@data/translations';
import React from 'react';

interface ConfirmButtonsProps {
    onConfirm: Function;
    onCancel: Function;
    confirmText?: string;
    cancelText?: string;
    disableConfirm?: boolean;
}

const ConfirmButtons = (props: ConfirmButtonsProps) => {
    const { onConfirm, onCancel, confirmText, cancelText, disableConfirm } = props;
    const [actionPending, setActionPending] = React.useState(false);
    const [actionPendingCancel, setActionPendingCancel] = React.useState(false);
    const isMounted = React.useRef(true);

    React.useEffect(() => {
        // Cleanup function to update the ref when the component unmounts
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <div className="flex-center-center">
            <button
                disabled={actionPendingCancel}
                onClick={async (e) => {
                    setActionPendingCancel(true);
                    await onCancel(e);
                    if (isMounted.current) {
                        setActionPendingCancel(false);
                    }
                }}
                className="button-secondary mr-8p"
            >
                {translate(cancelText ? cancelText : 'Cancel')}
            </button>
            <button
                disabled={actionPending || disableConfirm ? true : false}
                onClick={async (e) => {
                    setActionPending(true);
                    await onConfirm(e);
                    if (isMounted.current) {
                        setActionPending(false);
                    }
                }}
                className="button-primary mr-8p"
            >
                {actionPending ? (
                    <React.Fragment>
                        <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>{' '}
                        {translate('Processing...')}
                    </React.Fragment>
                ) : (
                    translate(confirmText ? confirmText : 'Confirm')
                )}
            </button>
        </div>
    );
};

export default ConfirmButtons;
