import translate from '@data/translations';
import React from 'react';
import moment from 'moment';
import { TaskManagementItemModel, UserInfo } from '@common/modelDefinition';
import { getTaskTemplates, createOrUpdateTaskItem, deleteTaskItem } from './api';
import InlineModal from '@components/modalInline';
import { ReactComponent as Edit } from '../../assets/custom/icons/edit.svg';
import { ReactComponent as Delete } from '../../assets/custom/icons/trash.svg';
import { ReactComponent as Add } from '../../assets/custom/icons/plus.svg';
import { Filters, filterListItems, ListTableHeader, FilteredColumns } from '@components/TableContextMenuWraper';
//@ts-ignore
import _ from 'lodash';
//@ts-ignore
import TimePicker from 'rc-time-picker';
import { CustomSelect, customStyles } from '@pages/dashboard/toolbar';
import { getTimeForTimePicker } from './controller';
import { RoomReservationStatusText } from './interfaces';
import PageTitle from '@components/pageTitle';
import useStore from '@data/state/zustand';

export const taskTemplateObject: TaskManagementItemModel = {
    taskContent: '',
    taskStatus: null,
    taskGroup: 'cleaningTaskTemplate',
    parentId: null,
    userArguments: null,
    roomInfoId: null,
    reservationIds: null,
    customerId: null,
    companyId: null,
    taskManagementHeaderId: null,
    userCreated: null,
    userFinished: null,
    taskTitle: '',
    taskTimeEstimate: null,
    userAssigned: null,
    inspectionFailedCount: 0,
    totalTaskDuration: 0,
    userSrcEncoded: 0,
    taskHistory: [],
    timestamp: new Date().getTime(),
    roomStatuses: null,
};

const headers = [
    {
        filterKey: 'taskTitle',
        headerTitle: translate('Title'),
        label: 'taskTitle',
        type: '',
    },

    {
        filterKey: 'roomStatuses',
        headerTitle: translate('Room Status'),
        label: `roomStatuses`,
        type: 'reservationStatusBadge',
    },
];

interface TaskTemplateProps {
    history: any;
}
const TaskTemplates = (props: TaskTemplateProps) => {
    const { history } = props;
    const [taskTemplate, setTaskTemplate] = React.useState<TaskManagementItemModel | null>(null);
    const [filters, setFilters] = React.useState([] as Filters[]);
    const [sort, setSort] = React.useState({} as any);
    const [showContextMenu, setShowContextMenu] = React.useState(null as string | null);
    const [taskTemplates, setTaskTemplates] = React.useState<TaskManagementItemModel[]>([]);

    const loggedUser: UserInfo = useStore((appState: any) => appState.authTokenInfo?.user);

    React.useEffect(() => {
        const loadData = async () => {
            const _taskTemplates = await getTaskTemplates();
            setTaskTemplates(_taskTemplates);
        };

        loadData().catch((err) => {
            console.log(err);
        });
    }, []);

    let _taskTemplates = [...taskTemplates];

    if (sort) {
        _.forIn(sort, (val: string, key: string) => {
            _taskTemplates = _.orderBy(_taskTemplates, [key], [val]);
        });
    }
    const filteredTaskTemplates = filterListItems(_taskTemplates, filters);

    return (
        <div className="">
            <div className="title-with-options display-flex space-between">
                <PageTitle />
                <div className="page-title-button display-flex-mobile"></div>
            </div>
            <InlineModal
                show={taskTemplate}
                header={translate(taskTemplate?.id ? taskTemplate?.taskTitle : 'New task template')}
                width="lg"
                setShow={setTaskTemplate}
            >
                {taskTemplate ? <div className="">
                    <GeneralTab taskTemplate={taskTemplate} setTaskTemplate={setTaskTemplate} />
                    <div className="mt-20p display-flex justify-content-center">
                        <button
                            disabled={
                                taskTemplate
                                    ? taskTemplate.taskTitle
                                        ? taskTemplate.taskTitle.length > 0
                                            ? false
                                            : true
                                        : true
                                    : true
                            }
                            className="button-primary mr-8p"
                            onClick={async () => {
                                if (taskTemplate && taskTemplate.taskTitle) {
                                    await createOrUpdateTaskItem(taskTemplate);
                                    const _taskTemplates = await getTaskTemplates();

                                    setTaskTemplates(_taskTemplates);
                                    setTaskTemplate(null);
                                }
                            }}
                        >
                            {translate('Save')}
                        </button>
                        <button
                            className="button-light mr-8p"
                            onClick={async () => {
                                setTaskTemplate(null);
                            }}
                        >
                            {translate('Close')}
                        </button>
                    </div>
                </div> : null}
            </InlineModal>
            <div className="">
                <div className="ml-16p">
                    <div className="flex-center-between">
                        <div className=""></div>
                        {loggedUser?.role === 'admin' ||
                        loggedUser?.role === 'managerHousehold' ||
                        loggedUser?.role === 'superAdmin' ? (
                            <div
                                className="button-white-w-icon-only mr-16p"
                                onClick={() => {
                                    const subTaskTemplate = { ...taskTemplateObject };
                                    subTaskTemplate.taskGroup = 'cleaningTaskTemplate';
                                    setTaskTemplate(subTaskTemplate);
                                }}
                            >
                                <Add />
                                <span>{translate('Add')}</span>
                            </div>
                        ) : null}
                    </div>
                    <ListTableHeader
                        sort={sort}
                        setSort={setSort}
                        headers={headers}
                        listArray={taskTemplates}
                        showContextMenu={showContextMenu}
                        setShowContextMenu={setShowContextMenu}
                        filters={filters}
                        setFilters={setFilters}
                        setPage={() => {}}
                        history={history}
                    >
                        <div></div>
                    </ListTableHeader>
                    {filteredTaskTemplates?.map((tt) => {
                        return (
                            <div id="no-hover" key={tt.id} className="custom-list-item">
                                <FilteredColumns headers={headers} listItem={tt} history={history} />
                                {loggedUser?.role === 'admin' ||
                                loggedUser?.role === 'managerHousehold' ||
                                loggedUser?.role === 'superAdmin' ? (
                                    <div className="display-flex">
                                        <div className="button-white-w-icon-only mr-8p">
                                            <Delete
                                                onClick={async () => {
                                                    if (tt.id) {
                                                        await deleteTaskItem(tt.id);
                                                        const _taskTemplates = await getTaskTemplates();
                                                        setTaskTemplates(_taskTemplates);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="button-white-w-icon-only mr-8p">
                                            <Edit
                                                onClick={() => {
                                                    setTaskTemplate(tt);
                                                }}
                                            />{' '}
                                        </div>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>

            <div>
                <div></div>
            </div>
        </div>
    );
};

interface GeneralTabProps {
    taskTemplate: TaskManagementItemModel | null;
    setTaskTemplate: Function;
}

interface SelectOptions {
    label: string;
    value: string;
}
export enum TaskTypeText {
    'cleaningTaskTemplate' = 'Cleaning',
    'inspectionTaskTemplate' = 'Inspection',
}

export const GeneralTab = (props: GeneralTabProps) => {
    const { taskTemplate, setTaskTemplate } = props;
    const roomStatusOptions = ['arrival',  'dueOut',  'stay', 'emptyRoom']?.map(
        (roomStatus: string) => {
            return {
                value: roomStatus,
                label: translate(RoomReservationStatusText[roomStatus as keyof typeof RoomReservationStatusText]),
            };
        }
    );

    const roomStatusValues: SelectOptions[] = [];
    const taskTemplateParams = taskTemplate?.roomStatuses?.split(';');
    taskTemplateParams?.forEach((roomStatus: string) => {
        const newValue = {
            value: roomStatus,
            label: translate(RoomReservationStatusText[roomStatus as keyof typeof RoomReservationStatusText]),
        };
        roomStatusValues.push(newValue);
    });

    const typeOptions = ['cleaningTaskTemplate', 'inspectionTaskTemplate']?.map((t) => {
        return {
            value: t,
            label: translate(TaskTypeText[t as keyof typeof TaskTypeText]),
        };
    });

    return (
        <div className="">
            <div className="mr-8p mt-8p">
                <label>
                    <b>{translate('Title')}</b>
                </label>
                <input
                    type="text"
                    onChange={(e) => {
                        if (taskTemplate) {
                            const newTaskTemplate: TaskManagementItemModel = { ...taskTemplate };
                            newTaskTemplate.taskTitle = e.target.value;
                            setTaskTemplate(newTaskTemplate);
                        }
                    }}
                    value={taskTemplate?.taskTitle ? taskTemplate.taskTitle : ''}
                    className="form-control"
                />
            </div>
            <div className="mr-8p mt-8p">
                <label>
                    <b>{translate('Type')}</b>
                </label>
                <CustomSelect
                    placeholder={translate('Type')}
                    styles={customStyles}
                    options={typeOptions}
                    onChange={(values: any) => {
                        if (values === null) {
                            const newTaskTemplate = { ...taskTemplate };
                            newTaskTemplate.taskGroup = '';
                            setTaskTemplate(newTaskTemplate);
                        } else {
                            const newTaskTemplate = { ...taskTemplate };

                            newTaskTemplate.taskGroup = values[values.length - 1].value;
                            setTaskTemplate(newTaskTemplate);
                        }
                    }}
                    value={
                        taskTemplate?.taskGroup !== ''
                            ? [
                                  {
                                      value: taskTemplate?.taskGroup,
                                      label: translate(
                                          TaskTypeText[taskTemplate?.taskGroup as keyof typeof TaskTypeText]
                                      ),
                                  },
                              ]
                            : []
                    }
                    width={"40%"}
                ></CustomSelect>
            </div>
            <div className="mr-8p mt-8p">
                <label>
                    <b className="mr-4p">{translate('Room status')}</b>-
                    <span className="ml-4p">{translate('multiple')}</span>
                </label>
                <div className="">
                    <CustomSelect
                        width="auto"
                        isMulti
                        placeholder={translate('By room status')}
                        styles={customStyles}
                        options={roomStatusOptions}
                        onChange={(values: any) => {
                            if (values === null) {
                                const newTaskTemplate = { ...taskTemplate };
                                newTaskTemplate.roomStatuses = null;
                                setTaskTemplate(newTaskTemplate);
                            } else {
                                const newTaskTemplate = { ...taskTemplate };
                                const newParams: string[] = [];
                                for (const value of values) {
                                    newParams.push(value.value);
                                }
                                newTaskTemplate.roomStatuses = newParams.join(';');
                                setTaskTemplate(newTaskTemplate);
                            }
                        }}
                        value={roomStatusValues}
                    ></CustomSelect>
                </div>
            </div>
            <div className="mr-8p mt-8p">
                <label>
                    <b>{translate('Task estimated time')}</b>
                </label>
                <div>
                    {' '}
                    <TimePicker
                        id="checkInTime"
                        allowEmpty={false}
                        showSecond={false}
                        minuteStep={1}
                        value={moment(
                            getTimeForTimePicker(taskTemplate?.taskTimeEstimate ? taskTemplate.taskTimeEstimate : 0.25)
                        )}
                        onChange={(e: any) => {
                            if (taskTemplate) {
                                const time = moment(e.valueOf()).format('HH:mm');
                                const timeArr = time.split(':');
                                const hour = Number(timeArr[0]);
                                const minutes = Number(timeArr[1]);

                                // Izračunajte ukupno vrijeme u satima
                                var _taskTimeEstimate = hour + minutes / 60;
                                const newTaskTemplate = { ...taskTemplate };
                                newTaskTemplate.taskTimeEstimate = _taskTimeEstimate;
                                setTaskTemplate(newTaskTemplate);
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default TaskTemplates;
