import React from 'react';
import ActiveReservations from './sidebarActiveReservations';
import { Reservation } from '@pages/newReservations/resController';
import { RoomInfoEditModel } from '@common/modelDefinition';

interface SidebarProps {
    children: any;
    setReservationId: Function;
    toggleSelectRoomForReservation: Function;
    reservations: Reservation[];
    availableToTimestamp: number | null;
    availableFromTimestamp: number;
    searchTerm: string;
    rooms: RoomInfoEditModel[];
    setReservationUuid: Function;
}

function Sidebar(props: SidebarProps) {
    return (
        <div id="activity-sidebar" className="p-2 bg-white rounded-sm shadow-sm">
            {props.children}
            <ActiveReservations {...props} />
        </div>
    );
}

export default Sidebar;
