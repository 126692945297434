import React from 'react';
import translate from '@data/translations';
import useStore from '@data/state/zustand';

const PermissionCheck = (props) => {
    const { name = 'permissionName', noPermision } = props;
    const permissions = useStore.getState().state.userPermissions;

    if (permissions?.features?.includes(name)) {
        return <React.Fragment>{props.children}</React.Fragment>;
    } else {
        return noPermision ? <div className="font-18p ml-24p mt-16p">{translate('No access.')}</div> : null;
    }
};

export default PermissionCheck;
