import React, { Component } from 'react';

class PageNotFoundScreen extends Component {
    render() {
        return (
            <div className="">
                {window.translate("PAGE NOT FOUND ERROR")}
                <br />
                {window.translate("PAGE NOT FOUND ERROR")}
                <br />
                {window.translate("PAGE NOT FOUND ERROR")}
                <br />
                {window.translate("PAGE NOT FOUND ERROR")}
                <br />
                {window.translate("PAGE NOT FOUND ERROR")}
                <br />
                {window.translate("PAGE NOT FOUND ERROR")}
                <br />
            </div>
        );
    }
}

export default PageNotFoundScreen;