import {
    InvoiceItemsModel,
    PriceOffer,
    PriceOfferInterval,
    ReservationEditModel,
    ReservationModel,
    ReservationModelWithModelsIncluded,
} from '@common/modelDefinition';
import { Reservation } from './resController';
import moment from 'moment';
//@ts-ignore
import _ from 'lodash';

export interface CondoReservationCheck {
    isReservationMadeByOwner: boolean;
    isReservationMadeForOwner: boolean;
    isCondoReservation: boolean;
}

export interface OfferMismatch {
    invoiceItems: InvoiceItemsModel[];
    proformaInvoiceItems: InvoiceItemsModel[];
    reservations: ReservationEditModel[];
}

export const _selfServiceCheckInTemplate = {
    pinCode: null,
    selfCheckInType: 'hardware', //hardware or sofware
    notificationSent: false,
    selfCheckInValidFrom: new Date().getTime(),
    selfCheckInValidTo: new Date().getTime(),
    sendSelfCheckInCodeAfterTimestamp: new Date().getTime(),
    notificationLanguage: '',
};

export const getHourAndMinuteObj = (time: string) => {
    const newTime = time ? time.split(':') : [12, 0]; //add default time if setting does not exist
    const hour = Number(newTime[0]);
    const minute = Number(newTime[1]);
    return { hour, minute };
};

export const getQuery = (location: Location) => {
    let query: any = {};
    location.search
        .replace('?', '')
        .split('&')
        .forEach((param) => {
            const temp = param.split('=');
            query[temp[0]] = temp[1];
        });
    return query;
};

export const getCheckInAndCheckoutTimestamps = (location: Location, otherSettings: any) => {
    const query = getQuery(location);
    const { checkInTime = '12:00', checkOutTime = '12:00' } = otherSettings;
    const checkInHourAndMinute = getHourAndMinuteObj(checkInTime);
    const checkOutHourAndMinute = getHourAndMinuteObj(checkOutTime);

    const defaultCheckIn = new Date(
        new Date().setHours(checkInHourAndMinute.hour, checkInHourAndMinute.minute)
    ).getTime();

    const defaultCheckOut =
        new Date(new Date().setHours(checkOutHourAndMinute.hour, checkOutHourAndMinute.minute)).getTime() +
        24 * 60 * 60 * 1000;

    const checkInTimestamp = query.from
        ? new Date(
              new Date(parseInt(query.from, 10)).setHours(checkInHourAndMinute.hour, checkInHourAndMinute.minute)
          ).getTime()
        : defaultCheckIn;

    const checkOutTimestamp = query.to
        ? new Date(
              new Date(parseInt(query.to, 10)).setHours(checkOutHourAndMinute.hour, checkOutHourAndMinute.minute)
          ).getTime()
        : defaultCheckOut;

    return { checkInTimestamp, checkOutTimestamp };
};

export const sanitizeReservationsObject = (res: ReservationModel) => {
    if (process.env.NODE_ENV === 'development') {
        console.warn('Note: Sanitizing reservation form');
    }

    const sanitizedReservationObject = {
        id: res.id,
        additionalServicesJson: res.additionalServicesJson,
        adultsNumber: res.adultsNumber,
        autoBlockCount: res.autoBlockCount,
        checkInTimestamp: res.checkInTimestamp,
        checkOutTimestamp: res.checkOutTimestamp,
        childrenNumber: res.childrenNumber,
        companyId: res.companyId,
        customerId: res.customerId,
        discountPercentage: res.discountPercentage,
        documents: res.documents,
        earlyBirdValidTo: res.earlyBirdValidTo,
        enableSelfCheckIn: res.enableSelfCheckIn,
        groupUuid: res.groupUuid,
        guest1CodeCopy: res.guest1CodeCopy,
        guest2CodeCopy: res.guest2CodeCopy,
        guest3CodeCopy: res.guest3CodeCopy,
        notes: res.notes,
        invoicedStatus: res.invoicedStatus,
        receptionistUuid: res.receptionistUuid,
        rememberedAccommodationPriceInfo: res.rememberedAccommodationPriceInfo,
        roomInfoId: res.roomInfoId,
        statusChangeNotes: res.statusChangeNotes,
        statusEnum: res.statusEnum,
        updatedAt: res.updatedAt,
        createdAt: res.createdAt,
        accommodationPriceListId: res.accommodationPriceListId,
        tags: res.tags,
        previousGroupUuid: res.previousGroupUuid,
        openPrice: res.openPrice,
        canceledReservationInvoicingStatus: res.canceledReservationInvoicingStatus,
    };

    return sanitizedReservationObject;
};

export const condoStatusCheck = (reservation: Reservation | ReservationEditModel): CondoReservationCheck => {
    if (reservation) {
        const _isReservationMadeByOwner =
            reservation.receptionistUuid &&
            reservation.condoUserUuid &&
            reservation.receptionistUuid === reservation.condoUserUuid;
        const _isReservationMadeForOwner =
            reservation.condoUserUuid &&
            reservation.receptionistUuid &&
            reservation.receptionistUuid !== reservation.condoUserUuid;
        const result: CondoReservationCheck = {
            isReservationMadeByOwner: _isReservationMadeByOwner ? _isReservationMadeByOwner : false,
            isReservationMadeForOwner: _isReservationMadeForOwner ? _isReservationMadeForOwner : false,
            isCondoReservation: _isReservationMadeByOwner === true || _isReservationMadeForOwner === true,
        };
        return result;
    } else {
        return {
            isReservationMadeByOwner: false,
            isReservationMadeForOwner: false,
            isCondoReservation: false,
        };
    }
};



export function checkOfferMismatch(params: OfferMismatch) {
    const { invoiceItems, proformaInvoiceItems, reservations } = params;
    let mismatchOffers = [];
    const accommodationInvoiceItems = invoiceItems.filter((i: InvoiceItemsModel) => i.type === 'accommodation');
    const accommodationProformaInvoiceItems = proformaInvoiceItems.filter(
        (i: InvoiceItemsModel) => i.type === 'accommodation'
    );

    if (
        _.sumBy(accommodationInvoiceItems, (i: InvoiceItemsModel) => {
            return Number(i.nettoValueWithDiscount);
        }) !==
        _.sumBy(accommodationProformaInvoiceItems, (i: InvoiceItemsModel) => {
            return Number(i.nettoValueWithDiscount);
        })
    ) {
        mismatchOffers.push('totalValue');
    }

    if (reservations.length > 0) {
        for (const reservation of reservations) {
            if (reservation && reservation.rememberedAccommodationPriceInfo) {
                const { rememberedAccommodationPriceInfo = [] } = reservation;
                const lastOffer =
                    rememberedAccommodationPriceInfo.length > 0
                        ? rememberedAccommodationPriceInfo[rememberedAccommodationPriceInfo.length - 1]
                        : null;
                if (lastOffer) {
                    const { checkInTimestamp, checkOutTimestamp } = lastOffer;
                    if (
                        moment(checkInTimestamp).isSame(checkOutTimestamp, 'day') &&
                        !moment(reservation.checkOutTimestamp).isSame(checkOutTimestamp, 'day')
                    ) {
                        mismatchOffers.push('refreshment stay detected');
                    }
                }
            }
        }
    }
    return mismatchOffers;
}

export function checkReservationFromGroupOfferMismatch(
    rememberedPriceOfferIntervals: PriceOfferInterval[],
    lastOffer: PriceOffer,
    reservation: ReservationModelWithModelsIncluded
) {
    const {
        accommodationPriceListId,
        checkInTimestamp,
        checkOutTimestamp,
        adultsNumber,
        childrenNumber,
        previousGroupUuid,
    } = reservation;

    const lastOfferIntervals = lastOffer.priceOfferIntervals;
    if (
        accommodationPriceListId !== lastOffer.accommodationPriceListId ||
        checkInTimestamp !== lastOffer.checkInTimestamp ||
        checkOutTimestamp !== lastOffer.checkOutTimestamp ||
        adultsNumber !== lastOffer.adultsNumber ||
        childrenNumber !== lastOffer.childrenNumber ||
        previousGroupUuid ||
        _.sumBy(rememberedPriceOfferIntervals, (priceOfferInterval: PriceOfferInterval) => {
            return _.sumBy(priceOfferInterval.apRules, (apRules: number) => {
                return Number(apRules);
            });
        }) !==
            _.sumBy(lastOfferIntervals, (priceOfferInterval: PriceOfferInterval) => {
                return _.sumBy(priceOfferInterval.apRules, (apRules: number) => {
                    return Number(apRules);
                });
            })
    ) {
        return true;
    } else {
        return false;
    }
}
