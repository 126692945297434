import {
    WarehouseStock,
    PosItemModel,
    SkuPLURateModel,
    StockDocHeadersExtended,
    StockDocHeadersWithItems,
    StockDocItemModel,
    StockKeepingUnitModel,
    WarehouseModel,
    EntryCalcHeaderExtended,
    EntryCalcHeadersWithItems,
    EntryCalculationItemModel,
    StockDocHeaderModel,
    EntryCalculationHeaderModel,
    SkuPluRateWithItem,
    InventoryWithExistingAndOtherItems,
    SupplierPriceListsExtended,
    SupplierPriceListToSheet,
    SkuSupplierPriceListPerHeaderModel,
    SkuSupplierPriceListPerHeaderEditModel,
    SkuItemSupplierCodeWithCompanyAndSkuItem,
    StockDocHeaderEditModel,
    PosTerminalEditModel,
    StockKeepingUnitEditModel,
    PriceChangeLogsHeadersExtended,
    PriceChangeLogHeaderWithItems,
    PriceChangeLogHeaderModel,
    PriceChangeLogItemModel,
    PaginatedData,
} from '@common/modelDefinition';
import rest from '@data/restWrapper';
import useStore from '@data/state/zustand';

export const getSKUItems = async (
    page: number,
    rows: number,
    onlyActive?: boolean
): Promise<PaginatedData> => {
    return onlyActive
        ? await rest('/getStockKeepingUnits?page=' + page + '&rows=' + rows + '&onlyActive=' + onlyActive)
        : await rest('/getStockKeepingUnits?page=' + page + '&rows=' + rows);
};

export const createOrEditSkuItem = async (
    skuItem: StockKeepingUnitModel | null,
    skuItems?: StockKeepingUnitModel[]
): Promise<StockKeepingUnitModel[]> => {
    return await rest('/createOrEditSkuItem', 'POST', { skuItem, skuItems });
};

export const deleteSKUItem = async (item: StockKeepingUnitModel): Promise<StockKeepingUnitModel[]> => {
    let skuItem = { ...item };
    skuItem.active = false;
    return await rest('/createOrEditSkuItem', 'POST', { skuItem });
};

export const checkIfBarcodeOrCodeIsUnique = async (
    barcode: string | null,
    code: string | null,
    skuItemId?: number
): Promise<number> => {
    return await rest(
        '/checkIfBarcodeOrCodeIsUnique/' + (skuItemId ? skuItemId : null) + '/' + barcode + '/' + code,
        'GET'
    );
};

export const checkIfStockDocHeaderDocNumberExist = async (
    docHeaderId: number | null,
    docNumber: string,
    docType: number,
    conclusionType?: number | null
): Promise<boolean> => {
    return await rest(
        '/checkIfStockDocHeaderDocNumberExist/' +
            docHeaderId +
            '/' +
            docNumber +
            '/' +
            docType +
            '?conclusionType=' +
            (Number(conclusionType) ? Number(conclusionType) : null),
        'GET'
    );
};

export const getDocumentOrEntryCalcNumSuggestion = async (
    docType: string | null,
    document?: boolean,
    conclusionType?: number | null
): Promise<string> => {
    if (document) {
        return await rest(
            '/getDocumentOrEntryCalcNumSuggestion/' +
                docType +
                '?document=' +
                document +
                '&conclusionType=' +
                (Number(conclusionType) ? Number(conclusionType) : null),
            'GET'
        );
    } else {
        return await rest('/getDocumentOrEntryCalcNumSuggestion/' + docType, 'GET');
    }
};

export const getLockedOrUnLockedStockDocHeaders = async (
    getLockedHeaders?: boolean
): Promise<StockDocHeadersExtended[]> => {
    if (getLockedHeaders) {
        return await rest('/getLockedOrUnLockedStockDocHeaders?getLockedHeaders=' + getLockedHeaders, 'GET');
    } else {
        return await rest('/getLockedOrUnLockedStockDocHeaders', 'GET');
    }
};

export const loadGoodsReceivedNotes = async (): Promise<StockDocHeaderEditModel[]> => {
    return await rest('/loadGoodsReceivedNotes');
};

export const getStockDocHeaderWithItems = async (docHeaderId: number): Promise<StockDocHeadersWithItems> => {
    return await rest('/getStockDocHeaderWithItems/' + docHeaderId, 'GET');
};

export const saveEditedStockDocument = async (
    stockDocument: StockDocHeadersWithItems | StockDocHeaderModel,
    stockDocItems: StockDocItemModel[]
): Promise<StockDocHeadersWithItems> => {
    return await rest('/saveEditedStockDocument', 'POST', {
        stockDocument,
        stockDocItems,
    });
};

export const createStockDocument = async (stockDocument: StockDocHeaderModel): Promise<StockDocHeadersWithItems> => {
    return await rest('/saveEditedStockDocument', 'POST', {
        stockDocument,
        stockDocItems: [],
    });
};

export const searchSkuItem = async (searchString: string | number): Promise<StockKeepingUnitEditModel[]> => {
    return await rest(`/searchSkuItem?search=${searchString}`);
};

export const getSkuPluRate = async (
    skuItemId: number | null,
    posItemId: number | null
): Promise<SkuPluRateWithItem[]> => {
    return await rest('/getSkuPluRate', 'POST', { skuItemId: skuItemId, posItemId: posItemId });
};

export const createOrUpdatePosItemAndSkuPluRate = async (
    posItem: PosItemModel | null,
    skuPluRates: SkuPLURateModel[],
    posItems?: PosItemModel[]
): Promise<PosItemModel[]> => {
    return await rest('/createOrUpdatePosItemAndSkuPluRate', 'POST', { posItem, skuPluRates, posItems });
};

export const createOrEditWarehouse = async (warehouse: WarehouseModel): Promise<WarehouseModel> => {
    const newOrEditedWarehouse = await rest('/createOrEditWarehouse', 'POST', { warehouse });
    const warehousesState = useStore.getState().model.Warehouse
    const warehouses = warehousesState ? [...warehousesState] : []
    if (!warehouse.id) {
        warehouses.push(newOrEditedWarehouse);
        useStore.getState().setNestedProperty(['model', 'Warehouse'], warehouses)
    } else {
        const _editedWarehouse = warehouses.map((w: WarehouseModel) => {
            if (w.id === warehouse?.id) {
                return warehouse;
            } else {
                return w;
            }
        });
        useStore.getState().setNestedProperty(['model', 'Warehouse'], _editedWarehouse)
    }
    return newOrEditedWarehouse;
};

export const deleteWarehouse = async (warehouseId: number): Promise<void> => {
    await rest('/deleteWarehouse/' + warehouseId, 'POST');
    const warehouses = useStore.getState().model.Warehouse
    const _warehouses = warehouses?.filter((w: WarehouseModel) => w.id !== warehouseId);
    useStore.getState().setNestedProperty(['model', 'Warehouse'], _warehouses)
};

export const concludeStockDocument = async (headerId: number): Promise<void> => {
    return await rest('/concludeStockDocument/' + headerId, 'POST');
};

export const concludeStockDocAndCreateCalc = async (headerId: number): Promise<number> => {
    return await rest('/concludeStockDocAndCreateCalc/' + headerId, 'POST');
};

export const getCurrentWarehouseStock = async (warehouseId: number): Promise<WarehouseStock[]> => {
    console.time("getCurrentWarehouseStock")
    const res = await rest('/getCurrentWarehouseStock/' + warehouseId, 'GET');
    console.timeEnd("getCurrentWarehouseStock")
    return res
};

export const saveGeneralSettings = async (obj: any): Promise<any> => {
    return await rest('/saveGeneralSetting', 'POST', obj);
};

export const getPrevOrNextDocumentOrEntryCalc = async (
    type: string,
    selectedId: number,
    document?: boolean
): Promise<number> => {
    if (document) {
        return await rest(
            '/getPrevOrNextDocumentOrEntryCalc?type=' + type + '&selectedId=' + selectedId + '&document=' + document,
            'GET'
        );
    } else {
        return await rest('/getPrevOrNextDocumentOrEntryCalc?type=' + type + '&selectedId=' + selectedId, 'GET');
    }
};

export const getInventoryData = async (
    warehouseId: number,
    headerId: number | null
): Promise<InventoryWithExistingAndOtherItems> => {
    console.time("getInventoryData")
    const res = await rest('/getInventoryData/' + warehouseId + '/' + headerId, 'GET');
    console.timeEnd("getInventoryData")
    return res
};

export const getSupplierPriceListsWithItems = async (
    headerId: number | null,
    date?: number | null,
    companyId?: number | null
): Promise<SupplierPriceListsExtended[]> => {
    return headerId
        ? await rest('/getSupplierPriceListsWithItems/' + headerId, 'POST')
        : date
        ? await rest('/getSupplierPriceListsWithItems/null', 'POST', { date, companyId: companyId ? companyId : null })
        : await rest('/getSupplierPriceListsWithItems/null', 'POST', { companyId: companyId ? companyId : null });
};

export const checkSkuItemCodeToSupplierCode = async (
    companyId: number,
    itemList: SupplierPriceListToSheet[]
): Promise<SupplierPriceListToSheet[] | null> => {
    return await rest('/checkSkuItemCodeToSupplierCode/' + companyId, 'POST', {
        itemList: itemList,
    });
};

export const saveSupplierPriceList = async (
    header: SkuSupplierPriceListPerHeaderModel,
    itemList: SupplierPriceListToSheet[]
): Promise<SkuSupplierPriceListPerHeaderEditModel> => {
    return await rest('/saveSupplierPriceList', 'POST', { header: header, itemList: itemList });
};

export const createProcurementOrders = async (
    warehouseId: number,
    skuItemsCode: SkuItemSupplierCodeWithCompanyAndSkuItem[],
    nonExistingSkuItemsCode?: number[]
): Promise<void> => {
    return await rest('/createProcurementOrders/' + warehouseId, 'POST', {
        skuItemsCode: skuItemsCode,
        nonExistingSkuItemsCode: nonExistingSkuItemsCode ? nonExistingSkuItemsCode : [],
    });
};

export const getSkuItemSuppliers = async (
    skuItemIds: number[]
): Promise<SkuItemSupplierCodeWithCompanyAndSkuItem[]> => {
    return await rest('/getSkuItemSuppliers', 'POST', { skuItemIds: skuItemIds });
};

// ---ENTRY CALCULATION---

export const getEntryCalculations = async (getLockedEntryCalc?: boolean): Promise<EntryCalcHeaderExtended[]> => {
    if (getLockedEntryCalc) {
        return await rest('/getEntryCalculations?getLockedEntryCalc=' + getLockedEntryCalc, 'GET');
    } else {
        return await rest('/getEntryCalculations', 'GET');
    }
};

export const getEntryCalcWithItems = async (headerId: number): Promise<EntryCalcHeadersWithItems> => {
    return await rest('/getEntryCalcWithItems/' + headerId, 'GET');
};

export const saveEditedEntryCalc = async (
    entryCalc: EntryCalcHeadersWithItems,
    entryCalcItems: EntryCalculationItemModel[],
    editedColumn: string | null
): Promise<EntryCalcHeadersWithItems> => {
    return await rest('/saveEditedEntryCalc', 'POST', {
        entryCalc,
        entryCalcItems,
        editedColumn,
    });
};

export const createEntryCalc = async (entryCalc: EntryCalculationHeaderModel): Promise<EntryCalcHeadersWithItems> => {
    return await rest('/saveEditedEntryCalc', 'POST', {
        entryCalc,
        entryCalcItems: [],
    });
};

export const concludeEntryCalculation = async (headerId: number): Promise<void> => {
    return await rest('/concludeEntryCalculation/' + headerId, 'POST');
};

export const checkIfEntryCalcHeaderDocNumberExist = async (
    headerId: number | null,
    docNumber: string
): Promise<boolean> => {
    return await rest('/checkIfEntryCalcHeaderDocNumberExist/' + headerId + '/' + docNumber, 'GET');
};

export const concludeEntryCalcAndCreateDocument = async (headerId: number | null): Promise<number> => {
    return await rest('/concludeEntryCalcAndCreateDocument/' + headerId, 'POST');
};

export const getActiveStockReport = async (warehouseId: number | null): Promise<StockDocHeaderEditModel | null> => {
    const activeStockReport = await rest('/getActiveStockReport/' + warehouseId, 'GET');
    return activeStockReport;
};

export const savePosTerminals = async (posTerminals: PosTerminalEditModel[]): Promise<void> => {
    await rest('/savePosTerminals', 'POST', posTerminals);
};

export const updatePosTerminals = async (
    warehousePosTerminals: PosTerminalEditModel[] | null,
    posTerminals: PosTerminalEditModel[],
    warehouse: WarehouseModel
): Promise<void> => {
    const deleteWarehouseCandidates: PosTerminalEditModel[] = [];
    const newWarehouseCandidates: PosTerminalEditModel[] = [];

    posTerminals.forEach((p) => {
        const validPosTerminal = warehousePosTerminals?.find((pt) => pt.id === p.id && p.warehouseId === null);
        if (validPosTerminal) {
            newWarehouseCandidates.push(validPosTerminal);
        }
    });

    const allExistingWarehousePosTerminals = posTerminals.filter((p) => p.warehouseId === warehouse.id);
    for (const existingWarehousePosTerminal of allExistingWarehousePosTerminals) {
        const validPosTerminal = warehousePosTerminals?.find((pt) => pt.id === existingWarehousePosTerminal.id);
        if (!validPosTerminal) {
            deleteWarehouseCandidates.push(existingWarehousePosTerminal);
        }
    }

    const updatedPosTerminals: PosTerminalEditModel[] = [];

    for (const newWarehousePosTerminal of newWarehouseCandidates) {
        const copy = { ...newWarehousePosTerminal };
        copy.warehouseId = warehouse.id;
        updatedPosTerminals.push(copy);
    }

    for (const deleteWarehouseCandidate of deleteWarehouseCandidates) {
        const copy = { ...deleteWarehouseCandidate };
        copy.warehouseId = null;
        updatedPosTerminals.push(copy);
    }

    await savePosTerminals(updatedPosTerminals);
};

export const getLockedOrUnLockedPriceChangeLogsHeaders = async (
    getLockedHeaders?: boolean
): Promise<PriceChangeLogsHeadersExtended[]> => {
    if (getLockedHeaders) {
        return await rest('/getLockedOrUnLockedPriceChangeLogsHeaders?getLockedHeaders=' + getLockedHeaders, 'GET');
    } else {
        return await rest('/getLockedOrUnLockedPriceChangeLogsHeaders', 'GET');
    }
};

export const getPriceChangeLogHeaderWithItems = async (
    priceChangeLogId: number
): Promise<PriceChangeLogHeaderWithItems> => {
    return await rest('/getPriceChangeLogHeaderWithItems/' + priceChangeLogId, 'GET');
};

export const createPriceChangeLog = async (
    priceChangeLogHeader: PriceChangeLogHeaderModel
): Promise<PriceChangeLogHeaderWithItems> => {
    return await rest('/saveEditedPriceChangeLog', 'POST', {
        priceChangeLogHeader,
        priceChangeLogItems: [],
    });
};

export const saveEditedPriceChangeLog = async (
    priceChangeLogHeader: PriceChangeLogHeaderModel,
    priceChangeLogItems: PriceChangeLogItemModel[]
): Promise<PriceChangeLogHeaderWithItems> => {
    return await rest('/saveEditedPriceChangeLog', 'POST', {
        priceChangeLogHeader,
        priceChangeLogItems,
    });
};

export const closePriceChangeLog = async (priceChangeLogHeaderId: number): Promise<void> => {
    return await rest('/closePriceChangeLog/' + priceChangeLogHeaderId, 'POST');
};

export const checkIfPriceChangeLogDocNumberExist = async (
    priceChangeLogHeaderId: number | null,
    docNumber: string
): Promise<boolean> => {
    return await rest('/checkIfPriceChangeLogDocNumberExist/' + priceChangeLogHeaderId + '/' + docNumber, 'GET');
};

export const getPriceChangeLogNumSuggestion = async (): Promise<string> => {
    return await rest('/getPriceChangeLogNumSuggestion', 'GET');
};

export const getPrevOrNextPriceChangeLogHeader = async (type: string, selectedId: number): Promise<number> => {
    return await rest('/getPrevOrNextPriceChangeLogHeader?type=' + type + '&selectedId=' + selectedId, 'GET');
};
