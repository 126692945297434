import React, { useState } from 'react';
import translate from '@data/translations';
import { Reservation, ReservationController } from '../resController';
import { CustomersAndCompanies } from './CustomersAndCompanies';
import { CompanyModel, CustomerModel, ReservationModel, ReservationStatus, UserInfo } from '@common/modelDefinition';
import ReservationDateTimeSelector from './ReservationDateTimeSelector';
import AccommodationPriceOffer from './accommodationPriceOffer';
import { setModalContent } from '@components/modal';
import CompanyForm from '../../company/form';
import CustomerForm from '../../customers/form';
import Delete from '@components/confirmModal';
import StatusButtons from './statusButtons';
import OpenPrice from './OpenPrice';
import moment from 'moment';
import useStore from '@data/state/zustand';
interface MainReservationInfoProps {
    history: any;
    otherSettings: any;
}

function MainReservationInfo(props: MainReservationInfoProps) {
    const { history, otherSettings } = props;
    const ctrl = new ReservationController();

    const loggedUser: UserInfo = useStore((appState: any) => appState.authTokenInfo?.user);
    const isPending: boolean = useStore((appState: any) => appState.state.reservationForm2.isPending);

    const reservation = ctrl.getActiveReservation();

    const [reservationNotes, setReservationNotes] = useState(reservation?.notes ? reservation.notes : '');

    const onReservationInputChange = (e: any) => {
        const res = { ...reservation };
        if (e.target.name === 'statusEnum') {
            (res as any)[e.target.name] = Number(e.target.value);
            if (Number(e.target.value) === 2) {
                (res as any)['checkInTimestamp'] = new Date().getTime();
            }
        } else {
            (res as any)[e.target.name] = e.target.value;
        }
        ctrl.updateActiveReservation(res as ReservationModel);
    };

    const editCustomer = (c: CustomerModel) => {
        setModalContent(
            <CustomerForm
                callback={(updatedCustomer: CustomerModel) => {
                    ctrl.setReservationHolder({ customer: updatedCustomer, company: reservation.Company });
                    if (updatedCustomer?.id && reservation.customerId !== updatedCustomer.id) {
                        const guestInVisaInfo =
                            reservation.Customer &&
                            reservation?.VisaInfo?.find((c) => c.customerId === reservation.Customer?.id);
                        if (guestInVisaInfo && guestInVisaInfo.id) {
                            ctrl.removeGuestFromReservation(guestInVisaInfo.id);
                        }
                    }
                }}
                customerId={c.id}
                reservation={reservation}
                customHeader={''}
                showPageTitle={false}
            />,
            translate('Edit customer'),
            false,
            'modal-xl'
        );
    };

    const editCompany = (c: CompanyModel) => {
        setModalContent(
            <CompanyForm
                customHeader={''}
                showPageTitle={false}
                callback={(updatedCompany: CompanyModel) => {
                    ctrl.setReservationHolder({ customer: reservation.Customer, company: updatedCompany });
                }}
                companyId={c.id}
            />,
            translate('Edit company'),
            false,
            'modal-md'
        );
    };

    const showDeleteModal = (cusOrComp: string) => {
        setModalContent(
            <Delete
                actionForYes={() => {
                    if (cusOrComp === 'customer') {
                        ctrl.setReservationHolder({ customer: null, company: reservation.Company });
                        const guestInVisaInfo =
                            reservation.Customer &&
                            reservation?.VisaInfo?.find((c) => c.customerId === reservation.Customer?.id);
                        if (guestInVisaInfo && guestInVisaInfo.id) {
                            ctrl.removeGuestFromReservation(guestInVisaInfo.id);
                        }
                    } else {
                        ctrl.setReservationHolder({ customer: reservation.Customer, company: null });
                    }
                }}
            />,
            translate('Delete ') + translate('reservation holder'),
            false,
            'modal-md'
        );
    };

    const isCheckedInOnLoad = reservation?.statusEnum === ReservationStatus.checkedIn;
    const isAutoBlocked = reservation?.statusEnum === ReservationStatus.autoBlocked;

    const isAdmin = loggedUser
        ? ['superAdmin', 'admin', 'managerFull', 'managerBussiness'].includes(loggedUser.role)
        : false;

    return (
        <div className="">
            <div className="display-flex mt-16p mobile-page-form">
                <div className="flex">
                    <label className="text-bold ml-4p mb-4p">{translate('Holder name')}</label>
                    <CustomersAndCompanies
                        company={null}
                        customer={reservation.Customer}
                        history={history}
                        reservation={reservation}
                        customerSelect={(c: CustomerModel) => {
                            const res = { ...reservation };
                            res.Customer = c;
                            res.customerId = c.id;
                            res.adultsNumber = res.id ? res.adultsNumber + 1 : 1;
                            ctrl.updateActiveReservation(res as ReservationModel);
                        }}
                        companySelect={(c: CompanyModel) => {}}
                        editCustomer={editCustomer}
                        deleteCustomer={() => showDeleteModal('customer')}
                        showCompany={false}
                    />
                    <CustomersAndCompanies
                        company={reservation.Company}
                        customer={null}
                        history={history}
                        reservation={reservation}
                        customerSelect={(c: CustomerModel) => {}}
                        companySelect={(c: CompanyModel) => {
                            const res = { ...reservation };
                            res.Company = c;
                            res.companyId = c.id;
                            ctrl.updateActiveReservation(res as ReservationModel);
                        }}
                        editCompany={editCompany}
                        deleteCompany={() => showDeleteModal('company')}
                        doNotShowCustomerSearch={true}
                    />
                </div>
                <div className="flex justify-content-center align-center">
                    {reservation?.id ? (
                        <StatusButtons
                            statusButtonActionHandler={(action: string, applyCurrentTime?: boolean) => {
                                const res = { ...reservation };
                                if (action === 'confirm') {
                                    res.statusEnum = ReservationStatus.confirmed;
                                    res.canceledReservationInvoicingStatus = 0;
                                } else if (action === 'checkin') {
                                    res.statusEnum = ReservationStatus.checkedIn;
                                    if (applyCurrentTime) {
                                        res.checkInTimestamp = Date.now();
                                    }
                                } else if (action === 'cancel') {
                                    res.statusEnum = ReservationStatus.canceled;
                                    if (reservation.previousGroupUuid) {
                                        reservation.previousGroupUuid = null; //Ako je izbačena iz grupe, pa nakon tog i otkazana, izbaci je s računa
                                    }
                                } else if (action === 'checkout') {
                                    //checkout only throught checkout form (payment)
                                }
                                ctrl.updateActiveReservation(res as ReservationModel);
                            }}
                            history={history}
                            reservation={reservation}
                            isPending={isPending}
                        />
                    ) : (
                        <div className="flex align-items-center">
                            <label className="text-bold ml-4p mb-4p">{translate('Reservation status')}</label>
                            <div className="p-1">
                                <select
                                    onChange={onReservationInputChange}
                                    name="statusEnum"
                                    value={reservation.statusEnum}
                                    className="input input-modal"
                                >
                                    <option disabled={isAutoBlocked || isCheckedInOnLoad} value={0}>
                                        {translate('Early bird')}
                                    </option>
                                    <option disabled={isCheckedInOnLoad} value={1}>
                                        {translate('Confirmed')}
                                    </option>
                                    <option
                                        disabled={
                                            reservation?.id ? (reservation?.statusEnum === 4 ? true : false) : true
                                        }
                                        value={2}
                                    >
                                        {translate('Checked in')}
                                    </option>
                                    <option
                                        disabled={
                                            (reservation?.id ? (reservation?.statusEnum === 2 ? true : false) : true) &&
                                            !isAdmin
                                        }
                                        value={3}
                                    >
                                        {translate('Canceled')}
                                    </option>
                                    <option disabled={reservation.statusEnum !== ReservationStatus.closed} value={4}>
                                        {translate('Close')}
                                    </option>
                                    <option disabled={true} value={5}>
                                        {translate('Auto blocked')}
                                    </option>
                                </select>
                            </div>
                        </div>
                    )}
                    {reservation.statusEnum === ReservationStatus.canceled ? (
                        <div className="mt-8p justify-content-center align-items-center d-inline">
                            <label className="text-bold mt-8p mb-4p">{translate('Invoicing method')}</label>
                            <select
                                onChange={async (e) => {
                                    const res = { ...reservation };
                                    res.canceledReservationInvoicingStatus = Number(e.target.value);
                                    if (Number(e.target.value) !== 0) {
                                        res.accommodationPriceListId = null;
                                        res.openPrice = null;
                                    }
                                    ctrl.updateActiveReservation(res);
                                }}
                                name="canceledReservationInvoicingStatus"
                                value={reservation?.canceledReservationInvoicingStatus}
                                className="input input-modal"
                            >
                                <React.Fragment>
                                    {['Full price', 'Partial fee', 'No charge'].map((option: string, key: number) => {
                                        return (
                                            <option value={key} key={key}>
                                                {translate(option)}
                                            </option>
                                        );
                                    })}
                                </React.Fragment>
                            </select>
                        </div>
                    ) : null}
                    {reservation.canceledReservationInvoicingStatus === 1 ? (
                        <div className="mt-4p justify-content-center align-items-center d-inline">
                            <label className="text-bold mt-4p mb-4p">
                                {translate('The cost of canceling a reservation')}
                            </label>
                            <OpenPrice reservation={reservation} />
                        </div>
                    ) : null}
                </div>
            </div>
            <hr />
            <div className="mt-8p">
                <ReservationDateTimeSelector
                    reservation={reservation}
                    onReservationUpdate={(reservation: Reservation) => {
                        const res = { ...reservation };
                        ctrl.updateActiveReservation(res);
                    }}
                />
            </div>
            <br />
            <AccommodationPriceOffer history={history} room={reservation.RoomInfo} reservation={reservation} />
            <hr />
            <div className="">
                {reservation?.id ? (
                    <>
                        <div className="">
                            <ReservationMadeBy reservation={reservation} otherSettings={otherSettings} />
                        </div>
                        <br />
                    </>
                ) : null}
                <label className="text-bold ml-4p mb-4p">{translate('Reservation notes')}</label>
                <textarea
                    rows={2}
                    className="input input-modal mt-4p"
                    placeholder={translate('Reservation notes')}
                    name="notes"
                    onChange={(e: any) => {
                        let _resNotes = reservationNotes;
                        _resNotes = e.target.value;
                        setReservationNotes(_resNotes);
                    }}
                    onBlur={() => {
                        const res = { ...reservation };
                        res.notes = reservationNotes;
                        ctrl.updateActiveReservation(res as ReservationModel);
                    }}
                    value={reservationNotes ? reservationNotes : ''}
                ></textarea>
            </div>
        </div>
    );
}

function ReservationMadeBy(props: { reservation: Reservation; otherSettings: any }) {
    const { reservation, otherSettings } = props;
    const user = reservation.Recepcionist;

    if (user === null || user === undefined) {
        return null;
    }

    let reservationMadeByName = user.name ? `${user.name} ${user.surname} ${user.email}` : `${user.email}`;

    return (
        <div className="flex-center-between">
            <div className="ml-4p ">
                <b>
                    {translate('Reservation made by')}
                    {': '}
                </b>
                {reservationMadeByName}
            </div>
            <div className="ml-4p ">
                <b>
                    {translate('Reservation creation time')}
                    {': '}
                </b>
                {moment((reservation as any).createdAt).format(
                    otherSettings?.dateFormat ? otherSettings.dateFormat + ' hh:mm' : 'DD/MM/YYYY hh:mm'
                )}
            </div>
        </div>
    );
}

export default MainReservationInfo;
