import useStore from '@data/state/zustand';
import _ from 'lodash';

var handleSRCSettingsPayload = (payloadRaw) => {
    let roomInfo = _.clone(
        useStore.getState().getItem(['model', 'RoomInfo'], 'remoteDeviceId', payloadRaw.remoteDeviceId)
    );

    let roomInfo1 = _.clone(
        useStore.getState().getItem(['model', 'RoomInfo'], 'remoteDeviceId2', payloadRaw.remoteDeviceId)
    );

    let roomInfo2 = _.clone(
        useStore.getState().getItem(['model', 'RoomInfo'], 'remoteDeviceId3', payloadRaw.remoteDeviceId)
    );


    if (roomInfo) {
        roomInfo.SRCSettings = payloadRaw.SRCSettings;
        useStore.getState().setNestedProperty(['model', 'RoomInfo'], roomInfo, "remoteDeviceId")
    }

    if (roomInfo1) {
        roomInfo1.SRCSettings = payloadRaw.SRCSettings;
        useStore.getState().setNestedProperty(['model', 'RoomInfo'], roomInfo1, "remoteDeviceId2")
    }

    if (roomInfo2) {
        roomInfo2.SRCSettings = payloadRaw.SRCSettings;
        useStore.getState().setNestedProperty(['model', 'RoomInfo'], roomInfo2, "remoteDeviceId3")
    }
};

export { handleSRCSettingsPayload };
