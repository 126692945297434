import _ from 'lodash';
import rest from '../restWrapper';
import { reloadReservations } from '../actions/loaders';
import useStore from '../state/zustand';

var socketBaobabDataFunnel = (modelName, method, payload) => {
    const permissions = useStore.getState().state?.userPermissions;
    if (!permissions?.readModels?.includes(modelName)) {
        return;
    }

    try {
        let pkeyName = useStore.getState().pkeyMap[modelName];
        let baobabRoute = null;

        const modelNameData = useStore.getState().model[modelName];
        if (modelNameData !== undefined) {
            baobabRoute = ['model', modelName];
        }

        if (baobabRoute !== null) {
            //baobabRoute.push(modelName)
            switch (method) {
                case 'PUT': //na temelju pkey-a potraži zapis i izmjeni ga
                    if (!_.isArray(payload)) {
                        let keyObj = {};
                        keyObj[pkeyName] = payload[pkeyName];
                        let _baobabObjectReference = useStore
                            .getState()
                            .getItem(baobabRoute, pkeyName, payload[pkeyName]);
                        let baobabObjectReference = { ..._baobabObjectReference };
                        if (baobabObjectReference !== undefined) {
                            _.forIn(payload, (val, key) => {
                                baobabObjectReference[key] = val;
                            });
                            useStore.getState().setNestedProperty(baobabRoute, baobabObjectReference, pkeyName);
                        } else {
                            //put it on the stack
                            useStore.getState().addItem(baobabRoute, payload);
                        }
                    }
                    break;
                case 'DELETE': //ukloni novi zapis sa liste na temelju pkeyy-a
                    if (!_.isArray(payload)) {
                        if (payload[pkeyName] !== undefined) {
                            useStore.getState().deleteItem(baobabRoute, pkeyName, payload[pkeyName]);
                            baobabRoute.push({ [pkeyName]: payload[pkeyName] });
                        }
                    } else {
                        for (const obj in payload) {
                            let delObj = payload[obj];
                            if (delObj[pkeyName] !== undefined) {
                                useStore.getState().deleteItem(baobabRoute, pkeyName, delObj[pkeyName]);
                                baobabRoute.push({ [pkeyName]: delObj[pkeyName] });
                            }
                        }
                    }
                    break;
                case 'POST': //dodaj novi zapis na listu ako ne postoji
                    if (!_.isArray(payload)) {
                        let keyObj = {};
                        keyObj[pkeyName] = payload[pkeyName];
                        let baobabObjectReference = useStore
                            .getState()
                            .getItem(baobabRoute, pkeyName, payload[pkeyName]);
                        if (baobabObjectReference === undefined) {
                            useStore.getState().addItem(baobabRoute, payload);
                        }
                    }
                    break;
                default: //ignore
                case 'GET': //GET is not supported by the socket funnel funnel. Manualy put data on state and take care
                    break;
            }
        }
    } catch (err) {
        console.warn('socketBaobabDataFunnel.error', err);
    }
};

var reloadModelData = async (modelName) => {
    try {
        if (useStore.getState().model[modelName] !== undefined) {
            //  ovaj if će zamijeniti ovaj iznad preko baobaba

            if (modelName === 'Reservation') {
                await reloadReservations();
            } else {
                let baobabRoute = ['model', modelName];
                let data = await rest(`/${modelName}?page=0&rows=1000`);
                useStore.getState().setNestedProperty(baobabRoute, data);
            }
        }
    } catch (err) {
        console.log(err);
    }
};

export { reloadModelData };
export default socketBaobabDataFunnel;
