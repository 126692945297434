import React from 'react';
import useStore from '@data/state/zustand';

const isFeatureEnabled = (featureFlag) => {
    const licence = useStore.getState().licence
    const fflags = licence?.licence?.decodedLicence?.pack?.features

    if (fflags) {
        // const fflgasArray = fflags.filter((f) => f.isEnabled).map((f) => f.name);
        // return fflgasArray.includes(featureFlag);
        return fflags.includes(featureFlag);
    } else {
        return false;
    }
};

const FeatureFlag = (props) => {
    const { flag = 'someFeatureName' } = props;
    if (isFeatureEnabled(flag)) {
        return <React.Fragment>{props.children}</React.Fragment>;
    } else {
        return null;
    }
};

export default FeatureFlag;
export { isFeatureEnabled };