import localeEn from './en';
import localeHr from './hr';
// import localeRs from './rs';
import useStore from '@data/state/zustand';

var translations = {
    hr: localeHr,
    en: localeEn,
    // rs: localeRs
};

const dict = () => {
    const language = useStore.getState().locale
    if (translations[language]) {
        return translations[language];
    } else {
        return translations.en;
    }
};

var getLanguagesInArray = () => {
    return Object.keys(translations);
};

const translate = (text) => {
    if (dict()[text]) {
        return dict()[text];
    } else {
        return text;
    }
};

const dictionaryTranslate = (text, dict) => {
    if (dict) {
        if(dict[text]){
            return dict[text];
        } else {
            return text;
        }
       
    } else {
        return translate(text);
    }
};

var activeLanguage = ()=>{
    return useStore.getState().locale
}

export default translate;

export { getLanguagesInArray, translations, translate, activeLanguage, dictionaryTranslate  };