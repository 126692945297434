import React, { Component } from 'react';

class FormHeader extends Component {
    render() {
        let classNameSufix = this.props.classNameSufix !== undefined ? this.props.classNameSufix : ' '; //animated faster slideInDown
        return (
            <h4 className={'text-center text-secondary bg-white border-bottom p-2 uppercase mb-0 ' + classNameSufix}>
                {this.props.title || this.props.children}
            </h4>
        );
    }
}

export default FormHeader;
