import React, { Component } from 'react';
import FileUpload from './fileUpload';
import _ from 'lodash';
import rest from '../../data/restWrapper';
import useStore from '@data/state/zustand';
import { Buffer } from 'buffer';

class FolderBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            isFileUploading: false,
        };
    }

    componentDidMount() {
        if (this.props.documents) {
            if (_.isString(this.props.documents)) {
                let documents = JSON.parse(this.props.documents);
                this.setState({ documents });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.documents && this.props.documents) {
            if (prevProps.documents.toString().length !== this.props.documents.toString().length) {
                let documents = JSON.parse(this.props.documents);
                this.setState({ documents });
            }
        }
    }

    onFileUploadedHandler(fileInfo) {
        let documents = _.clone(this.state.documents);
        documents.push(fileInfo);
        this.setState({ documents });
        if (this.props.onFolderUpdated) {
            this.props.onFolderUpdated(JSON.stringify(documents));
        }
    }

    setFileUploading(isUploading) {
        this.setState({ isFileUploading: isUploading });
    }

    openDocument(docInfo, e) {
        const token = useStore.getState().authTokenInfo.token
        if (process.env.NODE_ENV === 'development') {
            window.open(
                'http://localhost:7777/getDocument/' + docInfo.uuid + '?token=' + Buffer.from(token).toString('base64')
            );
        } else {
            window.open('/getDocument/' + docInfo.uuid + '?token=' + Buffer.from(token).toString('base64'));
        }
    }

    async deleteDocument(docInfo, e) {
        try {
            let documents = _.clone(this.state.documents);
            await rest('/deleteDocument/' + docInfo.uuid, 'DELETE');
            _.remove(documents, (d) => d.uuid === docInfo.uuid);
            this.setState({ documents });
            if (this.props.onFolderUpdated) {
                this.props.onFolderUpdated(JSON.stringify(documents));
            }
        } catch (err) {
            console.warn(err);
        }
    }

    getDocumentIconBasedOnFileType(docInfo) {
        let result = null;
        let defaultFileIconPlaceholder = this.props.defaultFileIconPlaceholder ? (
            this.props.defaultFileIconPlaceholder
        ) : (
            <i className="fa fa-file"></i>
        );

        switch (docInfo.type) {
            case 'image/jpeg':
            case 'image/png':
                result = <i className="fa fa-file-image-o"></i>;
                break;
            case 'application/json':
                result = <i className="fa fa-file-text-o"></i>;
                break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                result = <i className="fa fa-file-word-o"></i>;
                break;
            case 'application/pdf':
                result = <i className="fa fa-file-pdf-o"></i>;
                break;
            default:
                result = defaultFileIconPlaceholder;
                break;
        }
        return result;
    }

    render() {
        //let buttonStyles = this.props.btnStyle ?   this.props.btnStyle : null
        let openBtnStyle = this.props.openBtnStyle
            ? this.props.openBtnStyle
            : 'btn btn-sm btn-info ml-8p mr-8p pull-right';
        let openBtnContent = this.props.openBtnContent ? (
            this.props.openBtnContent
        ) : (
            <i className="fa fa-download" aria-hidden="true"></i>
        );
        let deleteBtnStyle = this.props.deleteBtnStyle
            ? this.props.deleteBtnStyle
            : 'btn btn-sm btn-danger ml-8p mr-8p pull-right';
        let deleteBtnContent = this.props.deleteBtnContent ? (
            this.props.deleteBtnContent
        ) : (
            <i className="fa fa-trash" aria-hidden="true"></i>
        );
        let ulStyle = this.props.ulStyle ? this.props.ulStyle : 'list-group list-group-flush bg-white mb-2';
        let liStyle = this.props.liStyle ? this.props.liStyle : 'list-group-item p-1 pl-2';
        //let defaultFileIconPlaceholder =  this.props.defaultFileIconPlaceholder ? this.props.defaultFileIconPlaceholder : <i className="fa fa-file mr-2"></i>
        let uploadButtonStyle = this.props.uploadButtonStyle ? this.props.uploadButtonStyle : 'btn btn-sm btn-info';
        let uploadButtonText = this.props.uploadButtonText
            ? this.props.uploadButtonText
            : window.translate('Upload a file');
        let showDeleteButton = this.props.showDeleteButton !== undefined ? this.props.showDeleteButton : true;
        let showOpenFileButton = this.props.showOpenFileButton !== undefined ? this.props.showOpenFileButton : true;
        let showFileUploadButton =
            this.props.showFileUploadButton !== undefined ? this.props.showFileUploadButton : true;

        return (
            <div>
                <ul className={ulStyle}>
                    {_.map(this.state.documents, (doc) => {
                        let documentIcon = this.getDocumentIconBasedOnFileType(doc);
                        return (
                            <li key={doc.uuid} className={liStyle}>
                                <small>
                                    {documentIcon}&ensp;{doc.name}
                                </small>

                                {showOpenFileButton ? (
                                    <button className={openBtnStyle} onClick={this.openDocument.bind(this, doc)}>
                                        {openBtnContent}
                                    </button>
                                ) : null}
                                {showDeleteButton ? (
                                    <button className={deleteBtnStyle} onClick={this.deleteDocument.bind(this, doc)}>
                                        {deleteBtnContent}
                                    </button>
                                ) : null}
                            </li>
                        );
                    })}
                    {this.state.isFileUploading ? (
                        <li className={liStyle}>
                            <i className="fa fa-spin fa-spinner"></i>
                            {window.translate(' uploading file')}
                        </li>
                    ) : null}
                </ul>

                {showFileUploadButton ? (
                    <FileUpload
                        uploadButtonText={uploadButtonText}
                        onFileUploaded={this.onFileUploadedHandler.bind(this)}
                        setFileUploading={this.setFileUploading.bind(this)}
                        uploadButtonStyle={uploadButtonStyle}
                    />
                ) : null}
            </div>
        );
    }
}

export default FolderBox;
