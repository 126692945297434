import translate from '@data/translations';
import React from 'react';

interface LoadingButtonProps {
    className?: any;
    onClick: Function;
    children: any;
    disabled: boolean;
}

const LoadingButton = (props: LoadingButtonProps) => {
    const { className, onClick, children, disabled } = props;
    const [actionPending, setActionPending] = React.useState(false);
    const isMounted = React.useRef(true);

    React.useEffect(() => {
        // Cleanup function to update the ref when the component unmounts
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <button
            disabled={actionPending || disabled ? true : false}
            onClick={async (e) => {
                setActionPending(true);
                await onClick(e);
                if (isMounted.current) {
                    setActionPending(false);
                }
            }}
            className={className}
        >
            {actionPending ? (
                <React.Fragment>
                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    {translate('Processing...')}
                </React.Fragment>
            ) : (
                children
            )}
        </button>
    );
};

export default LoadingButton;
