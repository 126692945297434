import React from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import asyncComponent from './components/asyncComponent';
import PageNotFound from './pages/pageNotFound';
import PageLoader from './components/loaders/loader1';
import MasterLayout from './pages/masterLayout';
import componentPageTitle from '@components/pageTitleUtils';
import useStore from './data/state/zustand';
const Login = asyncComponent(() => import('./authPages/login/index'));

var MasterLayoutWithRouter = withRouter(MasterLayout);

const PublicRoutes = (props) => {
    function onEnterRouteHandler(pageTitle, nextRouteObj) {
        useStore.getState().setNestedProperty(['pageTitle'], pageTitle);
        useStore.getState().setNestedProperty(['pagePath'], nextRouteObj.match.path);
        const licence = useStore.getState().licence
        const roomInfos = useStore.getState().model.RoomInfo
        
        if (
            licence?.licence?.decodedLicence?.pack?.features?.includes('onPrem') &&
            nextRouteObj.location.pathname === '/' &&
            !nextRouteObj.location.search
        ) {
            return <Redirect to={'?roomsOnPrem'}></Redirect>;
        }
        if (props.fixedRoute && props.fixedRoute !== '' && nextRouteObj.location.pathname !== props.fixedRoute) {
            return <Redirect to={props.fixedRoute} />;
        } else if (
            props.dashboardRoute &&
            props.dashboardRoute !== '' &&
            props.dashboardRoute !== '/' &&
            nextRouteObj.location.pathname === '/'
        ) {
            return <Redirect to={props.dashboardRoute} />;
        } else if (
            (!props.locale || !props.defaultCurrency) &&
            window.location.hash.lastIndexOf('firstTimeSetup') === -1
            
        ) {
			if(roomInfos?.length === 0){
				return <Redirect to={'/firstTimeSetup'} />;
			}else{
				return null
			}
            
        } else {
            return null;
        }
    }
    return (
        <Router {...props}>
            <MasterLayoutWithRouter {...props}>
                <Switch {...props}>
                    {componentPageTitle.map((itm, ind) => {
                        return (
                            <Route
                                key={ind}
                                ignoreScrollBehavior
                                exact
                                path={itm.path}
                                component={itm.component}
                                children={onEnterRouteHandler.bind(this, window.translate(itm.pageTitle))}
                            />
                        );
                    })}

                    <Route pageTitle="Page not found" component={PageNotFound} />
                </Switch>
            </MasterLayoutWithRouter>
        </Router>
    );
};

const UnauthorizedRouter = (props) => (
    <Router {...props} ignoreScrollBehavior>
        <Switch {...props}>
            <Route
                exact
                path="/"
                render={(_props) => {
                    return <Login {..._props} {...props} showRegistration={props?.about?.adminUsersCount === 0} />;
                }}
            />
            <Route
                pageTitle="Login"
                render={(_props) => {
                    return <Login {..._props} {...props} showRegistration={props?.about?.adminUsersCount === 0} />;
                }}
            />
        </Switch>
    </Router>
);

const ExportRoutes = (props) => {
    const showConnectingScreen = useStore((appState) => appState.socketConnection?.showConnectingScreen);
    const socketConnection = useStore((appState) => appState.socketConnection);
    const about = useStore((appState) => appState.about);
    const locale = useStore((appState) => appState.locale);
    const defaultCurrency = useStore((appState) => appState.defaultCurrency);
    const hotelName = useStore((appState) => appState.companyInfo?.hotelName);
    const authTokenInfo = useStore((appState) => appState.authTokenInfo);
    const fixedRoute = useStore((appState) => appState.state.userPermissions?.fixedRoute);
    const dashboardRoute = useStore((appState) => appState.state.userPermissions?.dashboardRoute);
    const licenceType = useStore((appState) => appState.licence?.decodedLicence?.licenceType);
    const keycloak = useStore((appState) => appState.keycloak);

    const _props = {
        socketConnection,
        about,
        locale,
        defaultCurrency,
        hotelName,
        authTokenInfo,
        fixedRoute,
        dashboardRoute,
        licenceType,
        keycloak,
        showConnectingScreen
    };

    if (_props.authTokenInfo || _props.keycloak?.authenticated) {
        if (_props.socketConnection && _props.socketConnection.showConnectingScreen) {
            return (
                <PageLoader
                    header={_props.socketConnection.connectingMessage}
                    heading={_props.socketConnection.heading}
                />
            );
        } else {
            return <PublicRoutes {..._props} />;
        }
    } else {
        if (_props.socketConnection?.showConnectingScreen) {
            return (
                <PageLoader
                    header={_props.socketConnection.connectingMessage}
                    heading={_props.socketConnection.heading}
                />
            );
        } else {
            return <UnauthorizedRouter {..._props} />;
        }
    }
};


export default ExportRoutes

