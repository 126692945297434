// const EventEmitter = require('events');
// class addOnEventEmitter extends EventEmitter {}

// //IMPORTANT: USE ARROW FUNCTIONS IN componentDidMount and componentWillUnmount to succesefuly deregister React events


// var eventEmitter = null;
// if (window.pimatico_hotel_pubsub) {
//     eventEmitter = window.pimatico_hotel_pubsub;
// } else {
//     eventEmitter = new addOnEventEmitter();
//     window.pimatico_hotel_pubsub = eventEmitter;
// }

// export default eventEmitter;

// /*
// USAGE TEMPLATES:

// pubsub.emit("toastMessage", "message", "info") //type=error or info

// */


import { EventEmitter } from 'events';

class AddOnEventEmitter extends EventEmitter {}

const eventEmitter: AddOnEventEmitter = (window as any).pimatico_hotel_pubsub
  ? (window as any).pimatico_hotel_pubsub
  : new AddOnEventEmitter();

if (!(window as any).pimatico_hotel_pubsub) {
  (window as any).pimatico_hotel_pubsub = eventEmitter;
}

export default eventEmitter;
