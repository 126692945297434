import translate from '@data/translations';
import React from 'react';

const SRCDeviceError = (props: any) => {
        return (
            <div className="alert alert-danger" role="alert">
                {' '}
                {translate("SRC DEVICE COMMUNICATION ERROR!")}{' '}
            </div>
        );
}
export default SRCDeviceError;

// class SRCDeviceError extends Component {
//     render() {
//         return (
//             <div className="alert alert-danger" role="alert">
//                 {' '}
//                 {window.translate("SRC DEVICE COMMUNICATION ERROR!")}{' '}
//             </div>
//         );
//     }
// }

// export default SRCDeviceError;
