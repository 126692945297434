import React from 'react';
import PageTitle from '../../components/pageTitle';
import rest from '../../data/restWrapper';
import useStore from '@data/state/zustand';
import { UserInfo } from '@common/modelDefinition';

const FeatureFlagsList = () => {
    const featureFlag = useStore((appState: any) => appState.model.FeatureFlag); 
    const userInfo: UserInfo = useStore((appState: any) => appState.authTokenInfo?.user);

    if (userInfo?.role !== 'admin' && userInfo?.role !== 'superAdmin') {
        return 'Access denied';
    }

    return (
        <div>
            <div>
                <div className="title-with-options display-flex space-between">
                    <PageTitle />
                </div>
            </div>

            {featureFlag
                ? featureFlag.map((f: any, key: number) => {
                      const checkboxIcon = f.isEnabled ? (
                          <i className="fa fa-2x fa-check-square-o pull-right" />
                      ) : (
                          <i className="fa fa-2x fa-square-o pull-right " />
                      );

                      return (
                          <div
                              onClick={async () => {
                                  const fflag = { ...f };
                                  fflag.isEnabled = fflag.isEnabled ? false : true;
                                  try {
                                      await rest('/FeatureFlag', 'POST', fflag);
                                  } catch (error) {
                                      console.log('error', error);
                                  }
                              }}
                              className="custom-list-item"
                              key={key}
                          >
                              <div className="">{f.name}</div>
                              <div className="">{f.description}</div>
                              <div className="">{f.version}</div>
                              <div className="pointer">{checkboxIcon}</div>
                          </div>
                      );
                  })
                : null}
        </div>
    );
};

export default FeatureFlagsList;
