import React from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import AlertToastTemplate from '../components/toaster/alertToastTemplate';
import { translate } from './translations';
import useStore from './state/zustand';

var userFrendlyAlarmAndEventsNames = {
    alarm_sos: translate('SOS assistance required'), //bitmask 15
    alarm_fire: translate('Fire alarm'), //bitmask 14
    alarm_smoke: translate('Smoke detected'), //bitmask 13
    alarm_flood: translate('Flood detected'), //bit mask 12
    alarm_motion: translate('Motion detected'), //bitmask 11
    alarm_intruder: translate('Intrudor detected'), //bitmask 10
    alarm_minibarOpened: translate('Minibar opened'), //bitmask 9
    alarm_safeOpened: translate('Room safe opened'), //bitmask 8
    alarm_doNotDisturb: translate('Do not disturb'), //bitmask 7
    alarm_cleaningService: translate('Cleaning service required'), //bitmask 6
    alarm_roomService: translate('Room service required'), //bitmask 5
    alarm_roomAssistancePending: translate('Assistance pending'), //bitmask 4
    alarm_ecoRoomMode: translate('ECO mode'), //bitmask 3
    alarm_comfortRoomMode: translate('COMFORT mode'), //bitmask 2
    alarm_preComfortRoomMode: translate('PRE COMFORT mode'), //bitmask 1
    alarm_cleaningRoomMode: translate('CLEANING mode'), //bit mask 0
    customAlarm_minibarNotClosed: translate('Minibar is open more than 5 minutes!'),
};

var alertCategories = {
    //USED FOR TOAST CONFIGURATION, NOT CSS STYLING
    //just show them and hide quickly
    info: [
        'alarm_minibarOpened',
        'alarm_safeOpened',
        'alarm_motion',
        'alarm_roomAssistancePending' /* "alarm_doNotDisturb",*/,
    ],
    //hold them and hide slowly
    warning: [
        'alarm_roomService',
        'alarm_roomCleaningInProgress',
        /* "alarm_cleaningService",*/ /* "alarm_ecoRoomMode", "alarm_comfortRoomMode", "alarm_preComfortRoomMode",*/ 'alarm_cleaningRoomMode',
    ],
    //hold them with no autohide option
    important: ['alarm_flood', 'alarm_intrudor', 'alarm_smoke', 'customAlarm_minibarNotClosed'],
    //hod them with no autohide or close opetion
    danger: ['alarm_sos', 'alarm_fire'],
};

var alarmAndEventCSSStyling = {
    alarm_minibarOpened: 'bg-light text-dark p-2 shadow rounded',
    alarm_safeOpened: 'bg-light text-dark p-2 shadow rounded',
    alarm_doNotDisturb: 'bg-info text-white p-2 shadow rounded',
    alarm_roomService: 'bg-info text-white p-2 shadow rounded',
    alarm_roomCleaningInProgress: 'bg-primary text-white p-2 shadow rounded',
    alarm_flood: 'bg-warning text-dark p-2 shadow rounded',
    alarm_intruder: 'bg-warning text-dark p-2 shadow rounded',
    alarm_smoke: 'bg-warning text-dark p-2 shadow rounded',
    alarm_sos: 'bg-danger text-white p-2 shadow rounded',
    alarm_fire: 'bg-danger text-white p-2 shadow rounded',
    alarm_cleaningService: 'bg-warning text-dark p-2 shadow rounded',
    alarm_roomAssistancePending: 'bg-warning text-dark p-2 shadow rounded',
    alarm_cleaningRoomMode: 'bg-primary text-white p-2 shadow rounded',
    //alarm_cardHolderActive: "bg-light text-dark p-2 shadow rounded",

    customAlarm_minibarNotClosed: 'bg-warning text-dark p-2 shadow rounded',
};

//categories = info | warning | important | danger

var toastAlert = (roomInfo, ticket) => {
    //{toastId, ruleName, alarm, srcAlarmOrEventEnum, roomInfo}
    let toastDisplayConfig = _.clone(
        useStore.getState().SRCAlarmsAndAlerts.toastDisplayConfig[ticket.category]
    );
    const userSettings = _.clone(useStore.getState().authTokenInfo.user);

    if (userSettings?.settings?.notificationSettings) {
        const eventsNotifications = userSettings?.settings?.notificationSettings?.subscribed
            ? userSettings.settings.notificationSettings?.subscribed
            : [];
        if (eventsNotifications.includes(ticket.ruleName)) {
            toastDisplayConfig.toastId = ticket.id;
            toastDisplayConfig.className = alarmAndEventCSSStyling[ticket.ruleName];

            toast(
                <AlertToastTemplate
                    //className={alarmAndEventCSSStyling[ticket.ruleName]}
                    roomInfo={roomInfo}
                    toastId={ticket.id}
                    alarmName={userFrendlyAlarmAndEventsNames[ticket.ruleName]}
                />,
                toastDisplayConfig
            );
        }
    }
};

var clearToastedAlarm = (toastId) => {
    toast.dismiss(toastId);
};

var showSRCAlarmToast = (roomInfo, ticket) => {
    if (ticket.closingTimestamp === null) {
        //its opening tckets
        toastAlert(roomInfo, ticket);
    } else {
        //its closing one
        clearToastedAlarm(ticket.id);
    }
};

var findEventType = (alertOrEventName) => {
    let type = 'low';
    _.forIn(alertCategories, (value, key) => {
        if (_.includes(value, alertOrEventName)) {
            type = key;
        }
    });
    return type;
};

export { showSRCAlarmToast, userFrendlyAlarmAndEventsNames, findEventType, alarmAndEventCSSStyling };
