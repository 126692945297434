import * as React from 'react';
import { translate } from '@data/translations';
import { ReservationController, Reservation } from '../resController';
import { CustomersAndCompanies } from './CustomersAndCompanies';
import CustomerForm from '../../customers/form';
import { setModalContent, closeModal } from '@components/modal';
import { CustomerModel, ReservationGuestReferenceModel, TaxModel } from '@common/modelDefinition';
import VisaDataModal from '../../customers/visaDataModal';
import Delete from '@components/confirmModal';
import moment from 'moment';
//@ts-ignore
import countryCodes from '@common/countryCodes';
import ConfirmButtons from '@components/buttons/confirmButtons';
//@ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Tooltip from '@components/tooltip/tooltip';
import { toast } from 'react-toastify';
import { ReactComponent as Edit } from '../../../assets/custom/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/custom/icons/trash.svg';
import { ReactComponent as Report } from '../../../assets/custom/icons/reports.svg';
import ContextMenu from '@components/ContextMenu';
import useStore from '@data/state/zustand';

const ctrl = new ReservationController();

interface VisaInfoProps {
    reservation: Reservation;
    history: any;
    otherSettings: any;
}

interface CustomerInfoProps {
    editCustomer: any;
    visaDataModal: any;
    showDeleteModal: any;
    customer: CustomerModel;
    invalidVisaIds: number[];
    index: number;
    reservationHolderId: Number;
    reservation: Reservation;
    contextMenuIndex: number | null;
    setContextMenuIndex: Function;
    points: any;
    setPoints: Function;
    location?: any;
    otherSettings: any;
}

const VisaInfo = (props: VisaInfoProps) => {
    const { reservation, history } = props;
    const [contextMenuIndex, setContextMenuIndex] = React.useState<number | null>(null);
    const [points, setPoints] = React.useState<any>(null);
    const taxes: TaxModel[] = useStore.getState().model.Tax;

    if ((reservation.customerId === null || reservation.companyId === null) && reservation.id === null) {
        return null;
    }
    const { location } = history;

    const editCustomer = (customer: CustomerModel) => {
        setModalContent(
            <CustomerForm
                customerId={customer.id}
                callback={async (updatedCustomerData: CustomerModel) => {
                    if (reservation && reservation.id) {
                        const guestReference = reservation.VisaInfo?.find((v) => v.customerId === customer.id);
                        if (guestReference && updatedCustomerData.id) {
                            const ref = { ...guestReference };
                            ref.customerId = updatedCustomerData.id;
                            await ctrl.updateReservationGuestReference(ref);
                            await ctrl.loadReservation(reservation.id);
                        }
                    }
                }}
                reservation={reservation}
                customHeader={''}
                showPageTitle={false}
            />,
            translate('Edit customer'),
            false,
            'modal-xl'
        );
    };

    const updateCustomerModal = (
        customer: CustomerModel,
        scannedCustomer: CustomerModel,
        updatedGuestReference: ReservationGuestReferenceModel
    ) => {
        setModalContent(
            <div className="container-fluid">
                <div className="text-center">
                    {translate(
                        'Do you want to update the customer ' +
                            customer.firstName +
                            ' ' +
                            customer.lastName +
                            ' with new data?'
                    )}
                    <hr />
                </div>

                <div className="text-center">
                    <ConfirmButtons
                        onConfirm={async () => {
                            try {
                                await ctrl.updateCustomer(scannedCustomer);
                                await ctrl.updateReservationGuestReference(updatedGuestReference);
                                if (reservation.id) await ctrl.loadReservation(reservation.id);
                                closeModal();
                            } catch (error) {
                                closeModal();
                            }
                        }}
                        onCancel={async () => {
                            await ctrl.updateReservationGuestReference(updatedGuestReference);
                            closeModal();
                        }}
                    />
                </div>
            </div>,
            translate('Update customer'), // header
            false, // showCloseButton
            'modal-md'
        );
    };

    const visaDataModal = (customer: CustomerModel) => {
        let person = `${customer.firstName ? customer.firstName : ''} ${customer.lastName ? customer.lastName : ''}`;
        person = person.toUpperCase();
        const visaData = reservation.VisaInfo?.find((v) => v.customerId === customer.id); //this will hold the reference to object

        setModalContent(
            <VisaDataModal
                customer={customer}
                reservation={reservation}
                visaData={visaData}
                saveHandler={async (
                    updatedGuestReference: ReservationGuestReferenceModel,
                    scannedCustomer: CustomerModel | any,
                    update: boolean
                ) => {
                    try {
                        if (update) {
                            const newCustomerData = { ...customer } as CustomerModel | any;
                            newCustomerData.documentId = updatedGuestReference.documentId;
                            newCustomerData.documentType = updatedGuestReference.documentType;
                            scannedCustomer &&
                                Object.keys(scannedCustomer).forEach((c: string) => {
                                    if (c === 'id' || c === 'documentId') {
                                    } else {
                                        newCustomerData[c] = scannedCustomer[c];
                                    }
                                });

                            updateCustomerModal(customer, newCustomerData, updatedGuestReference);
                        } else {
                            await ctrl.updateReservationGuestReference(updatedGuestReference);
                            closeModal();
                        }
                    } catch (error: any) {
                        closeModal();
                    }
                }}
                // cancelHandler={closeModal}
            />,
            translate('VISA AND DOCUMENT DATA FOR  ') + person,
            false,
            'modal-lg'
        );
    };

    const showDeleteModal = (customer: CustomerModel) => {
        setModalContent(
            <Delete
                actionForYes={async () => {
                    const resGuestRef = reservation.VisaInfo?.find((r) => r.customerId === customer.id);
                    if (reservation.customerId !== customer.id && resGuestRef) {
                        if (resGuestRef.id) {
                            await ctrl.removeGuestFromReservation(resGuestRef.id);
                        }
                    } else {
                        console.log('You cant remove reservation holder!!!!');
                    }
                }}
            />,
            translate('Delete ') + translate('reservation guest'),
            false,
            'modal-md'
        );
    };

    let list = null;

    if (reservation.Guests && reservation.Guests.length === 0) {
        list = (
            <div className="col-md-3">
                <div className="p-3 bg-white border rounded text-center">{translate('List is empty')}</div>
            </div>
        );
    } else {
        list =
            reservation.Guests &&
            reservation.Guests.map((customer, index: number) => {
                return (
                    <CustomerInfo
                        key={index}
                        index={index}
                        customer={customer}
                        editCustomer={editCustomer}
                        visaDataModal={visaDataModal}
                        showDeleteModal={showDeleteModal}
                        invalidVisaIds={ctrl.getCustomersWithInvalidVisaIds()}
                        reservationHolderId={reservation.customerId ? reservation.customerId : -1}
                        reservation={reservation}
                        location={location}
                        contextMenuIndex={contextMenuIndex}
                        setContextMenuIndex={setContextMenuIndex}
                        points={points}
                        setPoints={setPoints}
                        otherSettings={props.otherSettings}
                    />
                );
            });
    }

    if (!reservation.id || reservation.id === null) {
        return null;
    }

    const accoommodationFixedTaxes = taxes
        ? taxes
              .filter((t: TaxModel) => t.fixedAmount && Number(t.isActive) === 1)
              .sort((a: TaxModel, b: TaxModel) => (Number(b.fixedAmount) > Number(a.fixedAmount) ? 1 : -1))
        : [];
    let fixedTaxId = accoommodationFixedTaxes.length > 0 ? accoommodationFixedTaxes[0].id : 0;

    return (
        <div className="mt-4p">
            <div className="mt-4p mb-8p">
                <CustomersAndCompanies
                    history={history}
                    customer={null}
                    company={null}
                    showCompany={false}
                    customerSelect={async (c) => {
                        const customerDateOfBirth = c.dateOfBirth ? c.dateOfBirth : null; // Vrijedi samo za BiH , treba vidjeti što s ovim, neki handler gdje u ovisnosti o državi priprema taxId, isto je i na backendu kod holdera
                        if (customerDateOfBirth) {
                            const customerAge = Number(moment().diff(customerDateOfBirth, 'years'));
                            if (customerAge < 13) {
                                fixedTaxId = 0;
                            } else if (customerAge > 12 && customerAge < 19 && accoommodationFixedTaxes.length > 1) {
                                fixedTaxId = accoommodationFixedTaxes[1].id;
                            }
                        }
                        if (fixedTaxId || fixedTaxId === 0) {
                            await ctrl.addGuestToReservation(c, fixedTaxId);
                        }
                    }}
                    companySelect={(c) => {}}
                    reservation={reservation}
                    docScanner={true}
                />
            </div>

            <div style={{ height: '300px', overflowY: 'auto' }}>{list}</div>
        </div>
    );
};

const CustomerInfo = (CustomerInfoProps: CustomerInfoProps) => {
    const {
        customer,
        index,
        editCustomer,
        visaDataModal,
        reservationHolderId,
        showDeleteModal,
        invalidVisaIds,
        reservation,
        location,
        contextMenuIndex,
        setContextMenuIndex,
        points,
        setPoints,
        otherSettings,
    } = CustomerInfoProps;

    let country = countryCodes.find((cInfo: any) => {
        return customer.country === cInfo['country-code'];
    });

    const disableAccommodationTaxCheckbox: boolean =
        location && location.pathname.lastIndexOf('reservationDetails') !== -1 ? true : false;

    const visaData: ReservationGuestReferenceModel | undefined = reservation.VisaInfo?.find(
        (v) => v.customerId === customer.id
    ); //this will hold the reference to object

    const taxes: TaxModel[] = useStore.getState().model.Tax;

    const accoommodationFixedTaxes = taxes
        ?.filter((t: TaxModel) => t.fixedAmount && Number(t.isActive) === 1)
        .sort((a: TaxModel, b: TaxModel) => (Number(b.fixedAmount) > Number(a.fixedAmount) ? 1 : -1));

    const customerData =
        (customer.firstName ? customer.firstName : '') +
        ' ' +
        (customer.lastName ? customer.lastName + '\n' : '') +
        (customer.email ? customer.email + '\n' : '') +
        (customer.phoneOrMobile ? customer.phoneOrMobile + '\n' : '') +
        (customer.address ? customer.address + ', ' : '') +
        (customer.town ? customer.town + '\n' : '') +
        (country ? country['alpha-3'] : '');

    const options = [];
    options.push({ label: 'Visa data modal', icon: <Report /> });
    if (reservation.customerId !== customer.id) {
        options.push({ label: 'Edit', icon: <Edit /> });
    }
    if (reservationHolderId !== customer.id) {
        options.push({ label: 'Delete', icon: <DeleteIcon /> });
    }

    return (
        <div
            key={'customer' + index}
            style={{ width: '100%', margin: 0, padding: 0 }}
            className={
                'custom-list-item ' +
                (contextMenuIndex !== null && contextMenuIndex !== undefined
                    ? contextMenuIndex !== index
                        ? ' hover-disabled'
                        : ' custom-list-item-selected'
                    : ' ')
            }
        >
            <div className="display-flex align-items-center" style={{ width: '100%' }}>
                {customer && customer.id && invalidVisaIds.includes(customer.id) ? (
                    <div className="pl-4p">
                        <Tooltip
                            message={translate(`Visa information is not filled out`)}
                            style={{ zIndex: 9999, position: 'absolute' }}
                        >
                            <i className="fa fa-exclamation-circle text-danger float-right" aria-hidden="true"></i>
                        </Tooltip>
                    </div>
                ) : null}
                <div
                    className="text-bold pointer ml-4p"
                    onClick={() =>
                        setModalContent(
                            <textarea
                                rows={5}
                                cols={20}
                                style={{ whiteSpace: 'pre-wrap' }}
                                disabled
                                onChange={() => {}}
                                value={customerData}
                                className="input input-modal"
                            ></textarea>,
                            translate('Guest data'),
                            false,
                            'modal-md'
                        )
                    }
                >{`${customer.firstName ? customer.firstName : ''} ${customer.lastName ? customer.lastName : ''}`}</div>
            </div>
            <div className="pr-4p" style={{ width: '100%', overflow: 'hidden' }}>
                {` ${customer.email ? customer.email : ''}`} {`${customer.phoneOrMobile ? customer.phoneOrMobile : ''}`}
            </div>
            <div className="display-flex align-items-center justify-content-end" style={{ width: '100%' }}>
                <div
                    onClick={async () => {
                        const { checkInTimestamp, checkOutTimestamp } = reservation;
                        if (visaData && checkInTimestamp && checkOutTimestamp) {
                            const editVisaData = { ...visaData };
                            if (visaData?.checkInTimestamp && visaData?.checkOutTimestamp) {
                                editVisaData.checkInTimestamp = null;
                                editVisaData.checkOutTimestamp = null;
                                await ctrl.updateReservationGuestReference(editVisaData);
                            } else {
                                editVisaData.checkInTimestamp = checkInTimestamp;
                                editVisaData.checkOutTimestamp = checkOutTimestamp;
                                await ctrl.updateReservationGuestReference(editVisaData);
                            }
                        }
                        const guest = reservation?.Guests?.find((g) => g.id === visaData?.customerId);
                        setModalContent(
                            <div className="justify-content-center">
                                <div className="mb-16p">
                                    {translate('Select check in and check out date for guest ')}
                                    {guest?.firstName + ' ' + guest?.lastName}
                                </div>
                                <div className="display-flex justify-content-center">
                                    <DayPickerInput
                                        inputProps={{
                                            style: { width: '110px' },
                                            className: 'input input-modal mr-16p',
                                            readOnly: true,
                                        }}
                                        format={otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY'}
                                        placeholder={
                                            otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY'
                                        }
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        value={visaData?.checkInTimestamp ? new Date(visaData.checkInTimestamp) : ''}
                                        onDayChange={async (e) => {
                                            if (visaData?.customerId) {
                                                const editVisaData: ReservationGuestReferenceModel = {
                                                    ...visaData,
                                                };
                                                editVisaData.checkInTimestamp = new Date(e).getTime();
                                                if (
                                                    editVisaData.checkOutTimestamp &&
                                                    editVisaData.checkOutTimestamp >= editVisaData.checkInTimestamp
                                                ) {
                                                    await ctrl.updateReservationGuestReference(editVisaData);
                                                } else {
                                                    toast('Check in can not be after check out!', {
                                                        position: toast.POSITION.BOTTOM_RIGHT,
                                                        type: toast.TYPE.ERROR,
                                                    });
                                                }
                                            }
                                        }}
                                        dayPickerProps={{
                                            showWeekNumbers: true,
                                            numberOfMonths: 1,
                                            disabledDays: {
                                                before: new Date(reservation.checkInTimestamp),
                                                after: new Date(reservation.checkOutTimestamp),
                                            },
                                        }}
                                    />

                                    <DayPickerInput
                                        inputProps={{
                                            style: { width: '110px' },
                                            className: 'input input-modal ml-16p',
                                            readOnly: true,
                                        }}
                                        format={otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY'}
                                        placeholder={
                                            otherSettings?.dateFormat ? otherSettings.dateFormat : 'DD/MM/YYYY'
                                        }
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        value={visaData?.checkOutTimestamp ? new Date(visaData.checkOutTimestamp) : ''}
                                        onDayChange={async (e) => {
                                            if (visaData?.customerId) {
                                                const editVisaData = { ...visaData };
                                                editVisaData.checkOutTimestamp = new Date(e).getTime();
                                                if (
                                                    editVisaData.checkInTimestamp &&
                                                    editVisaData.checkOutTimestamp >= editVisaData.checkInTimestamp
                                                ) {
                                                    await ctrl.updateReservationGuestReference(editVisaData);
                                                } else {
                                                    toast('Check out can not be before check in!', {
                                                        position: toast.POSITION.BOTTOM_RIGHT,
                                                        type: toast.TYPE.ERROR,
                                                    });
                                                }
                                            }
                                        }}
                                        dayPickerProps={{
                                            showWeekNumbers: true,
                                            numberOfMonths: 1,
                                            disabledDays: {
                                                before: new Date(reservation.checkInTimestamp),
                                                after: new Date(reservation.checkOutTimestamp),
                                            },
                                        }}
                                    />
                                </div>
                                <div className="mt-12p display-flex justify-content-end"></div>
                            </div>,
                            translate(`The guest's stay`),
                            false,
                            'modal-md'
                        );
                    }}
                >
                    <Tooltip
                        message={translate(`The guest's stay does not match the duration of the reservation`)}
                        style={{ zIndex: 9999, position: 'absolute' }}
                    >
                        <i
                            className={
                                visaData?.checkInTimestamp && visaData?.checkOutTimestamp
                                    ? 'fa fa-check-square'
                                    : 'fa fa-square'
                            }
                        ></i>
                    </Tooltip>
                </div>
                <select
                    onChange={async (e) => {
                        if (visaData) {
                            const editVisaData = { ...visaData };
                            editVisaData.fixedTaxId = Number(e.target.value);
                            await ctrl.updateReservationGuestReference(editVisaData);
                        }
                    }}
                    name="fixedTaxId"
                    value={visaData?.fixedTaxId}
                    className="input input-modal ml-8p"
                    disabled={disableAccommodationTaxCheckbox}
                >
                    <React.Fragment>
                        {accoommodationFixedTaxes.map((t: TaxModel, key: number) => {
                            return (
                                <option value={t.id} key={key}>
                                    {translate(t.name)}
                                </option>
                            );
                        })}
                        <option value={0}>{translate('No charge')}</option>
                    </React.Fragment>
                </select>
            </div>
            <div>
                <ContextMenu
                    options={options}
                    position={index ? index : 0}
                    contextMenuIndex={contextMenuIndex} // Right-click context menu + Select row css
                    setContextMenuIndex={setContextMenuIndex}
                    points={points} // Right-click context menu
                    setPoints={setPoints}
                    onSelect={async (option: string) => {
                        if (customer?.id) {
                            if (option === 'Visa data modal') {
                                visaDataModal(customer);
                            } else if (option === 'Edit') {
                                editCustomer(customer);
                            } else if (option === 'Delete') {
                                showDeleteModal(customer);
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default VisaInfo;
