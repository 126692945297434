import * as React from 'react';
import DateTimeSelector from './DateTimeSelector';
import { reservationEnums } from '@data/reservationStatusEnums';
import { Reservation } from '../resController';
import { translate } from '@data/translations';
import { AttributesModel, ReservationStatus, ValueLabelString } from '@common/modelDefinition';
import ReservationRoomAttributes from './ReservationRoomAttributes';
import { getCheckInAndCheckoutTimestamps } from '@pages/newReservations';
import useStore from '@data/state/zustand';
import Select from 'react-select';

interface ReservationDateTimeSelectorProps {
    reservation: Reservation;
    onReservationUpdate: (res: Reservation) => void;
    isReadOnly?: boolean;
}

const getDaysArray = function (start: Date, end: Date) {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
};

const getISODate = (ts: number) => {
    return new Date(ts).toISOString().substr(0, 10);
};

function ReservationDateTimeSelector(props: ReservationDateTimeSelectorProps) {
    const { reservation, onReservationUpdate, isReadOnly = false } = props;
    // const { bedCount = 1, spareBedCount = 0 } = reservation.RoomInfo;
    const { genuineInvoiceIssued } = reservation;
    const disableCheckIn = reservation.statusEnum === reservationEnums.checkedIn; //bojan je disableCheckIn vezao za earlyInvoice???  ne razumijem zašto
    const disableChangingDateIfProviderReservation = reservation.providerReservation ? true : false;

    const reservations: Reservation[] = useStore((appState: any) => appState.model.Reservation);
    const otherSettings = useStore((appState: any) => appState.otherSettings);

    const attributes: AttributesModel[] = useStore((appState: any) => appState.model.RoomAttribute);

    const getReservedDates = (includeFirst = true, includeLast = true) => {
        const result: any[] = [];
        let roomReservations = reservations?.filter((r: Reservation) => {
            if (r.id === reservation.id) {
                return false; //remove currently edited reservation...
            }
            return (
                (Number(r.statusEnum) === reservationEnums.earlyBird ||
                    Number(r.statusEnum) === reservationEnums.autoBlocked ||
                    Number(r.statusEnum) === reservationEnums.confirmed ||
                    Number(r.statusEnum) === reservationEnums.checkedIn) &&
                r.roomInfoId === reservation.roomInfoId
            );
        });

        roomReservations.forEach((r: Reservation) => {
            const temp = getDaysArray(new Date(r.checkInTimestamp), new Date(r.checkOutTimestamp));

            for (let i = 0; i < temp.length; i++) {
                const date = temp[i];
                if (i === 0) {
                    if (includeFirst) {
                        result.push(date);
                    }
                } else if (i === temp.length - 1) {
                    if (includeLast) {
                        result.push(date);
                    }
                } else {
                    result.push(date);
                }
            }
        });
        return result;
    };

    const reservationsNumberInputHandler = (e: any) => {
        const res = { ...reservation };
        (res as any)[e.target.name] = Number(e.target.value);
        onReservationUpdate(res);
    };

    const minMaxConstraintsFix = (e: any) => {
        if (e.target.max < Number(e.target.value) || e.target.min > Number(e.target.value)) {
            const res = { ...reservation };
            if (e.target.max < Number(e.target.value)) {
                (res as any)[e.target.name] = Number(e.target.max);
                onReservationUpdate(res);
            } else {
                (res as any)[e.target.name] = Number(e.target.min);
                onReservationUpdate(res);
            }
        }
    };

    const dateTimePickHandler = (type: string, timestamp: number) => {
        const res = { ...reservation };
        (res as any)[type] = timestamp;

        if (type === 'checkOutTimestamp') {
            //for daily stayover
            if (getISODate(res.checkInTimestamp) === getISODate(res.checkOutTimestamp)) {
                if (res.checkInTimestamp >= res.checkOutTimestamp) {
                    res.checkOutTimestamp = res.checkInTimestamp + 1 * 60 * 60 * 1000;
                }
            } else if (res.checkInTimestamp >= res.checkOutTimestamp) {
                res.checkOutTimestamp = res.checkInTimestamp + 24 * 60 * 60 * 1000;
                const { checkOutTimestamp } = getCheckInAndCheckoutTimestamps(
                    otherSettings,
                    new Date(res.checkInTimestamp),
                    new Date(res.checkOutTimestamp)
                );
                res.checkOutTimestamp = checkOutTimestamp;
            }
        } else {
            if (res.checkInTimestamp >= res.checkOutTimestamp) {
                res.checkOutTimestamp = res.checkInTimestamp + 24 * 60 * 60 * 1000;
                const { checkOutTimestamp } = getCheckInAndCheckoutTimestamps(
                    otherSettings,
                    new Date(res.checkInTimestamp),
                    new Date(res.checkOutTimestamp)
                );
                res.checkOutTimestamp = checkOutTimestamp;
            }
        }
        onReservationUpdate(res);
    };

    const applyCurrentCheckInTime = () => {
        const res = { ...reservation };
        res.checkInTimestamp = Date.now();
        onReservationUpdate(res);
    };


    const boards = ['nb', 'bb', 'hb', 'fb', 'ai'];
    //  const defaultIndexBoard = boards.indexOf(this.state.defaultBoard);

    enum ListLabel  {
        nb= 'No board',
        bb= 'Breakfast',
        hb= 'Half board',
        fb= 'Full board',
        ai= 'All inclusive',
    };

    const boardList:ValueLabelString[] = boards.map((board) => {
        return { label: translate(ListLabel[board as keyof typeof ListLabel]), value: board };
    });

    let boardValues = {
        label: reservation.board ? translate(ListLabel[reservation.board as keyof typeof ListLabel]) : '',
        value: reservation.board ? reservation.board : '',
    };

    return (
        <div className="">
            <div className="display-flex mobile-page-form">
                <div className="flex mr-8p">
                    <label className="text-bold ml-4p mb-4p">
                        <b className="">{translate('Check in')}: </b>
                    </label>
                    <DateTimeSelector
                        readOnly={
                            disableChangingDateIfProviderReservation ||
                            disableCheckIn ||
                            isReadOnly ||
                            genuineInvoiceIssued === 1 ||
                            reservation.statusEnum === ReservationStatus.closed
                        }
                        disabledDays={getReservedDates(true, false).concat([
                            {
                                before: new Date(),
                            },
                        ])}
                        dateTimePickHandler={(timestamp: number) => {
                            dateTimePickHandler('checkInTimestamp', timestamp);
                        }}
                        value={reservation.checkInTimestamp}
                    />
                </div>
                <div className="flex mr-8p">
                    <label className="text-bold ml-4p mb-4p">
                        <b className="">{translate('Check out')}: </b>
                    </label>
                    <DateTimeSelector
                        readOnly={
                            disableChangingDateIfProviderReservation ||
                            isReadOnly ||
                            genuineInvoiceIssued === 1 ||
                            reservation.statusEnum === ReservationStatus.closed
                        }
                        disabledDays={getReservedDates(false, true).concat([
                            {
                                before: new Date(reservation.checkInTimestamp),
                            },
                        ])}
                        dateTimePickHandler={(timestamp: number) => {
                            dateTimePickHandler('checkOutTimestamp', timestamp);
                        }}
                        value={reservation.checkOutTimestamp}
                    />
                </div>
            </div>

            <div className="">
                {Number(reservation.statusEnum) === reservationEnums.checkedIn &&
                reservation.statusEnum !== reservationEnums.checkedIn ? (
                    <div onClick={applyCurrentCheckInTime} className="mt-2 w-100 button-warning text-dark d-block">
                        {translate('APPLY CURRENT TIME & DATE')}
                    </div>
                ) : null}
            </div>

            <div className="display-flex mt-12p mobile-page-form justify-content-between">
            <div className='flex mr-8p'>
                <div className="display-flex">
                    <div className="mr-16p">
                        <label className="text-bold ml-4p mb-4p">{translate('Adults')}:</label>
                        <br />
                        <input
                            disabled={isReadOnly || genuineInvoiceIssued === 1}
                            name="adultsNumber"
                            min={1}
                            max={20}
                            onChange={reservationsNumberInputHandler}
                            value={reservation.adultsNumber ? reservation.adultsNumber : 1}
                            onBlur={minMaxConstraintsFix}
                            type="number"
                            className="input input-modal"
                            style={{ width: '100px' }}
                        />
                    </div>
                    <div className=" ml-16p">
                        <label className="text-bold ml-4p mb-4p">{translate('Children')}:</label>
                        <br />
                        <input
                            disabled={isReadOnly || genuineInvoiceIssued === 1}
                            name="childrenNumber"
                            min={0}
                            max={20}
                            onChange={reservationsNumberInputHandler}
                            value={reservation.childrenNumber ? reservation.childrenNumber : 0}
                            onBlur={minMaxConstraintsFix}
                            type="number"
                            className="input input-modal"
                            style={{ width: '100px' }}
                        />
                    </div>
                </div>
                </div>
                <div className='flex mr-8p'>
                            
                <div className="" >
                        <label className="text-bold ml-4p mb-4p" htmlFor={''}>
                            {translate('Board')}:
                        </label>
                        <div>
                            <Select
                                className="nput input-modal"
                                placeholder={translate('Select options...')}
                                options={boardList}
                                name="board"
                                onChange={(option:any) => {
                                    const res = { ...reservation };
                                    res.board = option.value
                                    onReservationUpdate(res);
                                }}
                                value={boardValues}
                            ></Select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="display-flex mt-12p">
                <label className="text-bold ml-4p mb-4p mr-8p">{translate('Room beds (+ spare beds)')}:</label>
                <div className="">
                    {reservation.RoomInfo?.bedCount}{" + "}{reservation?.RoomInfo?.spareBedCount}
                </div>
            </div>
            <div className="display-flex mt-12p">
                <label className="text-bold ml-4p mb-4p">{translate('Room attributes')}:</label>
                <div className="">
                    <ReservationRoomAttributes room={reservation.RoomInfo} attributes={attributes} />
                </div>
            </div>
        </div>
    );
}

export default ReservationDateTimeSelector;
