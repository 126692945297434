import React, { useEffect, useState } from 'react';
import rest from '../../../data/restWrapper';
import PageLoader from '../../../components/loaders/loader1';
import AccessCodes from '../../SRCSettings/accessCodes';
import CommunicationError from '../../SRCSettings/communicationError';
import { checkIfRoomHasSettings } from '../../../data/controllers/SRCStatus';
import { ReservationWithIncludedModels, RoomInfoEditModel } from '@common/modelDefinition';
import translate from '@data/translations';
import useStore from '@data/state/zustand';
import { Reservation } from '@pages/newReservations/resController';

interface BasicSRCSettingsProps {
    match?: any;
    remoteDeviceId?: string | null;
    roomInfoId: number;
    reservation?: Reservation | ReservationWithIncludedModels;
    showOnlyOneSRC?: boolean;
}

function BasicSRCSettings(props: BasicSRCSettingsProps) {
    const [showConnectionError, setShowConnectionError] = useState(false as boolean);
    const [remoteDeviceId, setRemoteDeviceId] = useState(null as string | null);
    const [basicSRCSettings, setBasicSRCSettings] = useState({} as any);
    const [showMessage, setShowMessage] = useState(null as null | string);
    // const rooms =  useStore.getState().model.RoomInfo()
    const rooms = useStore.getState().model.RoomInfo;

    // const rooms: RoomInfoEditModel[] = useStore((appState: any) => appState.model.RoomInfo);
    const locale: string = useStore((appState: any) => appState.locale);

    useEffect(() => {
        const loadData = async () => {
            let _remoteDeviceId = props.remoteDeviceId ? props.remoteDeviceId : null;
            if (props.match && props.match.params.remoteDeviceId) {
                _remoteDeviceId = props.match.params.remoteDeviceId;
            }
            redrawComponent(_remoteDeviceId);
        };
        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [props.remoteDeviceId, rooms?.length]);

    const redrawComponent = async (remoteDeviceId: string | null) => {
        if (remoteDeviceId) {
            if (checkIfRoomHasSettings(remoteDeviceId)) {
                try {
                    setRemoteDeviceId(null);
                    setShowConnectionError(false);
                    let basicSRCSettings = await rest('/src/fetchBasicSRCSettingsByRoomInfoId', 'POST', {
                        roomInfoId: props.roomInfoId,
                        smartRoomControllerId: remoteDeviceId,
                    });
                    setRemoteDeviceId(remoteDeviceId);
                    setBasicSRCSettings(basicSRCSettings);
                    setShowConnectionError(false);
                } catch (err: any) {
                    setShowConnectionError(true);
                    setRemoteDeviceId(remoteDeviceId);
                }
            } else {
                setShowMessage('SRC settings not available for this device. Probably SOS Calling Device.');
            }
        }
    };

    let forceReloadButton = (
        <div className="mb-2">
            <button
                onClick={() => {
                    redrawComponent(remoteDeviceId);
                }}
                className="btn btn-outline-primary"
            >
                <i className="fa fa-refresh mr-2" aria-hidden="true"></i>
                {translate('Force reload')}
            </button>
        </div>
    );

    if (showMessage) {
        return <div>{showMessage}</div>;
    }

    if (showConnectionError) {
        return (
            <div>
                {forceReloadButton}
                <CommunicationError />
            </div>
        );
    }
    if (remoteDeviceId === null) {
        return (
            <PageLoader
                style={{ height: '20rem', paddingTop: '8rem' }}
                header="READING BASIC SRC SETTINGS"
                heading="(please wait)"
            />
        );
    }
    let roomInfo: any = rooms?.find((r: RoomInfoEditModel) => r.id === Number(props.roomInfoId));
    return (
        <div>
            {!props.showOnlyOneSRC ? (
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                        className={`nav-item nav-link ${remoteDeviceId === roomInfo.remoteDeviceId ? 'active' : ''}${
                            !roomInfo.remoteDeviceId ? ' disabled' : ''
                        }`}
                        id="nav-remoteDeviceId-tab"
                        data-toggle="tab"
                        href="#nav-remoteDeviceId"
                        role="tab"
                        aria-controls="nav-remoteDeviceId"
                        aria-selected="true"
                        onClick={() => (roomInfo.remoteDeviceId ? redrawComponent(roomInfo.remoteDeviceId) : null)}
                    >
                        {translate('Main Controller')}
                    </a>

                    <a
                        className={`nav-item nav-link ${remoteDeviceId === roomInfo.remoteDeviceId2 ? 'active' : ''}${
                            !roomInfo.remoteDeviceId2 ? ' disabled' : ''
                        }`}
                        id="nav-remoteDeviceId2-tab"
                        data-toggle="tab"
                        href="#nav-remoteDeviceId2"
                        role="tab"
                        aria-controls="nav-remoteDeviceId2"
                        aria-selected="false"
                        onClick={() => (roomInfo.remoteDeviceId2 ? redrawComponent(roomInfo.remoteDeviceId2) : null)}
                    >
                        {translate('Controller 2')}
                    </a>

                    <a
                        className={`nav-item nav-link ${remoteDeviceId === roomInfo.remoteDeviceId3 ? 'active' : ''}${
                            !roomInfo.remoteDeviceId3 ? ' disabled' : ''
                        }`}
                        id="nav-remoteDeviceId3-tab"
                        data-toggle="tab"
                        href="#nav-remoteDeviceId3"
                        role="tab"
                        aria-controls="nav-remoteDeviceId3"
                        aria-selected="false"
                        onClick={() => (roomInfo.remoteDeviceId3 ? redrawComponent(roomInfo.remoteDeviceId3) : null)}
                    >
                        {translate('Controller 3')}
                    </a>
                </div>
            ) : roomInfo.remoteDeviceId && (roomInfo.remoteDeviceId2 || roomInfo.remoteDeviceId3) ? (
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                        className={`nav-item nav-link active`}
                        id="nav-allDevices-tab"
                        data-toggle="tab"
                        href="#nav-allDevices"
                        role="tab"
                        aria-controls="nav-allDevices"
                        aria-selected="false"
                    >
                        {translate('All Controllers')}
                    </a>
                </div>
            ) : null}

            <AccessCodes
                reservation={props.reservation}
                basicMode={true}
                remoteDeviceId={remoteDeviceId}
                roomSettings={basicSRCSettings}
                locale={locale}
                showOnlyOneSRC={props.showOnlyOneSRC}
                remoteDeviceId1={roomInfo?.remoteDeviceId}
                remoteDeviceId2={roomInfo?.remoteDeviceId2}
                remoteDeviceId3={roomInfo?.remoteDeviceId3}
            />
        </div>
    );
}

export default BasicSRCSettings;
