import React from 'react';
import { attributeComponents } from './attributesIcons';
import { AttributesModel, RoomInfoModel } from '@common/modelDefinition';

interface RoomAttributesProps {
    room: RoomInfoModel;
    attributes: AttributesModel[];
}

function RoomAttributes(props: RoomAttributesProps) {
    let roomAttributes = props?.room?.roomAttributes ? JSON.parse(props.room.roomAttributes) : [];
    let attributes = props?.attributes
        ?.map((att) => {
            if (roomAttributes.includes(att.id)) {
                return att;
            } else {
                return null;
            }
        })
        ?.filter((a) => a !== null);
    let attributesList: any = [];

    if (attributes?.length > 0) {
        attributesList = attributes?.map((att, key) => {
            const AttrComp = attributeComponents[att?.name as keyof typeof attributeComponents];
            return (
                <span key={key} className="p-1" style={{ width: '25px', height: '25px', marginBottom: '3px' }}>
                    {AttrComp ? <AttrComp /> : null}
                </span>
            );
        });
    } else {
        attributesList = null; //<span className="mr-2 p-1">No assigned attributes</span>
    }

    if (attributesList === null) {
        return null;
    }

    return <div className="">{attributesList}</div>;
}

export default RoomAttributes;

//required props
//content nije required
//showOnlyCloseButton nije required
